import React, { useEffect, useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker,  NuInputText, NuSelect, NuTextArea,NuInputNumber,NuDateTime } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Navigate,useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dropdown from 'antd/es/dropdown/dropdown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import UseCatSubCatDropDown from '../../../../Components/Hooks/useCategoryDropDown';
import axiosInstance from '../../../../Services/axiosCommon';

function CreateInspectionSchedule() {
  const {officeId} = useParams();

  const navigate=useNavigate()
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:vehicle} = UseDropDown('/vehicle/drop-down')
  const {data:employees} = UseDropDown('/employees/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const { value: value } = useFetchMataData('Category_Inspection')
  const { value: value1 } = useFetchMataData('Sub_Category_Inspection')
  // const { value: itemsCategory } = useFetchMataData('/category/InspectionCategory')


  // const [division, setDivision] = useState([]);

  const [createNew, setCreateNew] = useState({
    // reqType: "RoomService",
    branchId: officeId,

    item:"",
    category:"",
    subCategory:"",
    inspectionSchedule: "",
    dayAndTime:"",
    comments:"",
})
  
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData ();
  const [selectedFile, setSelectedFile] = useState(null);

  const formik = useFormik
    ({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // productName: yup.string().required('Product is required!'),
        // category: yup.string().required('Category is required!'),
        // unit: yup.string().required('Unit is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        // let formData = new FormData();
        // formData.append('inspectionDetails', JSON.stringify(value));
        // // formData.append('itemImage', image.raw);
        // if(selectedFile !== null){
        //   selectedFile.forEach((file) => {
        //     formData.append("inspectionImage", file);
        //   });
        // }else{
        //   formData.append("inspectionImage",[])
        // } 

        addFormData('/inspection-schedule/new-schedule', value, resetForm);
      }
    });
  const { category: itemsCategory, subCategory: itemsSubCategory, getCategory, fetchSubCategory } = UseCatSubCatDropDown('/category/InspectionCategory', "InspectionCategory", formik?.values?.category);

  // console.log("hhh",itemsCategory);
  // const [category,setCategory] = useState([])
  // console.log("sherin",category);
  
//  const cat = () =>{
//   axiosInstance.get(`/category/InspectionCategory`)
//     .then((res)=>{
//       console.log("search",res?.data?.data);
//       let data = res?.data?.data
//       let tempData = [];
//             data.forEach((item) => {
               
//                 let tempObject = { value: item.name, label: item.name };
//                 tempData.push(tempObject);
//             });
//             res.data && setCategory(tempData);
//     }).catch(err => {
//       console.log(err, "err");
//     })
//  }
//  useEffect(()=>{
//     cat();
//  },[])
  // const formik = useFormik({
  //     enableReinitialize: true,
  //     initialValues: createNew,
  //     validateOnChange: false,
  //     validateOnBlur: false,
  //     validationSchema: yup.object({
  //         // email: yup.string().required('Email is required!'),
  //     }),
  //     onSubmit: async (value, { resetForm }) => {
         

  //         addFormData('/inspection-schedule/new-schedule', value, resetForm);
  //     }
  // })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Maintenance</p>) },
        { title: (<p className=' text-[12px] '>Inspection-Schedule</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          <NuInputText
              // isRequired={true}
              label="Item"
              placeholder="Item"
              formik={formik}
              name="item"
              width="w-full md:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label="Category"
              options={itemsCategory}
              placeholder="Category"
              formik={formik}
              name="category"
              width="w-full md:w-1/3"
            />
            
          <NuSelect
              label="Sub Category"
              options={itemsSubCategory}
              formik={formik}
              placeholder="Choose"
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputText
              // isRequired={true}
              label="Inspection Schedule"
              placeholder="Inspection Schedule"
              formik={formik}
              name="inspectionSchedule"
              width="w-full md:w-1/3"
            />
            <NuDateTime
              // isRequired={true}
              label="Day And Time"
              placeholder="dayAndTime"
              formik={formik}
              name="dayAndTime"
              width="w-full md:w-1/3"
            />
          <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
              {/* <NuInputNumber
              label="Id"
              placeholder="Id"
              formik={formik}
              name="roomserviceId"
              width="w-full md:w-1/3"
            /> */}
            {/* <NuSelect
              label="Menu Id"
              options={[
                { value: 1, label: "Veg" },
                { value: 2, label: "Non-veg" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="menuId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
          
            {/* <NuInputNumber
              label="Prpared By"
              placeholder="prparedBy"
              formik={formik}
              name="prparedBy"
              width="w-full md:w-1/3"
            /> */}
             {/* <NuInputNumber
              label="Bed No"
              placeholder="Bed No"
              formik={formik}
              name="Bed No"
              width="w-full md:w-1/3"
            /> */}
            
            
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateInspectionSchedule