import React, { useEffect, useState } from "react";
import { NormalHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';

function ViewDailyInspectionByStaff() {
  const { officeId } = useParams();
  const location = useLocation();
  const data = location.state.data;
  console.log("ccc",data);
    return (
      <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: (<p className=' text-[12px] '>Maintenance</p>) },
            { title: (<p className=' text-[12px] '>Daily-Inspection-By-Staff</p>) },
            { title: (<p className=' text-[12px] '>View</p>) },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/maintenance/daily-inspection-by-staff/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/maintenance/daily-inspection-by-staff/${officeId}/edit/${data?.dailyInspectionId}`} state={{data: data, from:`/main/maintenance/daily-inspection-by-staff/${officeId}/view/${data?.dailyInspectionId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
              <h2 className='text-base font-normal text-[#111537] p-3'>Details</h2>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-5 gap-x-8 m-10">
                <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.dailyInspectionId || '-'}</p>
                  </div>
                <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Item</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.item|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.cat || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>SubCategory</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.subCat || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Inspected</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.inspected? "true" : "false" || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>InspectedBy</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.inspectedBy || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Time</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.time || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Condition</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.condition || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments || '-'}</p>
                  </div>
                  
                  </div>
                  </div>
          </div>
          
      </div>
    );
}

export default ViewDailyInspectionByStaff