import { Button, Input, Radio, Select, Space } from 'antd';
import { useRef, useState } from 'react';
import { mobileCode } from '../../assets/CountryCode';
import { currencies } from '../../assets/CountryMini';


export const NuInputText = ({ label, name, formik, handleChange, width, placeholder, disable, isRequired }) => {
  return (
    <div className={`${width} my-3`}>
      <div className='px-3'>
        <label className='mb-2 font-normal text-[13px] text-textColor-500  '>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label><br />
        <Input className='mt-2  h-10 px-3' name={name} placeholder={placeholder} value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} disabled={disable ? true : false} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuInputfile = ({ label, name, handleChange, width, placeholder, disable, isRequired, acceptType = "*" }) => {
  return (
    <div className={`${width} my-3 `}>
      <div className=' px-3'>
        <label className=' mb-2 font-normal text-[13px] text-textColor-500  '>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input name={name} placeholder={placeholder} type='file' multiple={true} onChange={handleChange} disabled={disable ? true : false} accept={acceptType} />
        {/* {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null} */}
      </div>
    </div>
  );
};

export const NuInputPassword = ({ label, name, formik, handleChange, width, placeholder, isRequired }) => {
  return (
    <div className={`${width} my-3 `}>
      <div className=' px-3'>
        <label className='mb-2 font-normal text-[13px] text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input name={name} className='mt-2' type='password' placeholder={placeholder} value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuInputSubmit = ({ label, name, type = 'text', formik, handleChange, width, placeholder }) => {
  return (
    <div className={`${width} my-3 `}>
      <div className=' px-3'>
        <label className=' mb-2 font-medium text-textColor-500'>{label}</label>
        <Space.Compact style={{ width: '100%' }}>
          <Input style={{ padding: '2px 4px' }} name={name} type={type} placeholder={placeholder} value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} />
          <Button htmlType='submit' className=' bg-mainColor-500' type='primary'>
            Add
          </Button>
        </Space.Compact>
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuInputNumber = ({ label, name, formik, handleChange, width, placeholder, disable, isRequired, max, min }) => {
  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input name={name} className='mt-2 h-10 px-3' placeholder={placeholder} value={formik.values[name] || ""} type='number' onChange={handleChange || formik.handleChange} max={max} min={min} disabled={disable ? true : false} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuInputFloatNumber = ({ label, name, formik, handleChange, decimal = 2, width, placeholder, disable, isRequired, max, min }) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    // Ensure value is a valid number with up to three decimal places
    const newValue = parseFloat(value).toFixed(decimal);
    formik.setFieldValue(name, newValue);
    handleChange && handleChange(newValue); // Call custom change handler if provided
  };
  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input name={name} className='mt-2' placeholder={placeholder} value={formik.values[name] || ""} type='number' onChange={handleInputChange || formik.handleChange} max={max} min={min} disabled={disable ? true : false} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

// export const NuInputTwoDecimalNumber = ({ label, name, formik, handleChange, width, placeholder, disable, isRequired, max, min }) => {
//   const handleInputChange = (e) => {
//     formik.setFieldValue(name, e.target.value);
//     handleChange && handleChange(e); // Call custom change handler if provided
//   };

//   return (
//     <div className={`${width} my-3`}>
//       <div className=' px-3'>
//         <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
//         <Input
//           name={name}
//           className='mt-2'
//           placeholder={placeholder}
//           value={formik.values[name] || ""}
//           onChange={handleInputChange}
//           disabled={disable ? true : false}
//           formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
//           parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
//           type='text'
//         />
//         {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
//       </div>
//     </div>
//   );
// };

export const NuMobileNumber = ({
  label,
  name,
  nunCode,
  formik,
  onChange,
  placeholder,
  width,
  isRequired,
  disable,
  min,
  max,
}) => {
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e,
        name: nunCode,
      },
    });
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    // console.log('Focused..',isInputFocused);
  };

  const handleInputBlur = () => {
    setIsInputFocused(false);
  };

  const isInputValid = () => {
    const inputValue = formik.values[name] || '';
    // console.log("number..",inputValue);
    // console.log("check...",String(inputValue).length);
    const inputLength = String(inputValue).length;
    // console.log("check..",inputLength >= 9 && inputLength <= 10)
    return inputLength >= 9 && inputLength <= 10;
  };

  return (
    <div className={`${width} my-3 text-xs`}>
      <div className='px-3 flex flex-col gap-2'>
        <label className='mb-1 font-normal text-[13px] text-textColor-500'>
          {label}
          {isRequired ? <span className='text-red-500'>*</span> : ''}
        </label>
        <div className='flex items-center'>
          <Select
            className='w-auto md:w-[26%] h-10 '
            options={mobileCode}
            value={mobileCode.find((i) => i.value == formik.values[nunCode]) || undefined}
            allowClear={false}
            name={nunCode}
            placeholder={"00"}
            onChange={onChange || handleChange}
            disabled={disable}
          />
          <Input
            className={`w-full py-[5px] h-10`}
            style={{
              borderColor: isInputFocused ?
                isInputValid()
                  ? 'rgba(0, 255, 0, 0.3)'  // Light green with low opacity
                  : 'rgba(255, 0, 0, 0.3)'  // Light red with low opacity
                : ''
            }}
            name={name}
            placeholder={placeholder}
            value={formik.values[name] || ''}
            type='number'
            onChange={formik.handleChange || handleChange}
            max={max}
            min={min}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            disabled={disable ? true : false}
          />
        </div>
        {formik.errors[name] ? (
          <span className='text-xs font-semibold text-red-500'>
            {formik.errors[name]}
          </span>
        ) : null || (formik.values[name] && formik.values[nunCode] === '') ? (
          <span className='text-xs font-semibold text-red-500'>
            Select Country Code
          </span>
        ) : null}
      </div>
    </div>
  );
};

export const NuInputEmail = ({ label, name, formik, handleChange, width, placeholder, isRequired }) => {
  return (
    <div className={`${width} my-3`}>
      <div className=' px-3 text-textColor-500'>
        <label className='mb-2 font-normal text-[13px] text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input name={name} className='mt-2 h-10 px-3' placeholder={placeholder} value={formik.values[name] || ""} type='email' onChange={handleChange || formik.handleChange} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuDatePicker = ({ label, name, formik, handleChange, width, isRequired, max, min }) => {
  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className='mb-2 font-normal text-[13px] text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input className='mt-2 h-10' name={name} type='date' value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} min={min || ""} max={max || ""} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};


export const NuTimePicker = ({ label, name, formik, handleChange, width, isRequired }) => {
  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className=' mb-2 font-normal text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input className='mt-2 h-10' name={name} type='time' value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const YesOrNoButton = ({ width, label, isRequired, handleChange, name, formik }) => {
  const handleRadioChange = (e) => {
    formik.setFieldValue(name, e.target.value === true);
  };

  return (
    <div className={`${width} my-3`}>
      <div className='flex flex-col px-3'>
        <label className='mb-2 font-medium text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Radio.Group onChange={handleRadioChange} value={formik.values[name]}>
          <Radio value={true} >Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  )
}

export const NuSelect = ({ label, name, formik, onChange, options, placeholder, width, isRequired, isDisabled=false }) => {
  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e,
        name: name,
      },
    });
  };

  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className='mb-2 font-normal text-[13px] text-textColor-500' >{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Select
          style={{ width: '100%', marginTop: '8px', height:'40px'}}
          options={options}
          value={options.find((i) => i.value == formik.values[name]) || null}
          // allowClear={formik.values[name]?true:false}
          allowClear={false}
          disabled={isDisabled}
          name={name}
          placeholder={placeholder}
          onChange={onChange || handleChange}
          className=''
        />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

export const NuSelectCurrency = ({ label, name, formik, onChange, placeholder, width, isRequired }) => {
  const { Option } = Select;
  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e,
        name: name,
      },
    });
  };

  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className='mb-2 font-normal text-[13px] text-textColor-500' >{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Select
          style={{ width: '100%', marginTop: '8px',height:'40px' }}
          options={currencies}
          value={currencies.find((i) => i.value == formik.values[name]) || null}
          allowClear={false}
          name={name}
          placeholder={placeholder}
          onChange={onChange || handleChange}
        >
          {currencies.map((currency) => (
            <Option key={currency.value} value={currency.value}>
              <span dangerouslySetInnerHTML={{ __html: currency.label }} style={{ fontFamily: 'arial, sans-serif' }} />
            </Option>
          ))}
        </Select>
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};


export const NuMultiSelect = ({ label, name, formik, onChange, options, placeholder, width, isRequired }) => {
  // const [finalValue,setFinalValue] = useState([])
  const handleChange = (e) => {
    // setFinalValue({...finalValue,finalValue:e});
    // console.log(`Filter `,finalValue)
    // console.log(e);
    // console.log(x);
    formik.handleChange({
      target: {
        value: e,
        name: name,
      },
    });
  };
  // console.log('fffff',formik.values[name])
  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className='mb-2 font-medium text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Select
          mode="multiple"
          showSearch

          style={{ width: '100%',height:'40px' }}
          options={options}
          value={formik.values[name]}
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input)}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          allowClear={false}
          // allowClear={formik.values[name]?true:false}
          name={name}
          placeholder={placeholder}
          onChange={onChange || handleChange}
        />
        {/* {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null} */}
      </div>
    </div>
  );
};



export const NuSelectwithSearch = ({ label, name, formik, onChange, options, placeholder, width, isRequired }) => {
  const handleChange = (e) => {
    formik.handleChange({
      target: {
        value: e,
        name: name,
      },
    });
  };

  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className='mb-2 font-normal text-[13px] text-textColor-500' >{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Select
          showSearch
          style={{ width: '100%', marginTop: '8px' ,height:'40px'}}
          options={options}
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
          }
          value={options.find((i) => i.value == formik.values[name]) || null}
          allowClear={formik.values[name] ? true : false}
          // allowClear={false}
          name={name}
          placeholder={placeholder}
          onChange={onChange || handleChange}
        />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};





export const NuTextArea = ({ label, name, formik, handleChange, placeholder, isRequired, width = "w-full" }) => {
  const { TextArea } = Input;
  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className='mb-2 font-normal text-[13px] text-textColor-500 '>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <TextArea name={name} className='mt-2' placeholder={placeholder} value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} rows={4} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};


export const NuLabel = ({ label, textColor = 'text-black' }) => {
  return (
    <div className={`w-full my-4`}>
      <div className='px-3'>
        <label className={`${textColor} text-base font-semibold`}>{label}</label>
      </div>
    </div>
  );
};

export function NuInputSetting(props) {
  let {
    label, name, formik, handleChange, width, placeholder, disable, isRequired 
  } = props;
  const formikValues = formik ? formik.values[name] : "";
  const handleChangeFunc = formik ? formik.handleChange : handleChange;
  const formikErrors = formik && formik.errors ? formik.errors[name] : null;
  const focus = useRef('');
    const onFocus = () =>{
      focus?.current?.focus();
    }
  // console.log(max, "date");
  return (
    <div className={`${width} mb-2 `}>
      <div className=''>
        <label className='mb-1 font-normal text-[13px] text-textColor-500 '>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label><br />
        <Input className='mt-2 p-0.5 h-8' name={name} ref={focus} placeholder={placeholder} value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} disabled={disable ? true : false} suffix={
        <button className='bg-green-600 text-white hover:bg-green-700 text-xs w-10 h-full border-none rounded-sm cursor-pointer' type='submit'>
          Add
        </button>
      } />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
}

export const NuDateTime = ({ label, name, formik, handleChange, width, isRequired, max, min }) => {
  return (
    <div className={`${width} my-3`}>
      <div className=' px-3'>
        <label className='mb-2 font-normal text-[13px] text-textColor-500'>{label}{isRequired ? <span className="text-red-500">*</span> : ""}</label>
        <Input className='mt-2' name={name} type='datetime-local' value={formik.values[name] || ""} onChange={handleChange || formik.handleChange} min={min || ""} max={max || ""} />
        {formik.errors[name] ? <span className='text-xs font-semibold text-red-500'>{formik.errors[name]}</span> : null}
      </div>
    </div>
  );
};

// export function NuDataTime(props) {
//   const {
//     name,
//     placeholder,
//     label,
//     handleChange,
//     formik,
//     max,
//     min,
//     value,
//     isRequired,
//     onChange,disabled,
//     width
//   } = props;
//   const formikValues = formik ? formik.values[name] : "";
//   const handleChangeFunc = formik ? formik.handleChange : handleChange;
//   const formikErrors = formik && formik.errors ? formik.errors[name] : null;
//   return (
//     <div className={`px-6 py-2 ${width}`}>
//       <label htmlFor={name} className="text-xs 2xl:text-sm">
//         {label}
//         {isRequired ? <span className="text-red-500"> *</span> : ""}
//       </label>
//       <input disabled={disabled ? true : false}
//           // id={name} 
//         placeholder={placeholder}
//         type="datetime-local"
//         name={name}
//         max={max || ""}
//         min={min || ""}
//         value={value || formikValues}
//         onChange={onChange || handleChangeFunc}
//         // onBlur={handleBlur}
//         className={
//           "text-xs 2xl:text-sm font-medium border w-full h-10 px-3 mt-2 rounded-[10px] outline-none"
//         }
//         // multiple
//       />
//       {formikErrors ? (
//         <span className="text-xs font-semibold text-red-500">
//           {formikErrors}
//         </span>
//       ) : null}
//     </div>
//   );
// }