import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const CategoryRef = () => {
  return (
    <SettingsMetaData propertyName={"category_reference"} viewPropertyName={"Category"} />

  )
}

export default CategoryRef