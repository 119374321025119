import { useLocale } from 'antd/es/locale';
import React from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { NormalHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';
import NuDate from '../../../../Components/Utils/NuDate';

const ViewVisitorTracking = () => {
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const { VisitorTrackingViewId } = useParams();
    const location = useLocation();
    const data = location.state.data;
  return (
    <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Accomodation</p> },
            { title: <p className=" text-[12px] ">Visitor-Tracking</p> },
            { title: <p className=" text-[12px] text-[#4E4B4B]">Edit</p> },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/accomodation/visitors-tracking/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/accomodation/visitors-tracking/${officeId}/edit/${VisitorTrackingViewId}`} 
          state={{data: data, from:`/main/accomodation/visitors-tracking/${officeId}/view/${VisitorTrackingViewId}`}}
          ><EditButton height='8'/></Link>
        </div>
      </div>
      <div className='w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden p-3'>
          <NormalHeading title={"Visitors Details"} />
          <div className=" w-full flex flex-wrap ">
              <div className=" w-full grid grid-cols-3 gap-x-8 mx-4">
                <TempComponent label={"Id"} value={data?.visitorId||"-"} />
                <TempComponent label={"Name"} value={data?.visitorName||"-"} />
                <TempComponent label={"Mobile"} value={data?.visitorMobile.display||"-"} />
                <TempComponent label={"Visit Count"} value={data?.visitNumber||"-"} />
                <div className='w-full col-span-2'>
                  <p className='py-1 text-xs 2xl:text-sm font-semibold text-[#111537]'>Purpose Of Visit</p>
                  <p className='py-1 text-xs 2xl:text-sm text-[#606279] line-clamp-6'>{data.purposeOfVisit || ''}</p>
                </div> 
                </div>
              </div>
              <NormalHeading title={"Occupant & Date  Details"} />
              <div className=" w-full grid grid-cols-3 gap-x-8 mx-4">
              <TempComponent label={"Name"} value={data?.occupantName||"-"} />
              <TempComponent label={"Date"} value={data?.date && <NuDate value={data?.date}  format="DD-MMM-YYYY"/>||"-"} />
              <TempComponent label={"Time In"} value={data?.timeIn||"-"} />
              <TempComponent label={"Time Out"} value={data?.timeOut||"-"} />
              </div>
      </div>
    </div>
  )
};

export default ViewVisitorTracking;


function TempComponent({label,value}) {
  return (
    <div className='w-full'>
    <p className='py-1 text-xs 2xl:text-sm font-semibold text-[#111537]'>{label}</p>
    <p className='py-1 text-xs 2xl:text-sm text-[#606279]'>{value}</p>
  </div> 
  )
}