import React, { useEffect, useState } from "react";
import { NormalHeading, SmallsubHeading } from '../../../../Components/Utils/Heading';
import { Link, useLocation, useParams } from 'react-router-dom';
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';

function ViewVehicleMaintenance() {
  const { officeId } = useParams();
  const location = useLocation();
  const data = location.state.data;
    return (
      <div className='w-full h-full px-4 py-2'>
        <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: (<p className=' text-[12px] '>Maintenance</p>) },
            { title: (<p className=' text-[12px] '>Vehicle</p>) },
            { title: (<p className=' text-[12px] '>View</p>) },
          ]}
        />
        <div className=" flex gap-2">
          <Link to={`/main/maintenance/vehicle-maintenance/${officeId}`}><BackButton link={true}/></Link>
          <Link to={`/main/maintenance/vehicle-maintenance/${officeId}/edit/${data?.vehicleMaintainId}`} state={{data: data, from:`/main/maintenance/vehicle-maintenance/${officeId}/view/${data?.vehicleMaintainId}`}}><EditButton height='8'/></Link>
        </div>
      </div>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
              <h2 className='text-base font-normal text-[#111537] p-3'>Details</h2>
              <div className=" w-full flex flex-wrap ">
                <div className=" w-full grid grid-cols-5 gap-x-8 m-10">
                <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.vehicleMaintainId || '-'}</p>
                  </div>
                <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Vehicle Id</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.vehicleId|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Meter Reading</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.odoMeterReading || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Maintenance Period</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.maintenancePeriod || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Mileage Since Last Maintenance</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.mileageSinceLastMaintenance || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Maintenance Type</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.maintenanceType || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Maintenance Start Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.maintenanceStartDate || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Maintenance End Date</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.maintenanceEndDate || '-'}</p>
                  </div>

                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Next Maintenance</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.nextMaintenance ||  '-'}</p>
                  </div>
                  
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Work Done By</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.workDoneBy || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Details Of Parts</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.detailsOfParts || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Reason</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.reason || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Inspection Result</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.inspectionResult || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Agreement Info</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.agreementInfo || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>History</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.history || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537] '>Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments || '-'}</p>
                  </div>
                 
                  </div>
                  
                  
                  
                  </div>
                  </div>
          </div>
          
   
    );
}

export default ViewVehicleMaintenance