import React, { useState,useEffect } from 'react'
import { Link,useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CreateButton, FilterButton, SubmitButton, CancelButton,DeleteButton} from '../../../../Components/Utils/Buttons';
import DataTable from '../../../../Components/Layout/DataTable';
import { currencies } from '../../../../assets/CountryMini';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { NuInputNumber, NuSelect, NuInputText, NuSelectwithSearch } from '../../../../Components/Utils/NuInput';
import { useFormik } from 'formik';
import * as yup from 'yup'
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { Modal } from 'antd';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import ViewMoreAsserts from './ViewMoreAsserts';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function AssertsIndex() {
  const {data:suppliers} = UseDropDown('/vendors/drop-down')
  const { value: CalliberReq} = useFetchMataData('CalliberReq') 
  const { value: WarrentyApplicable} = useFetchMataData('WarrentyApplicable')
  const { value: Category} = useFetchMataData('Category') 
  const { value: SubCategory} = useFetchMataData('SubCategory')
  const { officeId } = useParams();
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState(20)
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const { data, totalCount, getData } = UseGetDataSearch( '/stocks/assets',params);
  console.log(data,'dddd');
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
  const [showFilterDialog, setShowFilterDialog] = useState(false);
const [filter, setFilter] = useState(false);
  const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
    const[filterasset,setfilterasset]=useState({
      category:"",
        subCategory:"",
        supplier:"",
        warrentyApplicable:"",
        calibReq:"",
    });
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterasset,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params, category: value?.category,subCategory: value?.subCategory, supplier: value?.supplier,warrentyApplicable: value?.warrentyApplicable,calibReq: value?.calibReq,})
        closeFilterDialog();
        setFilter(true)
        setfilterasset({
          category:"",
          subCategory:"",
          supplier:"",
          warrentyApplicable:"",
          calibReq:"",
        });
      }
    })
    const clearFilter = ()=> {
      setParams({...params,
        category:"",
        subCategory:"",
        supplier:"",
        warrentyApplicable:"",
        calibReq:"",
     
      })
      formik.values.category=""
        formik.values.subCategory=""
        formik.values.supplier=""
        formik.values.calibReq=""
        formik.values.warrentyApplicable=""
        setFilter(false)
    }

    let headers = [
        {
          label: <span>ID</span>,
          width: "w-[5%]",
        },
        // {
        //   label: <span>Meal Id</span>,
        //   width: "w-[5%]",
        // },
        {
          label: <span>Name</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Brand</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Manufacturer</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Quantity</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Condition</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Stock Location</span>,
          width: "w-[10%]",
        },
        {
          label: <span>MRP Price</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Actions</span>,
          width: "w-[5%]",
        },
      ];

      useEffect(()=>{
        setParams({...params,page:page,per_page:pageCount})
       },[page,pageCount])
     
return (
  <div className='w-full h-full px-4 py-2'>
    <div className='w-full flex justify-between items-center'>
    <BreadCrumb items={[
      {title:(<Link to="/main/dashboard">
      <Dashboard color="gray" width={10} height={10} />{" "}
    </Link>)},
      {title:(<p className=' text-[12px] '>Invontary</p>)},
      {title:(<p className=' text-[12px] '>Asserts</p>)}
      ]} />
    <div className=' flex gap-2'>
     <SearchBar
     handleChange={(e) =>
      setParams({ ...params, search: e.target.value })
    }
     />
     <FilterButton height='8' 
      handleClick={() => setShowFilterDialog(true)}/>
      {filter && ( 
           <FilterButton 
          handleClick={() => clearFilter()} 
          label="Clear" /> 
        )}
     <CreateButton label='Import' />
     <Link to={`/main/inventary/asserts/${officeId}/create`}><CreateButton label='Asset'/></Link> 
    </div>
    </div>
    <DataTable
     bodyHeight='max-h-[calc(100vh-260px)]'
     height='h-[calc(100vh-130px)]'
     headers={headers} 
     total={totalCount}
     pageIndex={page}
     setPageIndex={setPage}
     dataCount={pageCount}
     setDataCount={setPageCount}
    >
    {data.length>0?data.map((item, i) => {
            let getCurrency;
            currencies.find((i) => {
              if(i.value == item?.currency){
                getCurrency=i.label;
              }
            })
            return (
              <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                <td className="w-[5%] p-2 px-[1px] text-center ">{item?.stockId||"#"}</td>
                {/* <td className="w-[5%] p-2 px-[1px] text-center ">{item?.menuId||"#"}</td> */}
                {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.name||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.brand||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.manufacturer||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.quantity||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.condition||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.stockLocation||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.mrp||""}</td>
                
                <td className="w-[5%] p-2 px-[1px] text-center ">
                    <ViewMoreAsserts data={item} fetcher={getData} id={item?.stockId} />
                </td>
              </tr>
            );
          })
          :
          <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
        }
    </DataTable>

    <Modal
      open={showFilterDialog}
      title={"Asset"}
      width={"50%"}
      className="my-modal"
      centered
      onCancel={closeFilterDialog}
      footer={[]}
    >
               <form onSubmit={formik.handleSubmit}> 
                  <div className="flex flex-col mb-4">
                  
                  <NuSelectwithSearch
              label="Supplier"
              placeholder="Supplier"
              formik={formik}
              options={suppliers}
              name="supplier"
              width="w-full"
            />
                    <NuSelect
              label="Category"
              options={Category}
              formik={formik}
              placeholder="Category"
              name="category"
              width="w-full "
            />
            <NuSelect
              label="Sub Category"
              options={SubCategory}
              formik={formik}
              placeholder="Sub Category"
              name="subCategory"
              width="w-full "
            />
                          <NuSelect
              label="Warrenty Applicable"
              options={WarrentyApplicable}
              formik={formik}
              placeholder="Warrenty Applicable"
              name="warrentyApplicable"
              width="w-full "
            />
            <NuSelect
              label="Caliber Request"
              options={CalliberReq}
              formik={formik}
              placeholder="calibReq"
              name="calibReq"
              width="w-full "
            />
                  </div>
                  
                 <div className="flex justify-end gap-2">
                   <CancelButton handleClick={closeFilterDialog} />
                   <SubmitButton name='Apply' />
                 </div>
                </form> 
             {/* </div>
           </div>
         )} */}
         </Modal>         
  </div>
)
}

export default AssertsIndex