import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { Modal } from 'antd';
import Dashboard from '../../../../Components/Icons/Dasboard';

function ViewProducts() {
  const { officeId } = useParams();
  const { stocksViewId } = useParams();
  const location = useLocation();
    const data = location.state.data;
    console.log(location.state,'state');

    const [url,setUrl] = useState(false);

    console.log("bbb",data?.productId);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };

    return (
        <>
                <div className=' bg-[#E6F2F2] h-full'>
                <div className='flex justify-between items-center py-2 mx-6'>
                <BreadCrumb items={[
                  {
                    title: (
                      <Link to="/dashboard">
                        <Dashboard color="gray" width={10} height={10} />{" "}
                      </Link>
                    ),
                  },
                    
                    { title: <p className='text-[13px]'>Procurement</p> },
                    { title: <p className='text-[13px]'>Product</p> },
                    { title: <p className='text-[13px]'>View</p> },
                ]}
                />
                <div className='w-1/2 flex justify-end'>
                    <div className='flex gap-3'>
                        <Link to={`/main/procurment/products/${officeId}`}><BackButton link={true}/></Link>
                        <div>
                            <Link 
                            to={`/main/procurment/products/${officeId}/edit/${data?.productId}`} 
                            state={{ data : data , from : `/main/procurment/products/${officeId}/view/${data?.productId}`}} >
                                <EditButton height='8' />
                            </Link>
                        </div> 
                    </div>
                </div>
            </div>
            {/*  Section-1 */}
            <div className='px-6 py-5 flex  gap-9'>
                {/* <div className='flex flex-col gap-2 px-5 py-3 w-[30%] h-auto bg-[#FFF] rounded-[5px] p-5'>
                  
                    <div className='flex gap-5 h-full'>
                        <div className='w-full h-full flex items-center p-5 gap-2 bg-[#E6F2F2] rounded-[5px]'>
                        {data?.image && <img className='w-full h-auto' src={data?.image } alt='Product' />}
                        </div>
                        <div className='mx-10 border-r border-#E6F2F2' />
                        
                    </div>
                </div> */}
                <div className='flex flex-col gap-2 px-5 py-3 w-full h-[calc(100vh-170px)] bg-[#FFF] rounded-[5px] p-5'>
                    <h3 className='text-[#212121] text-sm font-bold leading-normal'>Details</h3>
                    
                    <div className='w-full flex flex-wrap h-[350px] overflow-scroll'>
                            <div className='w-full grid grid-cols-5 gap-x-8 m-10'>
                            <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>ID</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.productId|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Product Name</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.productName|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Item Code</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.itemCode || '-'}</p>
                  </div>
                  {/* <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Company Id</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.companyId || '-'}</p>
                  </div> */}
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Category</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.category || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Sub Category</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?. subCategory || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Unit</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.unit || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Brand</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.brand|| '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Manufacturer</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.manufaturer || '-'}</p>
                  </div>
                  <div className='w-full grid px-2'>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#111537]'>Comments</p>
                    <p className='w-1/2 py-3 text-sm font-medium text-[#606279]'>{data?.comments|| '-'}</p>
                  </div>
                  
                            
                            </div> 
                           
                        </div>
                    
                    <h3>Product Attachement Files</h3>
                    <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
    {data?.productImage.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                                // onClick={() => handleImageClick(imgs)}
                                                onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                                >
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    <img src={imgs?.file} alt={imgs?.file} />
                                                </div>
                                            </div>
                                         );
                                    })} 
                                    </div>
                </div>
               


            </div>
          
          
        </div>
        
        
        <Modal
        open={showFilterDialog}
        title={"Products"}
        width={"80%"}
        
        className="my-modal"
        onCancel={closeFilterDialog}
        footer={[]}
      >
        <div className='w-full h-[80vh] overflow-hidden'>
        <img src={url} alt="" className='' width={"100%"} />
        </div>
           </Modal>
        </>

    )
}

export default ViewProducts