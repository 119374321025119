import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import { Link, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import axiosInstance from '../../../../Services/axiosCommon';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditFoodMenu() {
  const { value: Category} = useFetchMataData('Category') 
  const { value: Type} = useFetchMataData('Type') 
  const { value: SubCategory} = useFetchMataData('SubCategory')
  // const { data: employees } = UseDropDown('/employees/drop-down');
  // const { data: bed } = UseDropDown('/rooms/drop-down');
  const location = useLocation();
  const { EditFoodMenuId } = useParams();
  const data = location.state.data;
  const from = location.state.from;
  const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
  const [createNew, setCreateNew] = useState({
    
      foodName:data?.foodName ,
      category:data?.category ,
      subCategory: data?.subCategory ,
      type:data?.type,
      branchId:data?.branchId
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
        // const valueWithItems = {
        //     ...value,
        //     floors: items,
        // };
        editFormData(`/food-menu/edit/${EditFoodMenuId}`, value, resetForm, from);
    }
})
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
            <div className="flex justify-between px-4">
                <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Catering</p>) },
                    { title: (<p className=' text-[12px] '>Food Menu</p>) },
                    { title: (<p className=' text-[12px] '>Edit</p>) },
                ]} />
                <BackButton />
            </div>
            <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInputText
              isRequired={true}
              label="Food Name"
              placeholder="Food Name"
              formik={formik}
              name="foodName"
              width="w-full md:w-1/3"
            />
             <NuSelect
              // isRequired={true}
              label="Category"
              options={Category}
              formik={formik}
              placeholder="Choose"
              name="category"
              width="w-full md:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label="Sub Category"
              options={SubCategory}
              formik={formik}
              placeholder="Choose"
              name="subCategory"
              width="w-full md:w-1/3"
            />
            {/* <NuDatePicker
              isRequired={true}
              label="Date Of Birth"
              formik={formik}
              name="dob"
              width="w-full md:w-1/3"
            /> */}
            <NuSelect
              // isRequired={true}
              label="Type"
              options={Type}
              formik={formik}
              placeholder="Choose"
              name="type"
              width="w-full md:w-1/3"
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel() }} />
                <SubmitButton isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default EditFoodMenu