import React, { useEffect, useState } from 'react'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { Link, useParams } from 'react-router-dom'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons'
import axiosInstance from '../../../../Services/axiosCommon'
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';

const ViewBuildings = () => {
    const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const { buildingViewId } = useParams();
  const [data, setData] = useState([]);
  const [image, setImage] = useState({ preview: "", raw: "" });
  const [createNew, setCreateNew] = useState({
    // campId: "",
    // buildName:"",
    // buildNo: "",
    // floors: ""
    buildPlan: data?.buildPlan,
    campId: data?.campId,
    buildName: data?.buildName,
    buildNo: data?.buildNo,
    numOfFloors:data?.numOfFloors,
    floors: data?.floors,
   
})
const handleChangeImg = (e) => {
    if (e.target.files.length) {
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0],
        });
    }
};
const handleImageRemove = () => {
    setImage({
        preview: "",
        raw: "",
    });
}
  async function getBiding() {
    await axiosInstance
      .get(`/buildings/${buildingViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getBiding();
  },[]);
  console.log(data?.buildPlan,"data");
    return (
      <div className=" bg-[#E6F2F2] h-full">
        <div className=" w-full px-6 my-2 flex justify-between items-center">
          <BreadCrumb
            items={[
              { title: <p className="text-[13px]">Accommodation</p> },
              { title: <p className="text-[13px]">Buildings</p> },
              { title: <p className="text-[13px]">View</p> },
            ]}
          />

          <div className="w-1/2 flex justify-end">
            <div className=" flex gap-3">
              <div className="flex justify-end gap-2">
                <Link to={`/main/accomodation/building/${officeId}`}>
                  <BackButton link={true} />
                </Link>
                <Link
                  to={`/main/accomodation/building/${officeId}/edit/${buildingViewId}`}
                  state={{
                    from: `/main/accomodation/building/${officeId}/view/${buildingViewId}`,
                    data: data,
                  }}
                >
                  <EditButton height="8" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/*  Section-1 */}
        <div className="px-6  w-full h-[calc(100vh-115px)] flex  gap-5">
          <div className="flex flex-col gap-2 px-5 py-2 w-1/2 h-full bg-[#FFF] rounded-lg shadow-box ">
            <h3 className="text-[#212121] text-sm 2xl:text-base font-semibold leading-normal">
              Building Layout :
            </h3>

            <div className="">
              <div className=" w-full h-[calc(100vh-350px)] flex items-center  gap-2  rounded-[5px]">
                <img src={data?.buildPlan} alt="" className="items-center max-h-[100%] max-w-[100%]" />
              </div>
              <h3 className="text-[#212121] text-sm 2xl:text-base font-semibold leading-normal mt-2">
               Details :
              </h3>
              <div className=" w-full   grid grid-cols-2 gap-4 px-2" >
              <TempComponent label={"ID"} value={data?.buildId || "-"} />
              <TempComponent label={"Camp Id"} value={data?.campId || "-"} />
              <TempComponent label={"Building Name"} value={data?.buildName || "-"} />
              <TempComponent label={"Building No."} value={data?.buildNo || "-"} />
              <TempComponent label={"No. Of Floor"} value={data?.numOfFloors || "-"} />
              </div>
            </div>
          </div>
          <div className="w-1/2 h-[calc(100vh-115px)] px-5 py-2 bg-white overflow-y-scroll rounded-lg shadow-box">
          <h3 className="text-[#212121] text-sm 2xl:text-base font-semibold leading-normal">
              Floor Details :
            </h3>
            <div className="w-full  bg-[#048178] flex justify-between rounded-lg">
              <p className=" w-[55%] text-left px-1 py-1 text-white text-xs 2xl:text-sm font-medium">
                Floor Name
              </p>
              <p className=" w-[20%] text-left px-1 py-1 text-white text-xs 2xl:text-sm font-medium">
                Floor Number
              </p>

              <p className=" w-[25%] text-left px-1 py-1 text-white text-xs 2xl:text-sm font-medium">
                Total Rooms on Floor
              </p>
            </div>
            <div className="overflow-scroll h-[340px]">
              {data?.floors?.map((data, index) => {
                return (
                  <div key={index} className="w-full py-2 flex justify-between">
                    <p className=" w-[55%] text-left px-1 py-1 text-black text-xs 2xl:text-sm font-light ">
                      {data?.floorName}
                    </p>
                    <p className=" w-[20%] text-left px-2 py-1 text-black text-xs 2xl:text-sm font-light">
                      {data?.floorNo}
                    </p>
                    <p className=" w-[25%] text-left px-2 py-1 text-black text-xs 2xl:text-sm font-light">
                      {data?.numOfRooms}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
}

export default ViewBuildings



function TempComponent({label,value}){
    return (
        <div className="w-full">
        <p className="text-[#212121] text-xs 2xl:text-sm font-medium ">{label}</p>
        <p className="text-[#4D4D4D] text-xs 2xl:text-sm">{value || "-"}</p>
      </div>
    )
}