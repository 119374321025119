import React, { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputText, NuSelect, NuTextArea, NuInputNumber, NuMultiSelect, NuDateTime } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Navigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dropdown from 'antd/es/dropdown/dropdown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';

function CreateMovements() {
  const { officeId } = useParams();
  const { value: FromPlaceValue, getData: FromPlace } = useFetchMataData('FromPlace')

  const { value: FromPlacee } = useFetchMataData('FromPlace')
  const { value: ToPlace } = useFetchMataData('ToPlace')


  const { data: user } = UseDropDown('/user/drop-down')
  const { data: camp } = UseDropDown('/camp/drop-down')
  const { data: employees } = UseDropDown('/employees/drop-down')
  console.log("ds", employees)
  const { data: occupant } = UseDropDown('/occupants/drop-down')
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    // reqType: "RoomService",
    date: "",
    fromPlace: "",
    toCamp: "",
    employees: "",
    branchId: officeId,
    // manufacturer:"",
    // equipmentType:"",
    // warrentyPeriod:"",
    // warrentyExpDate:"",
    // calibExpDate:"",
    // condition:"",
    // calibReq:"",
    // comments:"",

  })


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {


      addFormData('/movements/', value, resetForm);
    }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
      <div className="flex justify-between px-4">
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Listing</p>) },
          { title: (<p className=' text-[12px] '>Movements</p>) },
          { title: (<p className=' text-[12px] '>Create</p>) },
        ]} />
        <BackButton />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-135px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">

            {/* <NuInputNumber
              // isRequired={true}
              label=" SerialNo"
              placeholder=" SerialNo"
              formik={formik}
              name="serialNo"
              width="w-full md:w-1/3"
            />
           <NuInputNumber
              // isRequired={true}
              label=" Quantity"
              placeholder=" Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />

          <NuInputText
              // isRequired={true}
              label="Brand"
              placeholder=" Brand"
              formik={formik}
              name="brand"
              width="w-full md:w-1/3"
            />
             <NuInputNumber
              // isRequired={true}
              label=" Unit"
              placeholder=" Unit"
              formik={formik}
              name="unit"
              width="w-full md:w-1/3"
            />
             <NuInputText
              // isRequired={true}
              label="Manufacturer"
              placeholder=" Manufacturer"
              formik={formik}
              name="manufacturer"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Equipment Type"
              options={[
                { value: "Two-wheelar" , label: "Two-wheelar" },
                { value: "Four-wheelar", label: "Four-wheelar" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="equipmentType"
              width="w-full md:w-1/2 lg:w-1/3"
          /> */}
            <NuDateTime
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label='From Place'
              placeholder='Choose'
              formik={formik}
              Options={FromPlaceValue}
              name='fromPlace'
              getFunc={() => FromPlace()}
              displayProperty={"From Place"}
              propertyName={"FromPlace"}
              width='w-full md:w-1/2 lg:w-1/3' />
            {/* <NuDatePicker
              label="Warrenty Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Caliber Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="calibExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Condition"
              placeholder=" Condition"
              formik={formik}
              name="condition"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Caliber Request"
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              formik={formik}
              placeholder="calibReq"
              name="calibReq"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}


            {/* <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            /> */}
            <NuSelect
              label="To Camp"
              options={camp}
              placeholder="To Camp"
              formik={formik}
              name="toCamp"
              width="w-full md:w-1/3"
            />
            <NuMultiSelect
              // isRequired={true}
              label="Employees"
              options={employees}
              placeholder=" Employees"
              formik={formik}
              name="employees"
              width="w-full md:w-1/3"
            />
            {/* <NuSelect
              label="Menu Id"
              options={[
                { value: 1, label: "Veg" },
                { value: 2, label: "Non-veg" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="menuId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}

            {/* <NuInputNumber
              label="Prpared By"
              placeholder="prparedBy"
              formik={formik}
              name="prparedBy"
              width="w-full md:w-1/3"
            /> */}
            {/* <NuInputNumber
              label="Bed No"
              placeholder="Bed No"
              formik={formik}
              name="Bed No"
              width="w-full md:w-1/3"
            /> */}



            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel() }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateMovements