import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import { Link, Navigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import Dashboard from '../../../../Components/Icons/Dasboard';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { NormalHeading } from '../../../../Components/Utils/Heading';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';


function CreatePatient() {
  const { value: InsuranceTypeValue, getData: InsuranceType } = useFetchMataData('InsuranceType')
  const { value: RevisitValue, getData: Revisit } = useFetchMataData('Revisit')


  const { value: InsuranceTypee } = useFetchMataData('InsuranceType') 
  const { value: Gender } = useFetchMataData('Gender') 

  const {officeId} = useParams();

  const { data: occupant } = UseDropDown('/occupants/drop-down')
  const [image, setImage] = useState({ preview: "", raw: "" });
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();

  const [createNew, setCreateNew] = useState({
    occupantId: "",
    gender: "",
    nationality: "",
    probReported: "",
    medAndDosage: "",
    revisitReq: null,
    revisitDate: "",
    medHistory: "",
    insuranceType: "",
    insuranceExp: "",
    status: "",
    comments: "",
    branchId:officeId
  })
  const handleChangeImg = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      let formData = new FormData();
      formData.append('json_data', JSON.stringify(value));
      formData.append('insuranceImage', image.raw);

      addFormData('/patients/new', formData, resetForm);
    }
  })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
      <div className="flex justify-between px-4">
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Clinic</p>) },
          { title: (<p className=' text-[12px] '>Patients</p>) },
          { title: (<p className=' text-[12px] '>Create</p>) },
        ]} />
        <BackButton />
      </div>
      <div className="w-full h-full">
        <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
          {contextHolder}
          <div className="w-[96%] mx-auto ">
            <form onSubmit={formik.handleSubmit} className="w-full">
              <NormalHeading title={'Layout Area'} />
              <div className='flex flex-wrap md:flex-nowrap pb-4'>
                <div className='w-full md:w-1/3 rounded-md border border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 justify-center items-center'>
                  <CustomImgUpload
                    borderStyle="dashed"
                    isRemove={true}
                    width='w-1/3 md:w-1/2'

                    removeFunction={handleImageRemove}
                    handleChange={handleChangeImg}
                    preview={image.preview || createNew.image || ""}
                  />
                </div>
                <div className='w-full'>
                  <div className='flex w-full'>
                    <NuSelect
                      label="Occupant Id"
                      options={occupant}
                      formik={formik}
                      placeholder="Choose"
                      name="occupantId"
                      width="w-full md:w-1/2 lg:w-2/4"

                    />
                    <NuSelect
                      label="Gender"
                      options={Gender}
                      formik={formik}
                      placeholder="Choose"
                      name="gender"
                      width="w-full md:w-1/2 lg:w-2/4"
                    />
                  </div>
                  <div className='flex w-full'>
                    <NuInputText
                      label="Nationality"
                      placeholder="Nationality"
                      formik={formik}
                      name="nationality"
                      width="w-full md:w-2/4"
                    />
                    <NuInputText
                      label="Problem Reported"
                      placeholder="Problem Reported"
                      formik={formik}
                      name="probReported"
                      width="w-full md:w-2/4"
                    />

                  </div>
                </div>

              </div>
              <div className="w-full flex justify-start gap-y-2 content-start pb-4 flex-wrap">
                <NuInputText
                  label="Medicine And Dosage"
                  placeholder="Medicine And Dosage"
                  formik={formik}
                  name="medAndDosage"
                  width="w-full md:w-1/3"
                />
                {/* <DropDownAddMetaData
                  label="Revisit Required"
                  Options={RevisitValue}
                  formik={formik}
                  placeholder="Choose"
                  name="revisitReq"
                  getFunc={() => Revisit()}
                  displayProperty={"Revisit"}
                  propertyName={"Revisit"}
                  width="w-full md:w-1/2 lg:w-1/3"
                /> */}
                <NuSelect
              label="Revisit Required"
              options={[
                { value: true, label: "Yes" },
                { value: false, label: "No" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="revisitReq"
              width="w-full md:w-1/2 lg:w-1/3"
            />
                <NuDatePicker
                  label="Revisit Date"
                  placeholder="dd/mm/yyyy"
                  formik={formik}
                  name="revisitDate"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
                <NuInputText
                  label="Medication History"
                  placeholder="Medication History"
                  formik={formik}
                  name="medHistory"
                  width="w-full md:w-1/3"
                />
                <DropDownAddMetaData
                  label="Insurance Type"
                  Options={InsuranceTypeValue}
                  formik={formik}
                  placeholder="Choose"
                  name="insuranceType"
                  getFunc={() => InsuranceType()}
              displayProperty={"Insurance Type"}
              propertyName={"InsuranceType"}
                  width="w-full md:w-1/2 lg:w-1/3"
                />
                <NuDatePicker
                  label="Insurance Expiry"
                  placeholder="dd/mm/yyyy"
                  formik={formik}
                  name="insuranceExp"
                  width="w-full md:w-1/2 lg:w-1/3"
                />
              </div>
              {/* <NuSelect
              label="Status"
              options={[
                { value: "1", label: "A" },
                { value: "2", label: "B" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="Status"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
              <NuTextArea
                label="Comments"
                placeholder="Comments"
                formik={formik}
                name="comments"
                width='w-full md:w-1/2'
              />

              <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton handleClick={() => { Oncancel(-1) }} />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
            </form>
          </div>

        </div>
      </div>
    </div>

  )
}

export default CreatePatient

