import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { notification } from 'antd';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputText, NuMobileNumber, NuSelect, NuSelectCurrency, NuTextArea, NuTimePicker } from '../../../../Components/Utils/NuInput';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseAddData from '../../../../Components/Hooks/useAddData';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
// import Room from '../Rooms';

function CreateFoodMenu() {
  const { value: TypeValue, getData: Type } = useFetchMataData('Type')
  const {officeId} = useParams()

  // const { data: employee } = UseDropDown('/employees/drop-down');
  // const { data: bed } = UseDropDown('/rooms/drop-down');
  const { value: Category} = useFetchMataData('Category') 
    const { value: Typee} = useFetchMataData('Type') 
    const { value: SubCategory} = useFetchMataData('SubCategory') 
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
    const [createNew, setCreateNew] = useState({
      foodName: "",
      category: "",
      subCategory: "",
      type: "",
      branchId:officeId,
    })
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
      }),
      onSubmit: async (value, { resetForm }) => {
          addFormData('/food-menu/new', value, resetForm);
      }
  })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
            <div className="flex justify-between px-4">
                <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Catering</p>) },
                    { title: (<p className=' text-[12px] '>Food Menu</p>) },
                    { title: (<p className=' text-[12px] '>Add</p>) },
                ]} />
                <BackButton />
            </div>
            <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInputText
              isRequired={true}
              label="Food Name"
              // options={employee}
              formik={formik}
              placeholder="Choose"
              name="foodName"
              width="w-full md:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label="Category"
              options={Category}
              formik={formik}
              placeholder="Choose"
              name="category"
              width="w-full md:w-1/3"
            />
            <NuSelect
              // isRequired={true}
              label="Sub Category"
              options={SubCategory}
              formik={formik}
              placeholder="Choose"
              name="subCategory"
              width="w-full md:w-1/3"
            />
            {/* <NuDatePicker
              // isRequired={true}
              label="Sub Category"
              formik={formik}
              name="subCategory"
              width="w-full md:w-1/3"
            /> */}
            <DropDownAddMetaData
              // isRequired={true}
              label="Type"
              Options={TypeValue}
              formik={formik}
              placeholder="Choose"
              name="type"
              getFunc={() => Type()}
              displayProperty={"Type"}
              propertyName={"Type"}
              width="w-full md:w-1/3"
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel() }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateFoodMenu