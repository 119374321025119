import React, { useState } from 'react'
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import {  NuInputNumber, NuInputText, NuSelect } from '../../../../Components/Utils/NuInput';
import { Input } from 'antd';
import UseDropDown from '../../../../Components/Hooks/useDropDown';


function CreateBuildings() {
    const {officeId} = useParams();
    const {data:camps} =UseDropDown('/camp/drop-down')
    const [image, setImage] = useState({ preview: "", raw: "" });
    // const [division, setDivision] = useState([]);
    const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
    const [createNew, setCreateNew] = useState({
        campId:"",
        buildName:"",
        buildNo:"",
        floors:"",
        branchId: officeId,
       
    })
    
    const [items, setItems] = useState([])
    const [newData , setNewData]=useState({
        floorName:"",
        floorNo:"",
        numOfRooms:""

        // Total Rooms on Floor:
    })
    console.log("ddd", items);
    console.log("ddd", items);

    function handleDelete(idx) {
        const list = items.filter((item, i) => i !== idx);
        setItems(list);
        

    }
    const handleAdd =()=>{
        // if (items.length < formik.values.numberOfFloors) {
        const newItem = {
            
            floorName: formik.values.floorName,
            floorNo: formik.values.floorNo,
            numOfRooms: formik.values.numOfRooms,
            // Total Rooms on Floor:newData.floorNo
          };
          console.log(newItem,'jkh');
          setItems([...items, newItem]);
          // Update local storage
          setNewData({
            floorName: "",
            floorNo: "",
            numOfRooms:"",
           
          });
       
          formik.values.floorName=""
          formik.values.floorNo=""
          formik.values.numOfRooms=""
          
          
        // }
        // else{
        //     alert("You have reached the maximum limit of floors.");
        // }
    };
        // const handleChange = (e) => {
        //     setNewData({
        //         ...newData,
        //         [e.target.name]: e.target.value,
        //     });
        // };
    
    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };
    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            const valueWithItems = {
                ...value,
                floors: items,
              };
            let formData = new FormData();
            formData.append('json_data', JSON.stringify(valueWithItems));
            formData.append('attachments', image.raw);

            addFormData('/buildings/new', formData, resetForm);
        }
    })
    
return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
        {contextHolder}
            <div className="flex justify-between px-4 my-1">
                <BreadCrumb items={[
                {
                    title: (<Link to="/main/dashboard">
                        <Dashboard color="gray" width={10} height={10} />{" "}
                    </Link>)
                },
                { title: (<p className=' text-[12px] '>Accommodation</p>) },
                { title: (<p className=' text-[12px] '>Buildings</p>) },
                { title: (<p className=' text-[12px] '>Create</p>) },
            ]} />
            <BackButton/>
            </div>
    
                <div className="w-full h-[calc(100vh-100px)] px-3 overflow-y-scroll">
                    
                    <div className="w-full ">
                      
                        <form onSubmit={formik.handleSubmit} className="w-full ">
                            <div className="w-full h-[calc(100vh-160px)] flex gap-x-6">
                            <div className='flex gap-8 md:flex-nowrap p-4  h-full  w-full md:w-[40%] bg-white rounded-lg'>
                            <div className='w-full md:w-1/2 rounded-md  h-full border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 flex-col'>

                                <h3 className=" text-[#231942] w-full text-center capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"Building Layout"}</h3>

                                    <CustomImgUpload
                                        borderStyle="dashed"
                                        isRemove={true}
                                        width='w-full mx-auto'
                                        justify='justify-evenly'
                                        removeFunction={handleImageRemove}
                                        handleChange={handleChangeImg}
                                        preview={image.preview || createNew.image || ""}
                                    />
                                </div>
                                <div className='w-full'>
                                    <div className='grid w-full gap-x-3'>
                                    <NuSelect
                                    label="Camp Id"
                                    options={camps}
                                    formik={formik}
                                    placeholder="Choose"
                                    name="campId"
                                    width="w-full "
                                    />
                                        <NuInputText 
                                           label='Building Name'
                                           placeholder='Building Name'
                                           formik={formik}
                                           name='buildName'
                                           width='w-full ' />
                                        <NuInputNumber 
                                        label='Building Number'
                                         placeholder='Building Number'
                                          formik={formik} 
                                          name='buildNo'
                                           width='w-full ' />
                                           <NuInputNumber 
                                        label='No.Floor'
                                         placeholder='Number of Floor'
                                          formik={formik} 
                                          name='numOfFloors'
                                           width='w-full ' />
                                    </div>
                                   
                                </div>
                            </div>
                    {/* </form>
                    <form onSubmit={handleAdd}> */}
                            <div className='flex  md:flex-nowrap px-4 py-2  h-full w-full md:w-[59%] bg-white rounded-lg'>
                           
                                <div className='w-full'>
                                <h3 className=" text-[#231942] w-full  mb-1 capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px]">{"Floor Layout"}</h3>

                               
                                    <div className='flex w-full gap-x-3'>
                                        
                                        <NuInputText 
                                           label='Floor Name'
                                           placeholder='Floor Name'
                                           formik={formik}
                                           name='floorName'
                                        //    value={newData.floorName}
                                        //    onChange={handleChange}
                                        //    disable={true}
                                           width='w-full md:w-1/2 md:-mt-2' />
                                        <NuInputNumber 
                                        label='Floor Number'
                                         placeholder='Floor Number'
                                          formik={formik} 
                                          name='floorNo'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                        //   value={newData.floorNo}
                                           width='w-full md:w-1/2 md:-mt-2' />
                                           
                                           {/* <div className='flex gap-3 items-center w-full'> */}
                                           <NuInputNumber 
                                        label='Total Rooms on Floor'
                                         placeholder='Total Rooms on Floor'
                                          formik={formik} 
                                          name='numOfRooms'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full md:w-1/2 md:-mt-2' />
                                           {/* <div className={`md:w-1/2 w-ful  my-3`}>
                                        <div className=' px-3'>
                                            <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{"Floor Name"}</label>
                                            <Input 
                                            name={"floorName"}
                                             className='mt-2' 
                                             placeholder={"Floor Name"}
                                              value={newData.floorName}
                                               type='text'  />

                                                </div>
                                        </div>
                                        <div className={`md:w-1/2 w-ful  my-3`}>
                                        <div className=' px-3'>
                                            <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{"Floor Number"}</label>
                                            <Input 
                                            name={"floorNo"} 
                                            className='mt-2' 
                                            placeholder={"Floor Number"} 
                                            value={newData.floorNo} 
                                            type='number'  />
                                                </div>
                                        </div> */}
                                        {/* <div className={`md:w-1/2 w-ful  my-3`}>
                                        <div className=' px-3'>
                                            <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{"Floor Number"}</label>
                                            <Input 
                                            name={"floorNo"}
                                             className='mt-2' 
                                             placeholder={"Floor Number"}
                                              value={newData.floorNo} 
                                              type='number'  />
                                                </div>
                                        </div> */}
                                             <button
                                                onClick={handleAdd}
                                                type="button"
                                                className="w-full md:w-[12%] h-8 text-center cursor-pointer bg-[#048178] border border-solid border-[#9B74F2] text-white font-semibold rounded-lg mt-5"
                                                >
                                                Add
                                                </button>
                                             {/* <CreateButton/> */}
                                           {/* </div> */}
                                          
                                    </div>
                                   
                                   <div className='w-full bg-white overflow-y-scroll mt-1'>
                                        <div className='w-full py-[1%] bg-[#048178] flex justify-between rounded-lg px-4'>
                                        <p className=" w-[40%] text-left text-white text-sm font-medium">
                                            Floor Name
                                        </p>
                                        <p className=" w-[20%] text-center text-white text-sm font-medium">
                                            Floor Number
                                        </p>
                                       
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Total Rooms on Floor
                                        </p>
                                        <p className=" w-[5%] text-center text-white text-sm font-medium">
                                        Action
                                        </p>
                                        </div>
                                   </div>
                                   {items?.length > 0 ? (
                <div className="h-[calc(100vh-450px)] overflow-y-scroll">
             
                                   {items.map((data,index)=>{
                                  return(
                                    <div key={index} className="w-full py-2 flex justify-between px-4">
                                    <p className=" w-[40%] text-left pl-3  text-black text-sm font-light1">
                                      {data?.floorName}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.floorNo}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.numOfRooms}
                                      </p>
                                      {/* <p className='w-[5%] cursor-pointer'> */}
                                      <button
                                            onClick={() => handleDelete(index)}
                                            type="button"
                                            className="cursor-pointer w-[5%] h-8  rounded border-none ml-16"
                                        >
                                            <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 25"
                                            fill="none"
                                            >
                                            <path
                                                d="M10 15.0227L10 12.0227"
                                                stroke="red"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M14 15.0227L14 12.0227"
                                                stroke="red"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M3 7.02271H21V7.02271C20.0681 7.02271 19.6022 7.02271 19.2346 7.17495C18.7446 7.37793 18.3552 7.76728 18.1522 8.25734C18 8.62488 18 9.09082 18 10.0227V16.0227C18 17.9083 18 18.8511 17.4142 19.4369C16.8284 20.0227 15.8856 20.0227 14 20.0227H10C8.11438 20.0227 7.17157 20.0227 6.58579 19.4369C6 18.8511 6 17.9083 6 16.0227V10.0227C6 9.09082 6 8.62488 5.84776 8.25734C5.64477 7.76728 5.25542 7.37793 4.76537 7.17495C4.39782 7.02271 3.93188 7.02271 3 7.02271V7.02271Z"
                                                stroke="red"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            <path
                                                d="M10.0681 3.3933C10.1821 3.28698 10.4332 3.19303 10.7825 3.12603C11.1318 3.05902 11.5597 3.02271 12 3.02271C12.4403 3.02271 12.8682 3.05902 13.2175 3.12603C13.5668 3.19303 13.8179 3.28698 13.9319 3.3933"
                                                stroke="red"
                                                stroke-width="2"
                                                stroke-linecap="round"
                                            />
                                            </svg>
                                        </button>
                                    {/* </p> */}
                                  
                                    </div>
                                  );
                                })}
                                </div> ) : (
                <div className="w-full text-center text-[#93949f]">
                 No Floor Details are here
                </div>
              )}
                                </div>
                                
                            </div>
                            </div>
                         
                            <div className="w-full md:block mx-auto  mt-2 ">
                                <div className="md:flex justify-end gap-4">
                                    <CancelButton handleClick={() => { Oncancel() }} />
                                    <SubmitButton isLoading={isLoading} />
                                </div>
                            </div> 
                        </form>
                    </div>
                </div>
            </div>

  )
 }

export default CreateBuildings