import React, { useState } from 'react'
import UseDropDown from '../../../../Components/Hooks/useDropDown'
import useAddFormData from '../../../../Components/Hooks/useAddFormData'
import { useFormik } from 'formik'
import * as yup from 'yup';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { Link, useParams } from 'react-router-dom';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputNumber, NuInputText, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import { notification } from 'antd';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx", "mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateDelivaryNotes() {
  const {officeId} = useParams();

  const { value: DeliveryVechileTypeValue, getData: DeliveryVechileType } = useFetchMataData('DeliveryVechileType')
  const { value: IdTypeValue, getData: IdType } = useFetchMataData('IdType')


  const { value: value1 } = useFetchMataData('DeliveryVechileType')
  const { value: value } = useFetchMataData('IdType')


  const { data: user } = UseDropDown('/user/drop-down')
  const { data: camps } = UseDropDown('/camp/drop-down')
  const { data: products } = UseDropDown('/products/drop-down')
  const { data: purchase } = UseDropDown('/PR/drop-down')
  const { data: vendors } = UseDropDown('/vendors/drop-down')
  const [images, setImages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null)
  const [api] = notification.useNotification();
  console.log("purchase", vendors);


  const [image, setImage] = useState({ preview: "", raw: "" });
  
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
    branchId: officeId,

    idNumber: "",
    countryCode: "",
    mobile: "",
    deliveryVehicleType: "",
    poRef: "",
    dnRef: "",
    daysElapsed: "",
    date: "",
    idType: "",
    deliveryPersonName: "",
    wayBillNo: "",
    receivedOn: "",
    comments: "",
    products: "",




  })
  console.log(createNew, "jjj");
  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item => {
      if (item.size < maxFileSize) {
        fileArr.push(item)
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item => {
      if (item.size < maxFileSize) {
        let fileName = item?.name
        console.log('fname', fileName);
        let fileExtention = fileName.split(".").reverse()[0]
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName)
        } else {
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
    })
    console.log(tempArr, 'temp');
    setImages(tempArr)
  }



  const [items, setItems] = useState([])
  // console.log("abcd",items);

  const [newData, setNewData] = useState({
    // quoteProductId:"",

    productId: "",
    orderedQty: "",
    packedQty: "",
    remainingQty: "",
   
    // Total Rooms on Floor:
  })
  console.log("ddd", items);

  function handleDelete(idx) {
    const list = items.filter((item, i) => i !== idx);
    setItems(list);


  }
  const handleAdd = () => {
    // if (items.length < formik.values.numberOfFloors) {
    const newItem = {

      // quoteProductId: formik.values.quoteProductId,
      productId: formik.values.productId,
      orderedQty: formik.values.orderedQty,
      packedQty: formik.values.packedQty,
      remainingQty: formik.values.remainingQty,
      // Total Rooms on Floor:newData.floorNo
    };
    console.log(newItem, 'jkh');
    setItems([...items, newItem]);
    // Update local storage
    setNewData({
      // quoteProductId:"",
      productId: "",
      orderedQty: "",
      packedQty: "",
      remainingQty: "",

    });

    // formik.values.quoteProductId=""
    formik.values.productId = ""
    formik.values.orderedQty = ""
    formik.values.packedQty = ""
    formik.values.remainingQty = ""


    // }
    // else{
    //     alert("You have reached the maximum limit of floors.");
    // }
  };
  // const handleChange = (e) => {
  //     setNewData({
  //         ...newData,
  //         [e.target.name]: e.target.value,
  //     });
  // };

  // const handleChangeImg = (e) => {
  //     if (e.target.files.length) {
  //         setImage({
  //             preview: URL.createObjectURL(e.target.files[0]),
  //             raw: e.target.files[0],
  //         });
  //     }
  // };
  const handleImageRemove = () => {
    setImage({
      preview: "",
      raw: "",
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      const valueWithItems = {
        ...value,
        products: items,
      };
      let formData = new FormData();
      formData.append('json_data', JSON.stringify(valueWithItems));
      if (selectedFile !== null) {
        selectedFile.forEach((file) => {
          formData.append("attachments", file);
        });
      } else {
        formData.append("attachments", [])
      }


      addFormData('/delivery-notes/new-delivery-note', formData, resetForm);
    }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
      <div className="flex justify-between px-4">
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Procurement</p>) },
          { title: (<p className=' text-[12px] '>Delivery Notes</p>) },
          { title: (<p className=' text-[12px] '>Create</p>) },
        ]} />
        <BackButton />
      </div>
      <div className="w-full h-full">
        <div className="w-full  overflow-y-scroll">
          {contextHolder}
          <div className="w-full ">

            <form onSubmit={formik.handleSubmit} className="w-full ">
              <div className="w-full flex gap-6 pt-6">
                {/* <div className='flex gap-8 md:flex-nowrap p-4   w-full md:w-1/2 bg-white rounded-lg'>
                            <div className='w-full md:w-1/2 rounded-md border border-solid border-[0.5px] border-slate-300/50 flex upload-bg py-3 flex-col'>

                                <h3 className=" text-[#231942] w-full text-center capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"Building Layout"}</h3>

                                    <CustomImgUpload
                                        borderStyle="dashed"
                                        isRemove={true}
                                        width='w-full mx-auto'
                                        justify='justify-evenly'
                                        removeFunction={handleImageRemove}
                                        handleChange={handleChangeImg}
                                        preview={image.preview || createNew.image || ""}
                                    />
                                </div>
                                <div className='w-full'>
                                    <div className='grid w-full gap-3'>
                                    <NuSelect
                                    label="Camp Id"
                                    options={camps}
                                    formik={formik}
                                    placeholder="Choose"
                                    name="campId"
                                    width="w-full md:w-1/2 md:-mt-2"
                                    />
                                        <NuInputText 
                                           label='Building Name'
                                           placeholder='Building Name'
                                           formik={formik}
                                           name='buildName'
                                           width='w-full md:w-1/2 md:-mt-2' />
                                        <NuInputNumber 
                                        label='Building Number'
                                         placeholder='Building Number'
                                          formik={formik} 
                                          name='buildNo'
                                           width='w-full md:w-1/2 md:-mt-2' />
                                           <NuInputNumber 
                                        label='Number of Floor'
                                         placeholder='Number of Floor'
                                          formik={formik} 
                                          name='numOfFloors'
                                           width='w-full md:w-1/2 md:-mt-2' />
                                    </div>
                                   
                                </div>
                            </div> */}
                {/* </form>
                    <form onSubmit={handleAdd}> */}
                <div className='w-full mx-auto  bg-white rounded-xl overflow-hidden mt-4'>

                  <div className='w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto'>
                    {/* <h3 className=" text-[#231942] w-full  capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"Floor Layout"}</h3> */}

                    <div className='w-full py-4 px-3 h-[calc(100vh-190px)] flex justify-start content-start flex-wrap  overflow-y-auto'>
                      <NuInputNumber
                        label='ID Number'
                        placeholder='ID Number'
                        formik={formik}
                        name='idNumber'

                        width=' w-full md:w-1/3' />
                      <NuInputNumber
                        label='Country Code'
                        placeholder='Country Code'
                        formik={formik}
                        name='countryCode'
                        width='w-full md:w-1/3' />
                      <NuInputNumber 
                        label='Mobile'
                         placeholder='Mobile'
                          formik={formik} 
                          name='mobile'
                         width='w-full md:w-1/3' />

                         <DropDownAddMetaData
                        label="Delivery Vehicle Type"
                        Options={DeliveryVechileTypeValue}
                        formik={formik}
                        placeholder="Delivery Vehicle Type"
                        name="deliveryVehicleType"
                        getFunc={() => DeliveryVechileType()}
                        displayProperty={"DeliveryVechileType"}
                        propertyName={"DeliveryVechileType"}
                        width="w-full md:w-1/3"
                      
                      />

                      <NuInputNumber
                        label='Po Ref'
                        placeholder='Po Ref'
                        formik={formik}
                        name='poRef'
                        width='w-full md:w-1/3' />

                        <NuInputNumber
                        label='Dn Ref'
                        placeholder='Do Ref'
                        formik={formik}
                        name='dnRef'
                        width='w-full md:w-1/3' />

                        <NuInputNumber
                        label='Days Elapsed'
                        placeholder='Days Elapsed'
                        formik={formik}
                        name='daysElapsed'
                        width='w-full md:w-1/3' />
                        
                      <NuDatePicker
                        label='Date'
                        placeholder='Date'
                        formik={formik}
                        name='date'
                        width='w-full md:w-1/3' />

                            <DropDownAddMetaData
                        label="Id Type"
                        Options={IdTypeValue}
                        formik={formik}
                        placeholder="Id Type"
                        name="idType"
                        width="w-full md:w-1/3"
                        getFunc={() => IdType()}
                        displayProperty={"IdType"}
                        propertyName={"IdType"}
                        idType
                      />

                        <NuInputText
                        label='Delivery Person Name'
                        placeholder='Delivery Person Name'
                        formik={formik}
                        name='deliveryPersonName'

                        width=' w-full md:w-1/3' />
                        <NuInputText
                        label='Way Bill No'
                        placeholder='Way Bill No'
                        formik={formik}
                        name='wayBillNo'
                        width=' w-full md:w-1/3' />

                        <NuDatePicker
                        label='Received On'
                        placeholder='Received On'
                        formik={formik}
                        name='receivedOn'
                        width='w-full md:w-1/3' />

                     
                      
                      <NuTextArea
                        label="Comments"
                        placeholder="Comments"
                        formik={formik}
                        name="comments"
                        width='w-full md:w-1/2'
                      />
                       <Attachement
                      handleChange={handleImageChange}
                      preview={images || ''}
                      width="w-full"
                      label="Attach"
                      multiple={true}
                    />
                      <div className='flex w-full gap-3 pt-10'>
                      <NuSelect
                          label='Product Id'
                          placeholder='Product Id'
                          options={products}
                          formik={formik}
                          name='productId'
                          width='w-full md:w-1/2 md:-mt-2' />

                        
                        <NuInputNumber
                          label='Ordered Qty'
                          placeholder='Ordered Qty'
                          formik={formik}
                          name='orderedQty'
                          width='w-full md:w-1/2 md:-mt-2' />

                        {/* <div className='flex gap-3 items-center w-full'> */}
                        
                        <NuInputNumber
                          label='Packed Qty'
                          placeholder='Packed Qty'
                          formik={formik}
                          name='packedQty'
                          width='w-full md:w-1/2 md:-mt-2'/>

                        <NuInputNumber
                          label='Remaining Qty'
                          placeholder='Remaining Qty'
                          formik={formik}
                          name='remainingQty'
                          //   onChange={handleChange}
                          //   disable={true}
                          width='w-full md:w-1/2 md:-mt-2' />
                        {/* <div className={`md:w-1/2 w-ful  my-3`}>
                                        <div className=' px-3'>
                                            <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{"Floor Name"}</label>
                                            <Input 
                                            name={"floorName"}
                                             className='mt-2' 
                                             placeholder={"Floor Name"}
                                              value={newData.floorName}
                                               type='text'  />

                                                </div>
                                        </div>
                                        <div className={`md:w-1/2 w-ful  my-3`}>
                                        <div className=' px-3'>
                                            <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{"Floor Number"}</label>
                                            <Input 
                                            name={"floorNo"} 
                                            className='mt-2' 
                                            placeholder={"Floor Number"} 
                                            value={newData.floorNo} 
                                            type='number'  />
                                                </div>
                                        </div> */}
                        {/* <div className={`md:w-1/2 w-ful  my-3`}>
                                        <div className=' px-3'>
                                            <label className=' mb-2 font-normal text-[13px] text-textColor-500'>{"Floor Number"}</label>
                                            <Input 
                                            name={"floorNo"}
                                             className='mt-2' 
                                             placeholder={"Floor Number"}
                                              value={newData.floorNo} 
                                              type='number'  />
                                                </div>
                                        </div> */}
                        <button
                          onClick={handleAdd}
                          type="button"
                          className="w-full md:w-[12%] h-8 text-center cursor-pointer bg-[#048178] border border-solid border-[#9B74F2] text-white font-semibold rounded-lg mt-5"
                        >
                          Add
                        </button>
                        {/* <CreateButton/> */}
                        {/* </div> */}

                      </div>
                      <div className='w-full h-auto bg-white overflow-y-scroll mt-5'>
                        <div className='w-full py-[1%] bg-[#048178] flex justify-between rounded-lg p-4'>
                          {/* <p className=" w-[20%] text-center text-white text-sm font-medium">
                                        Quote Product Id
                                        </p> */}
                          <p className=" w-[20%] text-center text-white text-sm font-medium">
                          Product Id
                          </p>

                          <p className=" w-[25%] text-center text-white text-sm font-medium">
                          Ordered Qty
                          </p>
                          <p className=" w-[25%] text-center text-white text-sm font-medium">
                          Packed Qty
                          </p>
                          <p className=" w-[25%] text-center text-white text-sm font-medium">
                          Remaining Qty
                          </p>
                        </div>
                      </div>
                      {items.map((data, index) => {
                        return (
                          <div key={index} className="w-full py-2 flex justify-between p-4">
                            {/* <p className=" w-[20%] text-center text-black text-sm font-light1">
                                      {data?.quoteProductId}
                                    </p> */}
                            <p className=" w-[20%] text-center text-black text-sm font-light">
                              {data?.productId}
                            </p>
                            <p className=" w-[25%] text-center text-black text-sm font-light">
                              {data?.orderedQty}
                            </p>
                            <p className=" w-[25%] text-center text-black text-sm font-light">
                              {data?.packedQty}
                            </p>
                            <p className=" w-[25%] text-center text-black text-sm font-light">
                              {data?.remainingQty}
                              <button
                                onClick={() => handleDelete(index)}
                                type="button"
                                className="cursor-pointer w-[15%] h-8 bg-[#048178] rounded border-none ml-3"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 24 25"
                                  fill="none"
                                >
                                  <path
                                    d="M10 15.0227L10 12.0227"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M14 15.0227L14 12.0227"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M3 7.02271H21V7.02271C20.0681 7.02271 19.6022 7.02271 19.2346 7.17495C18.7446 7.37793 18.3552 7.76728 18.1522 8.25734C18 8.62488 18 9.09082 18 10.0227V16.0227C18 17.9083 18 18.8511 17.4142 19.4369C16.8284 20.0227 15.8856 20.0227 14 20.0227H10C8.11438 20.0227 7.17157 20.0227 6.58579 19.4369C6 18.8511 6 17.9083 6 16.0227V10.0227C6 9.09082 6 8.62488 5.84776 8.25734C5.64477 7.76728 5.25542 7.37793 4.76537 7.17495C4.39782 7.02271 3.93188 7.02271 3 7.02271V7.02271Z"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                  <path
                                    d="M10.0681 3.3933C10.1821 3.28698 10.4332 3.19303 10.7825 3.12603C11.1318 3.05902 11.5597 3.02271 12 3.02271C12.4403 3.02271 12.8682 3.05902 13.2175 3.12603C13.5668 3.19303 13.8179 3.28698 13.9319 3.3933"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                  />
                                </svg>
                              </button>
                            </p>

                          </div>
                        );
                      })}

                      <div className="w-full md:block mx-auto my-4 mt-8 ">
                <div className="md:flex justify-end gap-4">
                  <CancelButton handleClick={() => { Oncancel() }} />
                  <SubmitButton isLoading={isLoading} />
                </div>
              </div>
                    </div>




                  </div>

                </div>
              </div>

              
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateDelivaryNotes