import React, { useState } from 'react';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { notification } from 'antd';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectCurrency, NuTextArea, NuTimePicker } from '../../../../Components/Utils/NuInput';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseAddData from '../../../../Components/Hooks/useAddData';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';

function CreateTakeAwaySchedule() {
  const { value: MealTypeValue, getData: MealType } = useFetchMataData('MealType')
  const { value: FoodCategoryValue, getData: FoodCategory } = useFetchMataData('FoodCategory')
  const { value: MealTypee} = useFetchMataData('MealType') 
  const { value: FoodCategoryy} = useFetchMataData('FoodCategory')
  const Navigate=useNavigate()
  const {officeId} = useParams()
    // const { data: employee } = UseDropDown('/employees/drop-down');
    // const {data:user} =UseDropDown('/user/drop-down')
    const {data:occupant} = UseDropDown('/occupants/drop-down')
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
    const [createNew, setCreateNew] = useState({
      ethnicity:"",
      mealType:"",
      foodCategory: "",
      deliverLocation: "",
      date:"",
      name:"",
      occupantId: "",
      branchId:officeId
    })
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
      }),
      onSubmit: async (value, { resetForm }) => {
          addFormData('/take-away-schedule/new', value, resetForm);
      }
  })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
            <div className="flex justify-between px-4">
                <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Catering</p>) },
                    { title: (<p className=' text-[12px] '>Take Away Schedule</p>) },
                    { title: (<p className=' text-[12px] '>Create</p>) },
                ]} />
                <BackButton />
            </div>
            <form onSubmit={formik.handleSubmit} className='pt-3'>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <NuInputText
              // isRequired={true}
              label="Name"
              placeholder="Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/3"
            />
            <NuSelect
            label = "Occupant"
            options= {occupant}
            placeholder="Occupant"
            formik={formik}
            name="occupantId"
            width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Ethnicity"
              placeholder="Ethnicity"
              formik={formik}
              name="ethnicity"
              width="w-full md:w-1/3"
            />
            <NuDatePicker
              label="Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="date"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Meal Type"
              Options={MealTypeValue}
              formik={formik}
              placeholder="Choose"
              name="mealType"
              getFunc={() => MealType()}
              displayProperty={"Meal Type"}
              propertyName={"MealType"}
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Food Category"
              Options={FoodCategoryValue}
              formik={formik}
              placeholder="Choose"
              name="foodCategory"
              getFunc={() => FoodCategory()}
              displayProperty={"Food Category"}
              propertyName={"FoodCategory"}
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
            <NuInputText
              // isRequired={true}
              label=" Delivery Location"
              placeholder=" Location"
              formik={formik}
              name="deliverLocation"
              width="w-full md:w-1/3"
            />
            
            
            

            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateTakeAwaySchedule