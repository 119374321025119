import React, { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker,  NuInputText, NuSelect, NuTextArea,NuInputNumber,NuDateTime,NuTimePicker } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Navigate,useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dropdown from 'antd/es/dropdown/dropdown';

function CreateTrips() {
  const {officeId} = useParams();
  const navigate=useNavigate()
  const {data:vehicle} = UseDropDown('/vehicle/drop-down')
  const {data:employees} = UseDropDown('/employees/drop-down')
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const {data:menus} = UseDropDown('/food-menu/drop-down')
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData ();
  const [createNew, setCreateNew] = useState({
      // reqType: "RoomService",
      driverName:"",
      branchId: officeId,
      driverId:"",
      comments:"",
      vehicleId:"",
      departingDateTime:"",
      destinationLocation:"",
      arrivalTime:"",
      seatCapacity:"",
      frequency:"",
      cancellation:"",
  })
 

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
         

          addFormData('/trips/new-trip', value, resetForm);
      }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Transportation</p>) },
        { title: (<p className=' text-[12px] '>Trips</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <NuSelect
              label="Vehicle Id"
              options={vehicle}
              formik={formik}
              placeholder="Choose"
              name="vehicleId"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputText
              label="Driver Name"
              formik={formik}
              placeholder="Driver Name"
              name="driverName"
              width="w-full md:w-1/2 lg:w-1/3"
          />
            <NuInputNumber
              // isRequired={true}
              label="Driver Id"
              placeholder="Driver Id"
              formik={formik}
              name="driverId"
              width="w-full md:w-1/3"
            />
            <NuDateTime
              // isRequired={true}
              label="Departing Date Time"
              placeholder="Departing Date Time"
              formik={formik}
              name="departingDateTime"
              width="w-full md:w-1/3"
            />
            <NuInputText
              label="Destination Location"
              formik={formik}
              placeholder="Destination Location"
              name="destinationLocation"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuTimePicker
              label="Arrival Time"
              formik={formik}
              placeholder="Arrival Time"
              name="arrivalTime"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputNumber
              label="Seating Cap"
              formik={formik}
              placeholder="Seating Cap"
              name="seatCapacity"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputText
              label="Frequency"
              formik={formik}
              placeholder="Frequency"
              name="frequency"
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputText
              label="Cancellation"
              formik={formik}
              placeholder="Cancellation"
              name="cancellation"
              width="w-full md:w-1/2 lg:w-1/3"
          />
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/2'
            />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateTrips