import React from 'react'
import UseGetData from '../../../../Components/Hooks/useFetchData';
import { Link, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CreateButton, FilterButton, SubmitButton, CancelButton,DeleteButton} from '../../../../Components/Utils/Buttons';
import DataTable from '../../../../Components/Layout/DataTable';
import { currencies } from '../../../../assets/CountryMini';
// import ViewBuildings from './ViewBuildings';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import {  NuSelect, NuDatePicker,  NuInputText } from '../../../../Components/Utils/NuInput';
import { useFormik } from 'formik';
import { useState,useEffect } from 'react';
import * as yup from 'yup'
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { Modal } from 'antd';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import ViewMoreDinningRecords from './ViewMoreDinningRecords';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function DinningRecordIndex() {
  const {data:occupant} =UseDropDown('/occupants/drop-down')

  const { value: DineType} = useFetchMataData('DineType') 
  const { value: Type} = useFetchMataData('Type') 
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState(20)
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const { data, totalCount, getData } = UseGetDataSearch( '/dining-records/',params);
  console.log(data,'dddd');
  // useEffect(()=>{
  //   setParams({...params,page:page,per_page:pageCount})
  //  },[page,pageCount])
// const { contextHolder, handle } = useDeleteData(getData);
// const handleDelete = async (removevalue,id) => handle(`/building/${id}`, removevalue, 'buildings');
// const { data: branches } = UseDropDown('/company_branch/drop-down');
  const [showFilterDialog, setShowFilterDialog] = useState(false);
const [filter, setFilter] = useState(false);
  const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
    const[filterDining,setFilterDining]=useState({
      type:"",
      occupantId:"",
      dineType:"",
    });
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterDining,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params, type: value?.type, occupantId: value?.occupantId, dineType: value?.dineType, })
        closeFilterDialog();
        setFilter(true)
        setFilterDining({
          type:"",
          occupantId:"",
          dineType:"",
        });
      }
    })
    const clearFilter = ()=> {
      setParams({...params,
        type:"",
        occupantId:"",
      dineType:"",
     
      })
      formik.values.type=""
      formik.values.occupantId=""
      formik.values.dineType=""
        setFilter(false)
    }

    let headers = [
        {
          label: <span>ID</span>,
          width: "w-[5%]",
        },
        // {
        //   label: <span>Occupant Id</span>,
        //   width: "w-[10%]",
        // },
        {
          label: <span>Occupant Name</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Ethnicity</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Dine Type</span>,
          width: "w-[10%]",
        },
        {
          label: <span> Type</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Consumption</span>,
          width: "w-[10%]",
        },
        // {
        //   label: <span>Floor Plan/Layout</span>,
        //   width: "w-[10%]",
        // },
        // {
        //   label: <span>Floor Area</span>,
        //   width: "w-[10%]",
        // },
        {
          label: <span>Actions</span>,
          width: "w-[5%]",
        },
      ];

      useEffect(()=>{
        setParams({...params,page:page,per_page:pageCount})
       },[page,pageCount])


  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <BreadCrumb items={[
        {title:(<Link to="/main/dashboard">
        <Dashboard color="gray" width={10} height={10} />{" "}
      </Link>)},
        {title:(<p className=' text-[12px] '>Catering</p>)},
        {title:(<p className=' text-[12px] '>Dining records</p>)}
        ]} />
      <div className=' flex gap-2'>
       <SearchBar
       handleChange={(e) =>
        setParams({ ...params, search: e.target.value })
      }
       />
       <FilterButton height='8' 
        handleClick={() => setShowFilterDialog(true)}/>
        {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
       <CreateButton label='Import' />
       <Link to={`/main/catering/dinning-record/${officeId}/create`}><CreateButton label='Dining records'/></Link> 
      </div>
      </div>


      <DataTable
       bodyHeight='max-h-[calc(100vh-260px)]'
       height='h-[calc(100vh-130px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={page}
       setPageIndex={setPage}
       dataCount={pageCount}
       setDataCount={setPageCount}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              currencies.find((i) => {
                if(i.value == item?.currency){
                  getCurrency=i.label;
                }
              })
              return (
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                  <td className="w-[5%] p-2 px-[1px] text-center ">{item?.drId||"#"}</td>
                  {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.occupantName||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.ethnicity||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.dineType||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.type||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.consumption||""}</td>
                  {/* <td className="w-[10%] p-2 px-[1px] text-center ">{item?.floors?.map((n)=>{
                    return(
                      <p>{n?.numOfRooms}</p>
                    )
                  })||""}</td> */}
                  {/* <td className="w-[10%] p-2 px-[1px] text-center">{item?.floorName||""}</td> */}
                  {/* <td className="w-[10%] p-2 px-[1px] text-center">{item?.floors?.map((n)=>{
                    return(
                      // <div className='w-full '>
                        <p>{n?.floorNo}</p>
                      // </div>
                    )
                  }) ||""}</td> */}
                  <td className="w-[5%] p-2 px-[1px] text-center ">
                      <ViewMoreDinningRecords data={item} fetcher={getData} id={item?.drId}/>
                  </td>
                </tr>
              );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
          }
      </DataTable>

      <Modal
        open={showFilterDialog}
        title={"Filter Building"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    <NuSelect
              label="Occupant Id"
              options={occupant}
              formik={formik}
              placeholder="Choose"
              name="occupantId"
              width="w-full"
            />
            <NuSelect
              label="Dine type"
              options={DineType}
              formik={formik}
              placeholder="Choose"
              name="dineType"
              width="w-full "
            />

            <NuSelect
              label="Type"
              options={Type}
              formik={formik}
              placeholder="Choose"
              name="type"
              width="w-full  "
            />
                    </div>
                    
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
           
    </div>
  )
}

export default DinningRecordIndex