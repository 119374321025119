// import React, { useEffect, useState } from "react";
// import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
// import { Link, useLocation, useParams } from "react-router-dom";
// import { BackButton, EditButton } from "../../../../Components/Utils/Buttons";
// import axiosInstance from "../../../../Services/axiosCommon";
// import Dashboard from "../../../../Components/Icons/Dasboard";

import { Link, useLocation, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Utils/Breadcrumbs";
import Dashboard from "../../../Components/Icons/Dasboard";
import { BackButton, EditButton } from "../../../Components/Utils/Buttons";
import { MainHeading } from "../../../Components/Utils/Heading";

function ViewVendor() {
  const {officeId} = useParams();
  const { EditVendorId } = useParams();
  const location = useLocation();
  const data = location.state.data;
  return  (
    <div className='px-4 py-2 bg-[#E6F2F2] h-full w-full'>
      <div className="flex justify-between px-4">
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Vendor</p>) },
          { title: (<p className=' text-[12px] '>View</p>) },
        ]} />
        <div className='flex justify-end gap-2'>
          <Link to={`/main/vendors/${officeId}`}><BackButton link={true} /></Link>
          <Link to={`/main/vendors/${officeId}/edit/${EditVendorId}`} state={{ from: `/main/vendors/${officeId}/view/${data?.vendorId}`, data: data }}><EditButton height='8' /></Link>
        </div>
      </div>
      
      <div className='px-2 py-2 h-[calc(100vh-130px)] flex flex-col gap-5 overflow-y-scroll'>
                <div className='flex flex-col gap-2 px-3 py-3 w-full h-[calc(100vh-100px)]  bg-[#FFF] rounded-[5px] overflow-scroll-y'>
                    <h3 className='text-[#212121] text-sm font-semibold leading-normal'>Vendor Details</h3>
                    <div className='flex gap-5 h-[calc(100vh-66vh)] w-full items-center'>
                        {data?.vendorImage.file && <>
                            <div className='w-1/5 h-full flex items-center p-2 bg-[#E6F2F2] rounded-[5px] overflow-hidden'>
                                <img className='w-full flex justify-center items-center' src={data?.vendorImage.file} alt='employee img' />
                            </div>
                            <div className='border-r border-[1px] border-slate-200/60 border-solid' /></>
                        }
                        <div className='w-full flex flex-col justify-between h-full'>
                            <div className='flex w-auto '>
                            <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Vendor Id</h4>
                                    <p className='text-[#4D4D4D]'>{data?.vendorId || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Contact Person</h4>
                                    <p className='text-[#4D4D4D]'>{data?.contactPerson || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Company Name</h4>
                                    <p className='text-[#4D4D4D]'>{data?.companyName || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Email</h4>
                                    <p className='text-[#4D4D4D]'>{data?.email|| '-'} </p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Phone</h4>
                                    <p className='text-[#4D4D4D]'>{data?.phoneWithCC|| '-'}</p>
                                </div>
                            </div>
                            <div className='flex w-auto py-3'>
                                
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Website</h4>
                                    <p className='text-[#4D4D4D]'>{data?.website|| '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Designation</h4>
                                    <p className='text-[#4D4D4D]'>{data?.designation || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                    <h4 className='text-[#212121] pb-3'>Building No</h4>
                                    <p className='text-[#4D4D4D]'>{data?.buildingNo || '-'}</p>
                                </div>
                                <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>Products </h4>
                                        <p className='text-[#4D4D4D]'>{data?.products  || '-'}</p>
                                    </div>
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>Services</h4>
                                        <p className='text-[#4D4D4D]'>{data?.services || '-'}</p>
                                    </div>
                            </div>
                            <div className='w-auto py-3'>
                                {/* <div>
                                    <h3 className='text-[#212121] font-semibold pb-3'>Address</h3>
                                </div> */}
                                <div className='flex w-auto'>
                                    
                                    
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>Contact Person Email</h4>
                                        <p className='text-[#4D4D4D]'>{data?.contactPersonEmail || '-'}</p>
                                    </div>
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>Contact Person Mobile</h4>
                                        <p className='text-[#4D4D4D]'>{data?.cPMobileWithCC || '-'}</p>
                                    </div>
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>Tax Id </h4>
                                        <p className='text-[#4D4D4D]'>{data?.taxId  || '-'}</p>
                                    </div>
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>Performance Rating</h4>
                                        <p className='text-[#4D4D4D]'>{data?.performanceRating || '-'}</p>
                                    </div>
                                    <div className='w-1/4'>
                                        <h4 className='text-[#212121] pb-3'>Comments</h4>
                                        <p className='text-[#4D4D4D]'>{data?.comments || '-'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='flex flex-col gap-2 px-3 py-3 w-full h-[calc(100vh-300px)] bg-[#FFF] rounded-[5px] p-5'>
                    <h3 className='text-[#212121] pb-5 font-semibold leading-normal'>Address Details</h3>
                    <div className='w-full'>
                        <div className='flex w-full flex-wrap'>
                            <div className='w-[14.28%] py-2'>
                                <h4 className='text-[#212121] pb-3'>Country</h4>
                                <p className='text-[#4D4D4D]'>{data?.country || '-'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                                <h4 className='text-[#212121] pb-3'>State </h4>
                                <p className='text-[#4D4D4D]'>{data?.state || '-'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                                <h4 className='text-[#212121] pb-3'>District</h4>
                                <p className='text-[#4D4D4D]'>{data?.district|| '-'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                                <h4 className='text-[#212121] pb-3'>City</h4>
                                <p className='text-[#4D4D4D]'>{data?.city || '-'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                                <h4 className='text-[#212121] pb-3'>Street Name</h4>
                                <p className='text-[#4D4D4D]'>{data?.streetName || '-'}</p>
                            </div>
                            <div className='w-[14.28%] py-2'>
                            <h4 className='text-[#212121] pb-3'>postalCode</h4>
                                <p className='text-[#4D4D4D]'>{data?.postalCode || '-'}</p>
                            </div>
                            

                           
                        </div>
                    </div>
                </div>
            </div>
      </div>

    // </div>
  )
}

export default ViewVendor;