import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { CancelButton, CreateButton, DeleteButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import SearchBar from '../../../../Components/Utils/SearchBar';
import RightArrow from '../../../../Components/Icons/RightArrow';
import image from '../../../../assets/images/loginPage.jpg';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { NuInputNumber,NuInputText, NuSelect } from '../../../../Components/Utils/NuInput';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { Modal } from 'antd';
import ViewMoreFoodMenu from './ViewMoreFoodMenu';
import DataTable from '../../../../Components/Layout/DataTable';
import Card from '../../../../Components/Icons/card';
import List from '../../../../Components/Icons/list';
// import FoodMenuCard from './FoodMenuCard';
import Pagination from '../../../../Components/Utils/Pagenation';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function FoodMenuIndex() {
  const { value: Category} = useFetchMataData('Category') 
  const { value: Type} = useFetchMataData('Type') 
  const { value: SubCategory} = useFetchMataData('SubCategory') 
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    // const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode") || "card");
    const [params, setParams] = useState({
        page: page,
        per_page: pageCount,
        search: "",
      });
    const { data, totalCount, getData} = UseGetDataSearch('/food-menu/' , params);
    const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const[filterCamp,setFilterCamp]=useState({
        mealType: "",
        foodName: "",
        category: "",
        type:""
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterCamp,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, mealType: value?.mealType, foodName: value?.foodName,category: value?.category,type: value?.type})
          closeFilterDialog();
          setFilter(true)
          setFilterCamp({
            mealType: "",
            foodName: "",
            category: "",
            type:""
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          mealType: "",
          foodName: "",
          category: "",
          type:""
        })
        formik.values.mealType=""
        formik.values.foodName=""
        formik.values.category=""
        formik.values.type=""
          setFilter(false)
      }
      useEffect(()=>{
        // localStorage.setItem("viewMode", viewMode);
         setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
    console.log(data,'dddd');
  
      let headers = [
          {
            label: <span>Menu Id</span>,
            width: "w-[5%]",
          },
          {
            label: <span>Food Name</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Category</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Sub Category</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Type</span>,
            width: "w-[10%]",
          },
          // {
          //   label: <span>Designation</span>,
          //   width: "w-[10%]",
          // },
          // {
          //   label: <span>State</span>,
          //   width: "w-[10%]",
          // },
          {
            label: <span>Actions</span>,
            width: "w-[5%]",
          },
        ];
    
  return (
    <div className=' bg-[#E6F2F2] h-full w-full py-3 px-6'>
            <div className='w-full items-center flex justify-between'>
                <div>
                    <BreadCrumb items={[
                        {
                            title: (<Link to="/main/dashboard">
                              <Dashboard color="gray" width={10} height={10} />{" "}
                            </Link>)
                        },
                        { title: <p className='text-[13px]'>Catering</p> },
                        { title: <p className='text-[13px]'>Food Menu</p> },
                    ]}
                    />
                </div>
                {/* <div><h2 className='font-[Poppins] text-xl'>Camp Info</h2></div> */}
                <div className='flex gap-3'>
                    <div>
                      <SearchBar 
                    handleChange={(e) =>
                        setParams({ ...params, search: e.target.value })
                      }
                    /></div>
                    <div className="flex gap-2">
              {/* <div
                onClick={() => setViewMode("list")}
                className={
                  viewMode === "list"
                    ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white"
                    : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"
                }
              >
                <List />
              </div> */}
              {/* <div
                onClick={() => setViewMode("card")}
                className={
                  viewMode === "card"
                    ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white"
                    : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"
                }
              >
                <Card />
              </div> */}
            </div>
                    <FilterButton height='8' 
                     handleClick={() => setShowFilterDialog(true)}/>
                     {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
       <CreateButton label='Import' />

                    <Link to={`/main/catering/food-menu/${officeId}/create`}><div>
                        <CreateButton label='Food Menu' />
                    </div></Link>
                </div>
            </div>
            
            <DataTable
       bodyHeight='max-h-[calc(100vh-260px)]'
       height='h-[calc(100vh-130px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={page}
       setPageIndex={setPage}
       dataCount={pageCount}
       setDataCount={setPageCount}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              // currencies.find((i) => {
              //   if(i.value == item?.currency){
              //     getCurrency=i.label;
              //   }
              // })
              return (
             
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105 " key={i}>
                  <td className="w-[5%] p-2 px-[1px] text-center ">{item?.menuId||"#"}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/catering/food-menu/view/${item?.menuId}`} state={{data: item}}>{item?.foodName||""}</Link></td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.category||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.subCategory||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.type||""}</td>
                  {/* <td className="w-[10%] p-2 px-[1px] text-center ">{item?.occupantDetails?.email ||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.occupantDetails?.mobile?.display||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.occupantDetails?.designation||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center">{item?.occupantDetails?.state ||""}</td> */}
                  <td className="w-[5%] p-2 px-[1px] text-center ">
                      <ViewMoreFoodMenu data={item} fetcher={getData} id={item?.menuId}/>
                  </td>
                </tr>
              );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
            
          }
         
      </DataTable>
      <Modal
        open={showFilterDialog}
        title={"Filter Room"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
               
                    {/* <NuInputNumber label='Meal Type' placeholder='Meal Type' formik={formik} name='mealType' width='w-full' />
                    <NuInputText
              // isRequired={true}
              label="Food Name"
              placeholder="Food Name"
              formik={formik}
              name="noOfBeds"
              width="w-full"
            /> */}
            <NuSelect
              // isRequired={true}
              label="Category"
              options={Category}
              formik={formik}
              placeholder="Choose"
              name="category"
              width="w-full"
            />
                    <NuSelect
                                    // isRequired={true}
                                    label='Type'
                                    options={Type}
                                    formik={formik}
                                    placeholder='Type'
                                    name='type'
                                    width='w-full'
                                />
                    </div>
                    
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
            
           </Modal>
    </div>
            
            
)
}


export default FoodMenuIndex;