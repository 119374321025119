import React, { useState } from 'react'
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';

import { Link, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import * as yup from 'yup';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EditContactList() {
  const { value: value } = useFetchMataData('Category_ContactList')
  const { value: value1 } = useFetchMataData('Type_ContactList')
  const { value: value2 } = useFetchMataData('Status_ContactList')
    const [image, setImage] = useState({ preview: "", raw: "" });
    const location = useLocation();
    const data = location.state.data;
    const from = location.state.from;
    console.log(from,'from');
    const { patientsEditId } = useParams();
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData } = useEditFormData();
    const [createNew, setCreateNew] = useState({
      
      type: data?.type,
      name: data?.name,
      category: data?.category,
      email: data?.email,
      mobile: data?.mobile,
      address: data?.address,
      status: data?.status,
      comments: data?.comments,
      branchId: data?.branchId,

        
      
    })
    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };
    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
        setCreateNew(prevState => ({ ...createNew, image: "" }))
        formik.values.image = ""
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            // let formData = new FormData();
            // formData.append('patientData', JSON.stringify(value));
            // formData.append('insuranceImage', image.raw);

            editFormData(`/contact-list/${data.contactListId}`, value, resetForm, from);
        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: <p className='text-[13px]'>Clinic</p> },
        { title: <p className='text-[13px]'>Contact-List</p> },
        { title: <p className='text-[13px]'>Edit</p> }

    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          <NuSelect
              label="Type "
              options={value1}
              formik={formik}
              placeholder="Choose"
              name="type"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Name"
              placeholder="Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/3"
            />
           
           <NuSelect
              label="Category"
              options={value}
              formik={formik}
              placeholder="Choose"
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            
             <NuInputEmail
              label="Email"
              placeholder="Email"
              formik={formik}
              name="email"
              width="w-full md:w-1/3"
            />
             <NuInputNumber
              label="Mobile"
              placeholder="Mobile"
              formik={formik}
              name="mobile"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Status"
              options={value2}
              formik={formik}
              placeholder="Choose"
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />
             <NuTextArea
              label="Address"
              placeholder="Address"
              formik={formik}
              name="address"
              width='w-full md:w-1/3'
            />
            
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/2'
            />
             {/* attachments */}
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
 </div>
  )
}

export default EditContactList