import { useFormik } from 'formik';
import { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate, useLocation, useParams,useNavigate } from 'react-router-dom';
import { NuDatePicker,  NuSelect, NuTextArea, NuInputText,NuInputNumber } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
function EditParkingLots() {
  const navigate=useNavigate()
  const { value: Status} = useFetchMataData('Status')

  const {data:vehicle} = UseDropDown('/vehicle/drop-down')
  const {data:employees} = UseDropDown('/employees/drop-down')
  const {data:occupant} =UseDropDown('/occupants/drop-down')
  
  const { parkingLotsEditId } = useParams();
  const location=useLocation()
  const data =location.state.data
  const from=location.state.from
  // console.log(location.state,'from');
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
  const [createNew, setCreateNew] = useState({
    branchId: data?. branchId,
    allocatedTo: data?.allocatedTo,
    location: data?.location,
    status: data?.status,
    comments: data?.comments,
    vehicleId:data?.vehicleId
     
  })
  console.log(data);

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
    
          editFormData(`/parking-lots/edit/${parkingLotsEditId}`, value, resetForm, from);

      }
  })
return (
  <div className="w-full h-full p-2 bg-[#F1F2FB]">
  <div className="flex justify-between px-4">
      <BreadCrumb items={[
      {
          title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
          </Link>)
      },
      { title: (<p className=' text-[12px] '>Transportation</p>) },
      { title: (<p className=' text-[12px] '>Parking Lots</p>) },
      { title: (<p className=' text-[12px] '>Edit</p>) },
  ]} />
  <BackButton/>
  </div>

  <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
        <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
        <NuSelect
              label="Vehicle Id"
              options={vehicle}
              formik={formik}
              placeholder="Choose"
              name="vehicleId"
              width="w-full md:w-1/2 lg:w-1/3"
          />
        <NuSelect
              label="Allocated To"
              options={employees}
              formik={formik}
              placeholder="Choose"
              name="allocatedTo"
              width="w-full md:w-1/2 lg:w-1/3"
          />
            <NuInputText
              // isRequired={true}
              label="Location"
              placeholder="Location"
              formik={formik}
              name="location"
              width="w-full md:w-1/3"
            />
            <NuSelect
              label="Status"
              options={Status}
              formik={formik}
              placeholder="Choose"
              name="status"
              width="w-full md:w-1/2 lg:w-1/3"
            />  
            <NuTextArea
              label="Comments"
              placeholder="comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/2'
            />
                 
            
           
          <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton handleClick={()=>{navigate(-1)}} />
              <SubmitButton />
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
)
}

export default EditParkingLots