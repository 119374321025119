import React, { useState,useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CreateButton, FilterButton, SubmitButton, CancelButton,DeleteButton} from '../../../../Components/Utils/Buttons';
import DataTable from '../../../../Components/Layout/DataTable';
import { currencies } from '../../../../assets/CountryMini';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { NuInputNumber, NuSelect, NuInputText } from '../../../../Components/Utils/NuInput';
import { useFormik } from 'formik';
import * as yup from 'yup'
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { Modal } from 'antd';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import ViewMoreSchedule from './ViewMoreSchedule';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function FoodScheduleIndex() {
  const { value: MealType} = useFetchMataData('MealType') 
  const {data:menus} = UseDropDown('/food-menu/drop-down')
  const {data:user} = UseDropDown('/user/drop-down')



  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, totalCount, getData } = UseGetDataSearch( '/food-schedule/',params);
    console.log(data,'dddd');
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
    const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const[filterFoodSchedule,setfilterFoodSchedule]=useState({
        menuId:"",
        mealType:"",
        prparedBy:""
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterFoodSchedule,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, menuId: value?.menuId, mealType: value?.mealType,prparedBy:value?.prparedBy})
          closeFilterDialog();
          setFilter(true)
          setfilterFoodSchedule({
            menuId: "",
            mealType: "",
            prparedBy:""
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          menuId: "",
          mealType: "",
          prparedBy:""
       
        })
        formik.values.menuId=""
        formik.values.mealType=""
          setFilter(false)
      }
  
      let headers = [
          {
            label: <span>ID</span>,
            width: "w-[5%]",
          },
          // {
          //   label: <span>Meal Id</span>,
          //   width: "w-[5%]",
          // },
          {
            label: <span>Meal Type</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Menu By Days</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Menu On Special Days</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Ethnicity</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Prpared By</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Actions</span>,
            width: "w-[5%]",
          },
        ];

        useEffect(()=>{
          setParams({...params,page:page,per_page:pageCount})
         },[page,pageCount])
       
  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <BreadCrumb items={[
        {title:(<Link to="/main/dashboard">
        <Dashboard color="gray" width={10} height={10} />{" "}
      </Link>)},
        {title:(<p className=' text-[12px] '>Catering</p>)},
        {title:(<p className=' text-[12px] '>Food Schedule</p>)}
        ]} />
      <div className=' flex gap-2'>
       <SearchBar
       handleChange={(e) =>
        setParams({ ...params, search: e.target.value })
      }
       />
       <FilterButton height='8' 
        handleClick={() => setShowFilterDialog(true)}/>
        {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
       <CreateButton label='Import' />
       <Link to={`/main/catering/food-schedule/${officeId}/create`}><CreateButton label='Food Schedule'/></Link> 
      </div>
      </div>
      <DataTable
       bodyHeight='max-h-[calc(100vh-260px)]'
       height='h-[calc(100vh-130px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={page}
       setPageIndex={setPage}
       dataCount={pageCount}
       setDataCount={setPageCount}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              currencies.find((i) => {
                if(i.value == item?.currency){
                  getCurrency=i.label;
                }
              })
              return (
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                  <td className="w-[5%] p-2 px-[1px] text-center ">{item?.id||"#"}</td>
                  {/* <td className="w-[5%] p-2 px-[1px] text-center ">{item?.menuId||"#"}</td> */}
                  {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.mealType||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.menuByDays||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.menuOnSpecialDays||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.ethnicity||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.prparedByName||""}</td>
                  
                  <td className="w-[5%] p-2 px-[1px] text-center ">
                      <ViewMoreSchedule data={item} fetcher={getData} id={item?.id}/>
                  </td>
                </tr>
              );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
          }
      </DataTable>

      <Modal
        open={showFilterDialog}
        title={"Vechile"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
                    
                    <NuSelect
              label="Menu Id"
              options={menus}
              formik={formik}
              placeholder="Choose"
              name="menuId"
              width="w-full"
            />
           <NuSelect
              label="Prpared By"
              options={user}
              formik={formik}
              placeholder="Choose"
              name="prparedBy"
              width="w-full"
            />
                    <NuSelect
              label="Meal Type"
              options={MealType}
              formik={formik}
              placeholder="Choose"
              name="mealType"
              width="w-full"
            />
                    </div>
                    
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>         
    </div>
  )
}

export default FoodScheduleIndex