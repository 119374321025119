import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import GuestCard from './guestCard';
import Modal from 'antd/es/modal/Modal';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import * as yep from 'yup'
import { useFormik } from 'formik';
import { NuInputText, NuSelect, NuSelectwithSearch } from '../../../../Components/Utils/NuInput';
import { formattedBloodGroups } from '../../../../assets/CountryMini';
import Pagination from '../../../../Components/Utils/Pagenation';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function GuestIndex() {
  const { value: Designation } = useFetchMataData('Guest_Designation') 
  const { value: IdType } = useFetchMataData('ID_Type_Guest')
  const { value: PurposeofStay} = useFetchMataData('PurposeofStay') 
  const { value: Status } = useFetchMataData('Status')


  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState('');
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(20)
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const { data, totalCount, getData } = UseGetDataSearch('/guest/', params);
  // console.log('ddd',data);

  useEffect(() => {
    setParams({ ...params, page: page, per_page: pageCount })
  }, [page, pageCount])
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false); //clear filter
  const closeFilterDialog = () => {
    setShowFilterDialog(false); //close filter
  };
  const [filterGuest, setFilterGuest] = useState({
    idType: "",
    designation: "",
    purpose_of_stay: "",
    status:""
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterGuest,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yep.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("f", value);
      setParams({ ...params, purpose_of_stay: value?.purpose_of_stay, designation: value?.designation, idType: value?.idType,status: value?.status })
      closeFilterDialog();
      setFilter(true)
      setFilterGuest({
        idType: "",
        designation: "",
        purpose_of_stay: "",
        status:""
      });
    }
  })

  const clearFilter = () => {
    setParams({
      ...params,
      idType: "",
      designation: "",
      purpose_of_stay: "",
      status:""

    })
    formik.values.idType = ""
    formik.values.designation = ""
    formik.values.purpose_of_stay = ""
    formik.values.status = ""

    setFilter(false)
  }


  // const userAccess = true;JSON.parse(localStorage.getItem("userAccess"));
  let AllUserCreateAccess = true;//userAccess
  // ? userAccess.some((permission) => permission.permissionName == "Settings_Alluser_create")
  // : false;
  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full h-[46px] flex flex-wrap lg:flex-nowrap justify-between items-center'>
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Listing</p>) },
          { title: (<p className=' text-[12px] '>Guests</p>) }
        ]} />
        <div className='lg:flex gap-2 hidden '>
          <div>
            <SearchBar
              handleChange={(e) =>
                setParams({ ...params, search: e.target.value })
              }
            /></div>
          <div className='flex w-1/2 justify-around gap-2'>

            <FilterButton height='8' handleClick={() => setShowFilterDialog(true)} />
            {filter && (
              <FilterButton
                handleClick={() => clearFilter()}
                label="Clear" />)}

            <CreateButton label='Import' />
            {AllUserCreateAccess && <Link to={`/main/listing/guests/${officeId}/create`}><CreateButton label='Guest' /></Link>}
          </div>
        </div>
      </div>

      {/* <NormalHeading title={'Users'}/> */}
      <div className='h-5/6 w-full'>
        <div className='w-full flex  justify-start flex-wrap md:gap-x-5 lg:gap-x-8 gap-y-3 pt-4'>
          {data &&
            data.map((item, idx) => {
              return (<GuestCard fetcher={getData} item={item} key={idx} />);
            })}
        </div>
      </div>
      <Modal
        open={showFilterDialog}
        title={"Filter Guest"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}>
        <form onSubmit={formik.handleSubmit}>
          <div className="">

          <NuSelect
                                    label='Desigination'
                                    placeholder='choose'
                                    formik={formik}
                                    options={Designation}
                                    name='designation'
                                    width='w-full' />
                            <NuSelect
                                label='Purpose of Stay'
                                options={PurposeofStay}
                                formik={formik}
                                placeholder='Choose'
                                name='purpose_of_stay'
                                width='w-full'
                            />
                            <NuSelect
                                label={'Id Type'}
                                options={IdType}
                                placeholder={'Choose'}
                                name={'idType'}
                                formik={formik}
                                width={'w-full'}
                            />
                            <NuSelect
                                label={'Status'}
                                options={Status }
                                placeholder={'Choose'}
                                name={'status'}
                                formik={formik}
                                width={'w-full'}
                            />
            
          </div>

          <div className="flex justify-end gap-2">
            <CancelButton handleClick={closeFilterDialog} />
            <SubmitButton name='Apply' />
          </div>
        </form>
      </Modal>
      <div className='w-full h-20 '>
        <Pagination
          pageIndex={page}
          setPageIndex={setPage}
          dataCount={pageCount}
          setDataCount={setPageCount}
          total={totalCount}
        />
      </div>
    </div>
  )
}

export default GuestIndex