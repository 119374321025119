import React from 'react'
// import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { useState } from 'react'
// import { NuInputText } from '../../Components/Utils/NuInput'
import { Input, notification } from 'antd'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { BackButton, CancelButton, SubmitButton } from '../../../Components/Utils/Buttons'
import UseGetDataSearch from '../../../Components/Hooks/useFetchDataBySearch'
import UseDropDown from '../../../Components/Hooks/useDropDown'
import useEditFormData from '../../../Components/Hooks/useEditFormData'
import NuDate from '../../../Components/Utils/NuDate'
import * as yup from 'yup';
import BreadCrumb from '../../../Components/Utils/Breadcrumbs'
import Dashboard from '../../../Components/Icons/Dasboard'
import { NuDatePicker, NuInputNumber, NuInputText, NuMultiSelect, NuSelect, NuTextArea, NuTimePicker } from '../../../Components/Utils/NuInput'
import Attachement from '../../../Components/Utils/CustomAttrachmentupload'
import useFetchMataData from '../../../Components/Hooks/useFetchMataData'

const EditTask=()=>{
  const { value: value } = useFetchMataData('Priority')

  const { value: value1 } = useFetchMataData('TaskStatus')
    const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx", "mp4", "mov", "wmv", "avi", "flv", "mkv"];
  const {data:meeting} =UseDropDown('/meeting/drop-down')
  const navigate = useNavigate()
  const {data:user} =UseDropDown('/user/drop-down')
  const [productList, setProductList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null)
  
  const { EditTaskId } = useParams();
  const location=useLocation()
  const data =location.state?.data
  
  console.log(data,"dtttt");
  
  const [url,setUrl] = useState(false);

 
  const [showFilterDialog, setShowFilterDialog] = useState(false);

  const from=location.state.from
  const [images, setImages] = useState([]);
  // console.log(location.state,'from');
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData,  isLoading } = useEditFormData();
  const [api,contextHolder] = notification.useNotification();
  const [createNew, setCreateNew] = useState({
    // date: (data?.date && NuDate({value:data?.date,format:"YYYY-MM-DD"}) )||"",
    branchId: data?. branchId,

    taskName: data?.taskName,
    priority: data?.priority,
    taskStatus: data?.taskStatus,
    startDate: data?.startDate,
    endDate: data?.endDate,
    completeDate: data?.completeDate,
    noOfDays: data?.noOfDays,
    noOfWeeks: data?.completeDate,
    taskTeam: data?.taskTeam,
    reminderDate: data?.reminderDate,
    reminderTime: data?.reminderTime,
    assignedTo: data?.assignedTo,
    copyTo: data?.copyTo,
    taskDesc: data?.taskDesc,
    attachment: data?.attachment,
    // startDate: (data?.startDate && NuDate({value:data?.startDate,format:"YYYY-MM-DD"}) )||"",
    // comments: data?.comments,
    // endDate: (data?.endDate && NuDate({value:data?.endDate,format:"YYYY-MM-DD"}) )||"",
     
  })
  console.log(data);
  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item => {
      if (item.size < maxFileSize) {
        fileArr.push(item)
      } else {
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item => {
      if (item.size < maxFileSize) {
        let fileName = item?.name
        console.log('fname', fileName);
        let fileExtention = fileName.split(".").reverse()[0]
        if (filetype?.includes(fileExtention)) {
          tempArr.push(fileName)
        } else {
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
    })
    console.log(tempArr, 'temp');
    setImages(tempArr)
  }

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        // const withAgendaValues={
        //   ...value,
        //   agendaItems: productList
        // }
        let formData = new FormData();
        formData.append('taskData', JSON.stringify(value));
        if (selectedFile !== null) {
          selectedFile.forEach((file) => {
            formData.append("attachment", file);
          });
        } else {
          formData.append("attachment", [])
        }
  
        editFormData(`/tasks/edit-task/${EditTaskId }`, formData, resetForm,from);
      }
  })
//   const handleAddProducts = () => {
//     const products = formik.values.agendaItems
    // console.log("man",products)
//     setProductList((prevList) => [...prevList, products]);
//     formik.setFieldValue('agendaItems', '');
//   };
//   const handleDeleteProduct = (index) => {
//     setProductList((prevList) => prevList.filter((_, i) => i !== index));
//   };
return (
  <div className="w-full h-full p-2 bg-[#F1F2FB]">
  <div className="flex justify-between px-4">
      <BreadCrumb items={[
      {
          title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
          </Link>)
      },
      { title: (<p className=' text-[12px] '>Task</p>) },
      //{ title: (<p className=' text-[12px] '>Meeting-point</p>) },
      { title: (<p className=' text-[12px] '>Edit</p>) },
  ]} />
  <BackButton/>
  </div>

  <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto h-[calc(100vh-140px)] bg-white rounded-xl overflow-hidden mt-5 ">
        <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto  ">
                     <NuInputText
                      label='Task Name'
                      placeholder='task name'
                      formik={formik}
                      name='taskName'
                      width='w-full md:w-1/3 md:-mt-2' 
                      />
                       <NuSelect
                      label="priority"
                      options={value}
                      formik={formik}
                      placeholder="Choose"
                      name="priority"
                      width="w-full md:w-1/3 md:-mt-2"
                    />
                    <NuInputNumber
                   label="TaskTeam"
                   placeholder="Task"
                   formik={formik}
                   name="taskTeam"
                   width="w-full md:w-1/3 md:-mt-2"
                  
                  />
                      <NuSelect
                      label="Task Status"
                      options={value1}
                      formik={formik}
                      placeholder="Choose"
                      name="taskStatus"
                      width="w-full md:w-1/3 md:-mt-2"
                    />
                  <NuDatePicker
                    label="Start Date"
                    placeholder="startdate"
                    formik={formik}
                    name="startDate"
                    width="w-full md:w-1/3 md:-mt-2"
                   />
                    <NuDatePicker
                    label="End Date"
                    placeholder="enddate"
                    formik={formik}
                    name="endDate"
                    width="w-full md:w-1/3 md:-mt-2"
                   />
                    <NuDatePicker
                    label="Complete Date"
                    placeholder="complete date"
                    formik={formik}
                    name="completeDate"
                    width="w-full md:w-1/3 md:-mt-2"
                   />
                  <NuInputNumber
                   label="No Of Days"
                   placeholder="no of days"
                   formik={formik}
                   name="noOfDays"
                   width="w-full md:w-1/3 md:-mt-2"
                  
                  />
                   <NuInputNumber
                   label="No Of Weeks"
                   placeholder="noOfWeeks"
                   formik={formik}
                   name="noOfWeeks"
                   width="w-full md:w-1/3 md:-mt-2"
                  
                  />
                    <NuDatePicker
                    label="Reminder Date"
                    placeholder="reminderDate"
                    formik={formik}
                    name="reminderDate"
                    width="w-full md:w-1/3 md:-mt-2"
                   />
                   <NuTimePicker
                    label="Reminder Time"
                    placeholder="reminderTime"
                    formik={formik}
                    name="reminderTime"
                    width="w-full md:w-1/3 md:-mt-2"

                   />
                   <NuMultiSelect
                   label="Assigned To"
                   options={user}
                   formik={formik}
                   placeholder="choose"
                   name="assignedTo"
                   width="w-full md:w-1/3 md:-mt-2"

                   />
                   
                       <NuMultiSelect
                      label="copyTo"
                      options={user}
                      formik={formik}
                      placeholder="Choose"
                      name="copyTo"
                      width="w-full md:w-1/3 md:-mt-2"
                    />
 
             <NuTextArea
              label="Task Description"
              placeholder="taskDesc"
              formik={formik}
              name="taskDesc"
              width='w-full md:w-1/3 md:-mt-2'
            />
        
            <Attachement
                      handleChange={handleImageChange}
                      preview={images || ''}
                      width="w-full"
                      label="Attach"
                      multiple={true}
                    />
           
           <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
                        {data?.attachment?.map((imgs, idx) => {
                            console.log('img', imgs);
                            return (
                              imgs?.file === null ? <p>Attachements not found</p> :
                                <div
                                    className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                    key={idx}
                                    style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                >
                                    <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer"
                                        // onClick={() => handleImageClick(imgs)}
                                        onClick={() => { setShowFilterDialog(true); setUrl(imgs?.file) }}
                                    >
                                        {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                        <img src={imgs} alt={imgs} />
                                    </div>
                                </div>
                            );
                        })}
                    </div>
          
            
        
           
          <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton handleClick={()=>{navigate(-1)}} />
              <SubmitButton />
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
)
}

export default EditTask