import { useEffect, useState } from 'react'
import axiosInstance from '../../Services/axiosCommon';

function useFetchMataData(url) {
    const [value,setValue] = useState([])
    console.log("value",value);
    
    function getData(){
        axiosInstance.get(`dropdown/dropdown-details/${url}`).then(res=>{
            // console.log("hlo",res?.data?.data?.url);
            // console.log("url",url);
            // let find = url
            let data = res.data?.data[url]
            let tempData = [];
            data.forEach((item) => {
                console.log("test",item);
                
              let tempObject = { value: item, label: item };
              tempData.push(tempObject);
            });
            res.data?.data[url] && setValue(tempData);
        }).catch(err=>{
            console.log("err");
        })
    }
useEffect(()=>{
    getData()
},[])

return {value,getData}||[]
}

export default useFetchMataData