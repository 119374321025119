import { useFormik } from 'formik';
import { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate,useNavigate, useLocation, useParams } from 'react-router-dom';
import { NuDatePicker,  NuSelect, NuTextArea, NuInputText,NuInputNumber } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';

function EditMaterials() {
  const {id} = useParams();

  const navigate=useNavigate()
  const {data:occupant} =UseDropDown('/occupants/drop-down')
  
  const { EditMaterialsId } = useParams();
  const location=useLocation()
  const data =location.state.data
  const from=location.state.from
  // console.log(location.state,'from');
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
  const [createNew, setCreateNew] = useState({
    branchId: data?. branchId,
    description: data?.description,
    manufacturer: data?.manufacturer,
    unit: data?.unit,
    model: data?.model,
    quantity: data?.quantity,
    serialNo: data?.serialNo,
    warrentyPeriod: data?.warrentyPeriod,
    warrentyExpDate: data?.warrentyExpDate,
    receivedQty: data?.receivedQty,
    issuedQty: data?.issuedQty,
    stockQty: data?.stockQty,
    condition: data?.condition,
    comments: data?.comments,
     
  })
  console.log(data);

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
    
          editFormData(`/material/edit/${EditMaterialsId}`, value, resetForm, from);

      }
  })
return (
  <div className="w-full h-full p-2 bg-[#F1F2FB]">
  <div className="flex justify-between px-4">
      <BreadCrumb items={[
      {
          title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
          </Link>)
      },
      { title: (<p className=' text-[12px] '>Invontary</p>) },
      { title: (<p className=' text-[12px] '>Materials</p>) },
      { title: (<p className=' text-[12px] '>Edit</p>) },
  ]} />
  <BackButton/>
  </div>

  <form onSubmit={formik.handleSubmit}>
      <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
        <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          
        <NuInputText
              // isRequired={true}
              label="Modal"
              placeholder=" Manufacturer"
              formik={formik}
              name="model"
              width="w-full md:w-1/3"
            />
             <NuInputText
              // isRequired={true}
              label="Manufacturer"
              placeholder=" Manufacturer"
              formik={formik}
              name="manufacturer"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label=" Unit"
              placeholder=" Unit"
              formik={formik}
              name="unit"
              width="w-full md:w-1/3"
            />
                 
            <NuInputNumber
              // isRequired={true}
              label=" Quantity"
              placeholder=" Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label=" Serial No"
              placeholder=" Serial No"
              formik={formik}
              name="serialNo"
              width="w-full md:w-1/3"
            />
            <NuDatePicker
              label="Warrenty Period"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyPeriod"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Warrenty Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyExpDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label=" Received Quantity"
              placeholder=" Received Quantity"
              formik={formik}
              name="receivedQty"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label=" Issued Quantity"
              placeholder=" issued Quantity"
              formik={formik}
              name="issuedQty"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label=" Stock Quantity"
              placeholder=" Stock Quantity"
              formik={formik}
              name="stockQty"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Condition"
              placeholder=" Condition"
              formik={formik}
              name="condition"
              width="w-full md:w-1/3"
            />
            <NuTextArea
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width='w-full md:w-1/3'
            />
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/2'
            />
           
          <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
            <div className="md:flex justify-end gap-4">
              <CancelButton handleClick={()=>{navigate(-1)}} />
              <SubmitButton />
            </div>
          </div>
        </div>
      </div>
    </form>
    </div>
)
}

export default EditMaterials