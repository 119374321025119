import React, { useState } from 'react'
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData'
import { Link, useNavigate, useParams } from 'react-router-dom'
import UseDropDown from '../../../../Components/Hooks/useDropDown'
import useAddFormData from '../../../../Components/Hooks/useAddFormData'
import { useFormik } from 'formik'
import * as yup from 'yup';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import Dashboard from '../../../../Components/Icons/Dasboard'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons'
import { NuDatePicker, NuInputText ,NuSelect, NuTimePicker,NuTextArea} from '../../../../Components/Utils/NuInput'
import { NuTime } from '../../../../Components/Utils/Input'
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData'

function CreateDailyInspectionByStaff() {
  const { value: DineTypeValue, getData: DineType } = useFetchMataData('DineType')
  const { value: TypeValue, getData: Type } = useFetchMataData('Type')


  const { value: DineTypee} = useFetchMataData('DineType') 
  const { value: Typee} = useFetchMataData('Type') 
  const {officeId} = useParams()
  const navigate=useNavigate()
  const {data:occupant} =UseDropDown('/occupants/drop-down')
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
  const [createNew, setCreateNew] = useState({
      // reqType: "RoomService",
     
      item:"",
      cat:"",
      subCat:"",
      inspected: "",
      inspectedBy: "",
      time:"",
      condition:"",
      comments: "",
      branchId:officeId,
  })
 

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
         

          addFormData('/daily-inspection/new', value, resetForm);
      }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Maintenance</p>) },
        { title: (<p className=' text-[12px] '>Daily-Inspection-By-Staff</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
            <NuInputText
              // isRequired={true}
              label=" Item"
              placeholder="Item"
              formik={formik}
              name="item"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Category"
              placeholder="Category"
              formik={formik}
              name="cat"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Sub-Category"
              placeholder="Sub-Category"
              formik={formik}
              name="subcat"
              width="w-full md:w-1/3"
            />
            {/* <NuInputText
              // isRequired={true}
              label="Inspected"
              placeholder="inspected"
              formik={formik}
              name="inspected"
              width="w-full md:w-1/3"
            /> */}
            <NuSelect
              label="Inspected"
              options={[
                { value: 1, label: "true" },
                { value: 0, label: "false" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="inspected"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Inspected By"
              placeholder="Inspected by"
              formik={formik}
              name="inspectedBy"
              width="w-full md:w-1/3"
            />
            <NuTimePicker
            label="Time"
            placeholder="Time"
            formik={formik}
            name="time"
            width="w-full md:w-1/3"
            />
               {/* <NuSelect
              label="Occupant Id"
              options={[
                
              ]}
              formik={formik}
              placeholder="Choose"
              name="occupantId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
             <NuInputText
               // isRequired={true}
               label="Condition"
               placeholder="Condition"
               formik={formik}
               name="condition"
               width="w-full md:w-1/3"
             />
             <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3 md:-mt-2'
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
                    </div>
  )
}

export default CreateDailyInspectionByStaff