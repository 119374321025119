import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectwithSearch, NuTextArea, YesOrNoButton } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dashboard from '../../../../Components/Icons/Dasboard';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function EditProducts() {
  const { value: Category } = useFetchMataData('Category_Products')
  const { value: SubCategory } = useFetchMataData('SubCategory_Products')
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const {data:suppliers} = UseDropDown('/vendors/drop-down')
  const {data:menus} = UseDropDown('/food-menu/drop-down')
  
  const [url,setUrl] = useState(false);

 
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };

  const [images, setImages] = useState([]);
  const [selectedFile,setSelectedFile] =useState(null)
  // const [division, setDivision] = useState([]);
const [ api, ] = notification.useNotification();
  // const [images, setImages] = useState({ preview: "", raw: "" });
  const location = useLocation();
  const data = location.state.data;
  console.log("nj",data);
  const from = location.state.from;
  console.log(location.state,'state');
  const {productsEditId} = useParams();
  // const [division, setDivision] = useState([]);
  const { Oncancel, editFormData,contextHolder,isLoading } = useEditFormData();
  const [createNew, setCreateNew] = useState({
    branchId: data?. branchId,
    itemCode:data?.itemCode,
      category: data?.category,
      subCategory:data?.subCategory,
      productName:data?.productName,
      brand:data?.brand,
      manufaturer:data?.manufaturer,
      unit:data?.unit,
      comments:data?.comments,
    
  })
  const handleChangeImg = (e) => {
      if (e.target.files.length) {
          setImages({
              preview: URL.createObjectURL(e.target.files[0]),
              raw: e.target.files[0],
          });
      }
  };
  const handleImageRemove = () => {
      setImages({
          preview: "",
          raw: "",
      });
      setCreateNew(prevState => ({ ...createNew, image: "" }))
      formik.values.image = ""
  }
  function handleImageChange(e) {
      

      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = []
      files.map(item=>{
        if (item.size < maxFileSize ){
          fileArr.push(item)
        }else{
          api.warning({
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            placement: "bottomRight",
          });
        }
      })    
      let tempArr = []
      setSelectedFile(fileArr);
      files.map(item=>{
        if (item.size < maxFileSize ){
        let fileName = item?.name
        console.log('fname',fileName);
        let fileExtention =  fileName.split(".").reverse()[0]
        if(filetype?.includes(fileExtention)){
          tempArr.push(fileName)
        }else{
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
      })
      console.log(tempArr,'temp');
      setImages(tempArr)
  }

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
          let formData = new FormData();
          formData.append('itemDetails', JSON.stringify(value));
          formData.append('itemImage', images.raw);

          editFormData(`/products/edit-item/${productsEditId}`, formData, resetForm, from);
      }
  })
return (
  <div className="w-full h-full p-2 bg-[#F1F2FB]">
  <div className="flex justify-between px-4">
      <BreadCrumb items={[
      {
          title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
          </Link>)
      },
      { title: <p className='text-[13px]'>Procurement</p> },
      { title: <p className='text-[13px]'>Products</p> },
      { title: <p className='text-[13px]'>Edit</p> }

  ]} />
  <BackButton/>
  </div>
  <div className="w-full h-full">
              <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
                  {contextHolder}
                  <div className="w-[96%] mx-auto  ">
                  <form onSubmit={formik.handleSubmit} className="w-full ">
                      <div className=' p-6  mt-4  bg-white rounded-lg'>
                      <div className='flex gap-8 md:flex-nowrap'>
                          
                          {/* <div className='flex flex-wrap md:flex-nowrap pb-4 bg-white'> */}
                          {/* <div className='w-full md:w-1/2 rounded-md border border-solid border-[0.5px] border-slate-300/50  upload-bg py-3 flex flex-col'>

                              <h3 className=" text-[#231942] w-full text-center capitalize font-bold text-[14px] lg:text-[15px] xl:text-[16px] py-3">{"Product Image"}</h3>
                              <div className='text-center flex'>
                                  <CustomImgUpload
                                      borderStyle="dashed"
                                      isRemove={true}
                                      width='w-full mx-auto'
                                      justify='justify-center'
                                      removeFunction={handleImageRemove}
                                      handleChange={handleChangeImg}
                                      preview={images.preview || data?.image.file || ""}
                                  />
                                  </div>
                              </div> */}
                              <div className='w-full'>
                                  <div className='flex flex-wrap w-full'>
                                  <NuInputText
              // isRequired={true}
              label="Product Name"
              placeholder="productName"
              formik={formik}
              name="productName"
              width="w-full md:w-1/3"
            />
          <NuInputText
              // isRequired={true}
              label="Item Code"
              placeholder="itemCode"
              formik={formik}
              name="itemCode"
              width="w-full md:w-1/3"
            />
             {/* <NuInputText
              isRequired={true}
              label="Is Asset"
              placeholder="isAsset"
              formik={formik}
              name="manufacturer"
              width="w-full md:w-1/3"
            /> */}
            
                 
            <NuSelect
              label="Category"
              options={Category }
              formik={formik}
              placeholder="Category"
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              label="Sub Category"
              options={SubCategory }
              formik={formik}
              placeholder="Sub Category"
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Unit"
              placeholder="Unit"
              formik={formik}
              name="unit"
              width="w-full md:w-1/3"
            />
            <NuInputText
              label="Brand"
              placeholder="Brand"
              formik={formik}
              name="brand"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Manufacturer"
              placeholder="Manufacturer"
              formik={formik}
              name="manufaturer"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />


                                  </div>
                                  
                              </div>
                              
                          </div>
                          <Attachement
                  handleChange={handleImageChange}
                  preview={images||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
              <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
  {data?.productImage?.map((imgs, idx) => {
                                      console.log('img', imgs);
                                      return (
                                          <div
                                              className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                              key={idx}
                                              style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                          >
                                              <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                              // onClick={() => handleImageClick(imgs)}
                                              onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                              >
                                                  {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                  <img src={imgs?.file} alt={imgs?.file} />
                                              </div>
                                          </div>
                                   );
                                   })} 
                                  </div>
              </div>
                          
                         
                          <div className="w-full md:block mx-auto my-4 mt-8 ">
                              <div className="md:flex justify-end gap-4">
                                  <CancelButton handleClick={() => { Oncancel() }} />
                                  <SubmitButton isLoading={isLoading} />
                              </div>
                          </div> 
                          
                      </form>
                      
                  </div>
              </div>
          </div>
</div>
)
}

export default EditProducts