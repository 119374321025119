import React, { useState,useEffect } from 'react'
import { Link,useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CreateButton, FilterButton, SubmitButton, CancelButton,DeleteButton} from '../../../../Components/Utils/Buttons';
import DataTable from '../../../../Components/Layout/DataTable';
import { currencies } from '../../../../assets/CountryMini';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { NuInputNumber, NuSelect, NuInputText } from '../../../../Components/Utils/NuInput';
import { useFormik } from 'formik';
import * as yup from 'yup'
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { Modal } from 'antd';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import ViewMoreEquipments from './ViewMoreEquipments';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EquipmentsIndex() {
  const { value: CalliberReq} = useFetchMataData('CalliberReq') 
  const { value: EquipmentType} = useFetchMataData('EquipmentType')
  const { value: Condition} = useFetchMataData('Condition')

  const { officeId } = useParams();
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, totalCount, getData } = UseGetDataSearch( '/equipment/',params);
    console.log(data,'dddd');
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
    const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const[filterEquipment,setfilterEquipment]=useState({
        calibReq:"",
        equipmentType:"",
        condition:"",
        
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterEquipment,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, calibReq: value?.calibReq, equipmentType: value?.equipmentType,condition:value?.condition,})
          closeFilterDialog();
          setFilter(true)
          setfilterEquipment({
            calibReq:"",
        equipmentType:"",
        condition:"",
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          calibReq:"",
        equipmentType:"",
        condition:"",
       
        })
        formik.values.calibReq=""
        formik.values.equipmentType=""
        formik.values.condition=""
        
          setFilter(false)
      }
  
      let headers = [
          {
            label: <span>ID</span>,
            width: "w-[5%]",
          },
          // {
          //   label: <span>Meal Id</span>,
          //   width: "w-[5%]",
          // },
          {
            label: <span>Serial No</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Quantity</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Brand</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Unit</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Equipment Type</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Manufacturer</span>,
            width: "w-[10%]",
          },

          {
            label: <span>Actions</span>,
            width: "w-[5%]",
          },
        ];

        useEffect(()=>{
          setParams({...params,page:page,per_page:pageCount})
         },[page,pageCount])
       
  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <BreadCrumb items={[
        {title:(<Link to="/main/dashboard">
        <Dashboard color="gray" width={10} height={10} />{" "}
      </Link>)},
        {title:(<p className=' text-[12px] '>Inventory</p>)},
        {title:(<p className=' text-[12px] '>Equipments</p>)}
        ]} />
      <div className=' flex gap-2'>
       <SearchBar
       handleChange={(e) =>
        setParams({ ...params, search: e.target.value })
      }
       />
       <FilterButton height='8' 
        handleClick={() => setShowFilterDialog(true)}/>
        {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
       <CreateButton label='Import' />
       <Link to={`/main/inventary/equipments/${officeId}/create`}><CreateButton label='Equipment'/></Link> 
      </div>
      </div>
      <DataTable
       bodyHeight='max-h-[calc(100vh-260px)]'
       height='h-[calc(100vh-130px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={page}
       setPageIndex={setPage}
       dataCount={pageCount}
       setDataCount={setPageCount}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              currencies.find((i) => {
                if(i.value == item?.currency){
                  getCurrency=i.label;
                }
              })
              return (
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                  <td className="w-[5%] p-2 px-[1px] text-center ">{item?.equipmentId||"#"}</td>
                  {/* <td className="w-[5%] p-2 px-[1px] text-center ">{item?.menuId||"#"}</td> */}
                  {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.serialNo||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.quantity||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.brand||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.unit||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.equipmentType||""}</td>
                  <td className="w-[10%] p-2 px-[1px] text-center ">{item?.manufacturer||""}</td>
                  
                  <td className="w-[5%] p-2 px-[1px] text-center ">
                      <ViewMoreEquipments data={item} fetcher={getData} id={item?.equipmentId} />
                  </td>
                </tr>
      );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
          }
      </DataTable>

      <Modal
        open={showFilterDialog}
        title={"Equipment"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">                
              <NuSelect
              label="Equipment Type"
              options={EquipmentType}
              formik={formik}
              placeholder="Choose"
              name="equipmentType"
              width="w-full"
          />
         <NuSelect
              // isRequired={true}
              label="Condition"
              options={Condition}
              placeholder=" Condition"
              formik={formik}
              name="condition"
              width="w-full"
            />
            <NuSelect
              label="Caliber Request"
              options={CalliberReq}
              formik={formik}
              placeholder="caliber Request"
              name="calibReq"
              width="w-full"
            />
            
                    </div>
                    
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>         
    </div>
  )
}


export default EquipmentsIndex