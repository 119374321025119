import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from 'yup';
import CustomImgUpload from "../../../../Components/Utils/CustomImgUpload";
import { NuInputText, NuSelect, NuInputNumber, NuLabel, NuInputEmail, NuMobileNumber, NuSelectwithSearch, NuDatePicker, NuTextArea } from "../../../../Components/Utils/NuInput";
import { CountryMIN, formattedBloodGroups } from "../../../../assets/CountryMini";
import { BackButton, CancelButton, SubmitButton } from "../../../../Components/Utils/Buttons";
import useAddFormData from "../../../../Components/Hooks/useAddFormData";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { Link, useParams } from "react-router-dom";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import DropDownAddMetaData from "../../../../Components/Utils/selectAddMetaData";


function CreateEmployee() {
  const { value:DesignationValue, getData:Designation } = useFetchMataData('Designation')
  const { value:DivisionValue, getData:Division } = useFetchMataData('Division')
  const { value:ProjectValue, getData:Project } = useFetchMataData('Assigned Project')
  const { value:ID_TypeValue, getData:ID_Type } = useFetchMataData('ID_Type')


    
    const {officeId} = useParams();
    // console.log("branch",officeId);
    const { value: Designationn } = useFetchMataData('Designation') 
    const { value: IdType } = useFetchMataData('Id_Type')
    const { value: Divisionn } = useFetchMataData('Division')
    const { value: Projectt } = useFetchMataData('Assigned Project')
    const [image, setImage] = useState({ preview: "", raw: "" });
    // const [division, setDivision] = useState([]);
    const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
    const [createNew, setCreateNew] = useState({
        city: "",
        streetName: "",
        district: "",
        branchId:officeId,
        state: "",
        country: "",
        postalCode: "",
        name: "",
        bloodGroup: "",
        designation: "",
        dob: "",
        gender: "",
        email: "",
        religion: "",
        countryCode: "",
        mobile: "",
        image: "",
        department: "",
        join_date: "",
        assignedProject: "",
        releiving_date: "",
        proof_id: "",
        idType: "",
        comments: "",
        cpName: "",
        cpEmail: "",
        cpCountryCode: "",
        cpPhone: "",
        cpDesignation: ""
    })
    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };
    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            let formData = new FormData();
            formData.append('employeeDetails', JSON.stringify(value));
            formData.append('employeeImage', image.raw);

            addFormData('/employees/new', formData, resetForm);
        }
    })
    return (
        <div className="w-full h-full p-2 bg-[#F1F2FB]">
            <div className="flex justify-between px-4">
                <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Listing</p>) },
                    { title: (<p className=' text-[12px] '>Employees</p>) },
                    { title: (<p className=' text-[12px] '>Add</p>) },
                ]} />
                <BackButton />
            </div>
            <div className="w-full h-full">
                <div className="w-full h-[calc(100vh-100px)] overflow-y-scroll">
                    {contextHolder}
                    <div className="w-[96%] mx-auto ">
                        <form onSubmit={formik.handleSubmit} className="w-full">
                            <CustomImgUpload
                                borderStyle="dashed"
                                isRemove={true}
                                width='w-full md:w-1/2'
                                removeFunction={handleImageRemove}
                                handleChange={handleChangeImg}
                                preview={image.preview || createNew.image || ""}
                            />
                            <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                <NuInputText label='Name' placeholder='Name' formik={formik} name='name' width='w-full md:w-1/3' />
                                <NuInputEmail label='Email' placeholder={'example@email.com'} formik={formik} name='email' isRequired={true} width={'w-full md:w-1/3'} />
                                <NuMobileNumber
                                    nunCode={"countryCode"}
                                    label="Mobile number"
                                    placeholder="Mobile number"
                                    formik={formik}
                                    name="mobile"
                                    width="w-full md:w-1/3"
                                />
                                <NuInputText label='Gender' placeholder='M/F/T' formik={formik} name='gender' width='w-full md:w-1/3' />
                                <NuInputText label='Religion' placeholder='Religion' formik={formik} name='religion' width='w-full md:w-1/3' />
                                <NuDatePicker
                                    label={'DOB'}
                                    formik={formik}
                                    width={'w-full md:w-1/3'}
                                    name={'dob'}
                                />
                                {/* <NuInputText label={'Blood Group'} placeholder={'AB-'} formik={formik} name={'bloodGroup'} width={'w-full md:w-1/3'} /> */}
                                <NuSelectwithSearch
                                    options={formattedBloodGroups}
                                    label={'Blood Group'}
                                    placeholder={'AB-'}
                                    formik={formik}
                                    name={'bloodGroup'}
                                    width={'w-full md:w-1/3'}
                                />
                                <DropDownAddMetaData
                                    label='Desigination'
                                    placeholder='Choose'
                                    formik={formik}
                                    Options={DesignationValue}
                                    getFunc={()=>Designation()}
                                    displayProperty={"Add Designation"}
                                    propertyName={"Designation"}
                                    name='designation'
                                    width='w-full md:w-1/3' />
                                <DropDownAddMetaData
                                    label='Division / Department'
                                    Options={DivisionValue}
                                    formik={formik}
                                    placeholder='Choose'
                                    name='department'
                                    getFunc={()=>Division()}
                                    displayProperty={"Add Division"}
                                    propertyName={"Division"}
                                    width='w-full md:w-1/3'
                                />
                                <NuDatePicker
                                    label={'Join Date'}
                                    formik={formik}
                                    name={'join_date'}
                                    width={'w-full md:w-1/3'}
                                />
                                <DropDownAddMetaData
                                    placeholder={'Choose'}
                                    label={'Assigned Project'}
                                    Options={ProjectValue}
                                    name={'assignedProject'}
                                    formik={formik}
                                    getFunc={()=>Project()}
                                    displayProperty={"Add Project"}
                                    propertyName={"Assigned Project"}
                                    width={'w-full md:w-1/3'}
                                />
                                <NuInputText
                                    label={'Id No'}
                                    placeholder={'Id'}
                                    name={'proof_id'}
                                    formik={formik}
                                    width={'w-full md:w-1/3'}
                                />
                                <DropDownAddMetaData
                                    label={'Id Type'}
                                    Options={ID_TypeValue}
                                    placeholder={'Choose'}
                                    name={'idType'}
                                    getFunc={()=>ID_Type()}
                                    displayProperty={"Add ID Type"}
                                    propertyName={"ID_Type"}
                                    formik={formik}
                                    width={'w-full md:w-1/3'}
                                />
                                <NuTextArea
                                    label={'Comments'}
                                    name={'comments'}
                                    placeholder={'Write your comments here...'}
                                    formik={formik}
                                    width={'w-full md:w-1/2'}
                                />
                            </div>
                            <p className="text-xs font-semibold text-[#000000] pt-3">Address</p>
                            <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                <NuSelectwithSearch
                                    label="Country"
                                    options={CountryMIN}
                                    formik={formik}
                                    placeholder="Choose"
                                    name="country"
                                    width="w-full md:w-1/3"
                                />
                                <NuInputText label='State' placeholder='State' formik={formik} name='state' width='w-full md:w-1/3' />
                                <NuInputText label='District' placeholder='District' formik={formik} name='district' width='w-1/3' />
                                <NuInputText label='City' placeholder='City' formik={formik} name='city' width='w-full md:w-1/3' />
                                <NuInputText label='Street Name' placeholder='StreetName' formik={formik} name='streetName' width='w-full md:w-1/3' />
                                <NuInputNumber label='Postal Code' placeholder='PostalCode' formik={formik} name='postalCode' width='w-full md:w-1/3' />
                            </div>
                            <p className="text-xs font-semibold text-[#000000] pt-3">Contact Person</p>
                            <div className="w-full flex justify-start gap-y-2 content-start flex-wrap">
                                <NuInputText label='Name' placeholder='Name' formik={formik} name='cpName' width='w-full md:w-1/3' />
                                <NuInputText label='Email' placeholder='example@email.com' formik={formik} name='cpEmail' width='w-full md:w-1/3' />
                                <NuMobileNumber nunCode='cpCountryCode' name='cpPhone' label='Mobile' placeholder='Mobile number' formik={formik} width='w-full md:w-1/3' />
                                <NuInputText label='Designation' placeholder='Designation' formik={formik} name='cpDesignation' width='w-full md:w-1/3' />
                            </div>
                            <div className="w-full md:block mx-auto my-4 mt-8 ">
                                <div className="md:flex justify-end gap-4">
                                    <CancelButton handleClick={() => { Oncancel() }} />
                                    <SubmitButton isLoading={isLoading} />
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateEmployee;