import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import MaintenanceType from './maintenanceType'
import InspectionResult from './inspectionResult'


const VehicleMain  = () => {
  return (
    <>
    <TextMediumSM content="Service Requested" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
       <MaintenanceType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
       <InspectionResult/>
     </div>
     </div>
     </>
  )
}

export default VehicleMain