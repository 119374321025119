import React, { useEffect, useState } from 'react'
import { currencies, formattedBloodGroups } from '../../../../assets/CountryMini';
import ViewMorePatients from './ViewMorePatients';
import DataTable from '../../../../Components/Layout/DataTable';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
// import UseGetData from '../../../../Components/Hooks/useFetchData';
import Dashboard from '../../../../Components/Icons/Dasboard';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { Modal } from 'antd';
import { NuDatePicker, NuInputText, NuSelect, NuSelectwithSearch } from '../../../../Components/Utils/NuInput';
import { Formik, useFormik } from 'formik';
import * as yep from 'yup'
import Card from '../../../../Components/Icons/card';
import List from '../../../../Components/Icons/list';
import OccupantsCard from './PatientsCard';
import PatientsCard from './PatientsCard';
import Pagination from '../../../../Components/Utils/Pagenation';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import UseDropDown from '../../../../Components/Hooks/useDropDown';


function Patientsindex() {
  
  const { value: InsuranceType } = useFetchMataData('InsuranceType') 
  const { data: occupant } = UseDropDown('/occupants/drop-down')
  const { value: Status } = useFetchMataData('Status_Patients') 
  const { value: Gender } = useFetchMataData('gender_Patients')


  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const [page, setPage] = useState(1)
  const [pageCount, setPageCount] = useState(20)
  // const { data, totalCount, getData} = UseGetData(page, pageCount, '/patients/');
  const [viewMode, setViewMode] = useState(localStorage.getItem("viewMode") || "card");
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const { data, totalCount, getData } = UseGetDataSearch('/patients/', params);
  
  const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false); //clear filter
  const closeFilterDialog = () => {
    setShowFilterDialog(false); //close filter
  };
  const [filterPatient, setFilterGuest] = useState({
    status: "",
    insuranceType: "",
    gender: "",
    nationality: "",
    revisitDateFrom: "",
    revisitDateTo: "",

  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: filterPatient,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yep.object({
      // email: yup.string().required('Email is required!'),
    }),
    onSubmit: async (value, { resetForm }) => {
      console.log("f", value);
      setParams({ ...params, status: value?.status, insuranceType: value?.insuranceType, gender: value?.gender, nationality: value?.nationality, revisitDateFrom: value?.revisitDateFrom, revisitDateTo: value?.revisitDateTo })
      closeFilterDialog();
      setFilter(true)
      setFilterGuest({
        status: "",
        insuranceType: "",
        gender: "",
        nationality: "",
        revisitDateFrom: "",
        revisitDateTo: "",
      });
    }

  })
  const clearFilter = () => {
    setParams({
      ...params,
      status: "",
      insuranceType: "",
      gender: "",
      nationality: "",
      revisitDateFrom: "",
      revisitDateTo: "",

    })
    formik.values.status = ""
    formik.values.insuranceType = ""
    formik.values.gender = ""
    formik.values.nationality = ""
    formik.values.revisitDateFrom = ""
    formik.values.revisitDateTo = ""
    setFilter(false)
  }

  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])

  console.log(data, 'dddd');

  let headers = [
    {
      label: <span>ID</span>,
      width: "w-[5%]",
    },
    {
      label: <span>Gender</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Nationality</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Problem Reported</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Medicine And Dosage</span>,
      width: "w-[10%]",
    },
    {
      label: <span> Revisit Date</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Insurance Type</span>,
      width: "w-[10%]",
    },
    {
      label: <span>Insurance Expiry</span>,
      width: "w-[10%]",
    },

    {
      label: <span>Actions</span>,
      width: "w-[5%]",
    },
  ];

  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: <p className='text-[13px]'>Clinic</p> },
          { title: <p className='text-[13px]'>Patient</p> },

        ]} />
        <div className=' flex gap-2'>
          <SearchBar handleChange={(e) =>
                        setParams({ ...params, search: e.target.value })
                      }
                     />
          <div className="flex gap-2">
              <div
                onClick={() => setViewMode("list")}
                className={
                  viewMode === "list"
                    ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white"
                    : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"
                }
              >
                <List />
              </div>
              <div
                onClick={() => setViewMode("card")}
                className={
                  viewMode === "card"
                    ? "bg-baseColor-primary h-8 w-10 rounded-md flex justify-center items-center text-white"
                    : "bg-white h-8 w-10 rounded-md flex justify-center items-center cursor-pointer"
                }
              >
                <Card />
              </div>
            </div>
          <FilterButton height='8' handleClick={() => setShowFilterDialog(true)} />
          {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" />)}

          <CreateButton label='Import' />
          <Link to={`/main/clinic/patients/${officeId}/create`}><CreateButton label='Patient' /></Link>
        </div>
      </div>
{viewMode === "list" ? (
      <DataTable
        bodyHeight='max-h-[calc(100vh-260px)]'
        height='h-[calc(100vh-175px)]'
        headers={headers}
        total={totalCount}
        pageIndex={page}
        setPageIndex={setPage}
        dataCount={pageCount}
        setDataCount={setPageCount}
      >
        {data.length > 0 ? data.map((item, i) => {
          let getCurrency;
          currencies.find((i) => {
            if (i.value == item?.currency) {
              getCurrency = i.label;
            }
          })
          return (
            <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
              <td className="w-[5%] p-2 px-[1px] text-center ">{item?.patientId || "#"}</td>
              {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
              <td className="w-[10%] p-2 px-[1px] text-center ">{item?.gender === 'M' ? 'Male' : item?.gender === 'F' ? 'Female' : ''}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item?.nationality || ""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center ">{item?.probReported || ""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center">{item?.medAndDosage || ""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center">{item?.revisitDate || ""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center">{item?.insuranceType || ""}</td>
              <td className="w-[10%] p-2 px-[1px] text-center">{item?.insuranceExp || ""}</td>

              <td className="w-[5%] p-2 px-[1px] text-center ">
                <ViewMorePatients data={item} fetcher={getData} id={item?.patientId} />
              </td>
            </tr>
          );
        })
          :
          <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
        }
      </DataTable>): (
              <>
          <div className="w-full h-[calc(100vh-190px)] rounded-md bg-white p-3 mt-4 overflow-y-scroll">
            <div className="w-full flex justify-start flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 pt-4">
              {data?.length > 0 ? data &&
                data.map((item, idx) => (
                  <PatientsCard fetcher={getData} item={item} key={idx} />
                ))
              :
              <p className='w-full text-center'>No Data Found</p>
              }
            </div>
          </div>
          </>)}
      <Modal
        open={showFilterDialog}
        title={"Filter Patient"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}>
        <form onSubmit={formik.handleSubmit}>
          <div className="">

            
          <NuSelect
                      label="Occupant Id"
                      options={occupant}
                      formik={formik}
                      placeholder="Choose"
                      name="occupantId"
                      width="w-full"
                    />
            <NuSelect label='Gender' options={Gender} placeholder='M/F/T' formik={formik} name='gender' width='w-full ' />
            <NuSelect
                  label="Insurance Type"
                  options={InsuranceType}
                  formik={formik}
                  placeholder="Choose"
                  name="insuranceType"
                  width="w-full "
                />
                    <NuSelect
              label={'Status'}
              options={Status}
              placeholder={'Choose'}
              name={'status'}
              formik={formik}
              width={'w-full '}
            />
          </div>

          <div className="flex justify-end gap-2">
            <CancelButton handleClick={closeFilterDialog} />
            <SubmitButton name='Apply' />
          </div>
        </form>
         </Modal>
         <div className='w-full h-20 '>
            <Pagination
             pageIndex={page}
             setPageIndex={setPage}
             dataCount={pageCount}
             setDataCount={setPageCount}
             total={totalCount}
            />
            </div>
    </div>
  )
}
export default Patientsindex