import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../../../../Components/Utils/Breadcrumbs";
import { CancelButton,DeleteButton,SubmitButton,CreateButton, FilterButton } from "../../../../Components/Utils/Buttons";
import SearchBar from "../../../../Components/Utils/SearchBar";
import { useState,useEffect } from "react";
import DataTable from "../../../../Components/Layout/DataTable";
import RoomViewMore from "./viewMore";
import UseGetData from "../../../../Components/Hooks/useFetchData";
import { currencies } from "../../../../assets/CountryMini";
import Dashboard from "../../../../Components/Icons/Dasboard";
import { useFormik } from 'formik';
import * as yup from 'yup'
import { Modal } from 'antd';
import { NuInputNumber, NuSelect } from '../../../../Components/Utils/NuInput';
import UseGetDataSearch from "../../../../Components/Hooks/useFetchDataBySearch";
import useFetchMataData from "../../../../Components/Hooks/useFetchMataData";
import UseDropDown from "../../../../Components/Hooks/useDropDown";


const Room = () => {
  const {officeId} = useParams();
  const { data: buildings } = UseDropDown('/buildings/drop-down');
  const { value: value } = useFetchMataData('RoomType')
  const { value: value1 } = useFetchMataData('RoomFacilities')
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, totalCount, getData } = UseGetDataSearch( '/rooms/',params);
    // const { data, totalCount, getData} = UseGetDataSearch(page, pageCount, '/rooms/');
    console.log(data,'dddd');
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
    
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const[filterRoom,setFilterRoom]=useState({
        buildingId: "",
        roomType:"",
        roomFacilities:"",
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterRoom,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, buildingId: value?.buildingId,roomType:value?.roomType,roomFacilities:value?.roomFacilities})
          closeFilterDialog();
          setFilter(true)
          setFilterRoom({
            buildingId: "",
            roomType:"",
            roomFacilities:"",
          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          buildingId: "",
          roomType:"",
          roomFacilities:"",
       
        })
        formik.values.buildingId=""
        formik.values.roomType=""
        formik.values.roomFacilities=""
          setFilter(false)
      }
      const [roleList, setRoleList] = useState([]);
      const [role, setRole] = useState('');
      // const  { data, totalCount, getData } = UseGetData(1,20,'/employees/');
      // console.log('ddd',data);
      
      // const userAccess = true;JSON.parse(localStorage.getItem("userAccess"));
      let AllUserCreateAccess = true;//userAccess
      // ? userAccess.some((permission) => permission.permissionName == "Settings_Alluser_create")
      // : false;
      useEffect(()=>{
        setParams({...params,page:page,per_page:pageCount})
       },[page,pageCount])
      
    let headers = [
      {
        label: <span>ID</span>,
        width: "w-[5%]",
      },
        {
          label: <span>Room No</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Room Name</span>,
          width: "w-[10%]",
        },
        {
            label: <span>Floor Name</span>,
            width: "w-[10%]",
        },
        // {
        //   label: <span>Room Usage</span>,
        //   width: "w-[10%]",
        // },
        {
          label: <span>Room Type</span>,
          width: "w-[10%]",
        },
        // {
        //   label: <span>Bed Type</span>,
        //   width: "w-[10%]",
        // },
        {
          label: <span>Number OF Bed</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Maximum Occupancy</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Actions</span>,
          width: "w-[5%]",
        },
      ];
      return (
        <div className='w-full h-full px-4 py-2'>
        <div className='w-full flex justify-between items-center'>
        <BreadCrumb items={[
          {title:(<Link to="/main/dashboard">
          <Dashboard color="gray" width={10} height={10} />{" "}
        </Link>)},
          {title:(<p className=' text-[12px] '>Accommodation</p>)},
          {title:(<p className=' text-[12px] '>Room</p>)}
          ]} />
        <div className=' flex gap-2'>
        <SearchBar
       handleChange={(e) =>
        setParams({ ...params, search: e.target.value })
      }
       />
         <FilterButton height='8' 
                     handleClick={() => setShowFilterDialog(true)}/>
                     {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
         <CreateButton label='Import' />
         <Link to={`/main/accomodation/room/${officeId}/create`}><CreateButton label='Room'/></Link> 
        </div>
        </div>
    
        <DataTable
         bodyHeight='max-h-[calc(100vh-260px)]'
         height='h-[calc(100vh-130px)]'
         headers={headers} 
         total={totalCount}
         pageIndex={page}
         setPageIndex={setPage}
         dataCount={pageCount}
         setDataCount={setPageCount}
        >
        {data.length>0?data.map((item, i) => {
                let getCurrency;
                currencies.find((i) => {
                  if(i.value == item?.currency){
                    getCurrency=i.label;
                  }
                })
                return (
                  <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                    <td className="w-[5%] p-2 px-[1px] text-center ">{item?.roomId||"#"}</td>
                    {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                    <td className="w-[10%] p-2 px-[1px] text-center ">{item?.roomNumber||""}</td>
                    <td className="w-[10%] p-2 px-[1px] text-center">{item?.roomName||""}</td>
                    <td className="w-[10%] p-2 px-[1px] text-center ">{item?.floorName||""}</td>
                    {/* <td className="w-[10%] p-2 px-[1px] text-center ">{item?.roomUsage||""}</td> */}
                    <td className="w-[10%] p-2 px-[1px] text-center">{item?.roomType||""}</td>
                    <td className="w-[10%] p-2 px-[1px] text-center">{item?.noOfBeds||""}</td>
                    <td className="w-[10%] p-2 px-[1px] text-center">{item?.maxOccupancy ||""}</td>
                    <td className="w-[5%] p-2 px-[1px] text-center ">
                        <RoomViewMore data={item} fetcher={getData} id={item?.roomId}/>
                    </td>
                  </tr>
                );
              })
              :
              <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
            }
        </DataTable>
        
      <Modal
        open={showFilterDialog}
        title={"Filter Room"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="flex flex-col mb-4">
               
                    <NuSelect
              label="Building"
              placeholder="choose"
              options={buildings}
              formik={formik}
              name="buildingId"
              width="w-full"
            />
                    <NuSelect
              label="Room Type"
              placeholder="choose"
              options={value}
              formik={formik}
              name="roomType"
              width="w-full"
            />
             <NuSelect
                  label="Room Facilities"
                  placeholder="choose"
                  options={value1}
                  formik={formik}
                  name="roomFacilities"
                  width="w-full"
                />
                    </div>
                    
                   <div className="flex justify-end gap-2">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
      </div>
      )
    }
export default Room;
