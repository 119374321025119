import React, { useEffect, useState } from 'react'
import { BackButton, EditButton } from '../../../../Components/Utils/Buttons';
import { Link, useLocation, useParams } from 'react-router-dom';
import axiosInstance from '../../../../Services/axiosCommon';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { Modal } from 'antd';
import Dashboard from '../../../../Components/Icons/Dasboard';

const ViewPurchaseRequest = () => {
    const { officeId } = useParams();
//   const { medicalProductsViewId } = useParams();
const { purchaseRequestViewId } = useParams();
  // const location = useLocation();
  //   const data = location.state.data;
  //   console.log(location.state,'state');

    const [url,setUrl] = useState(false);

 
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const [data, setData] = useState([]);
  const [image, setImage] = useState({ preview: "", raw: "" });
//   const [createNew, setCreateNew] = useState({
//     // campId: "",
//     // buildName:"",
//     // buildNo: "",
//     // floors: ""
//     poId: data?.poId,
//     prId: data?.prId,
//     vendorId: data?.vendorId,
//     refNumber:data?.refNumber,
//     totalPrice: data?.totalPrice,
//     requestedDeliveryDate:data?.requestedDeliveryDate,
//     requestedBy: data?.requestedBy,
//     paymentStatus: data?.paymentStatus,
//     status:data?.status,
//     approvedBy:data?.approvedBy,
//     supportDocType:data?.supportDocType,
//     products:data?.products
   
// })
const handleChangeImg = (e) => {
    if (e.target.files.length) {
        setImage({
            preview: URL.createObjectURL(e.target.files[0]),
            raw: e.target.files[0],
        });
    }
};
const handleImageRemove = () => {
    setImage({
        preview: "",
        raw: "",
    });
}
  async function getBiding() {
    await axiosInstance
      .get(`/PR/${purchaseRequestViewId}`)
      .then(async (res) => {
        console.log("find", res?.data?.data);
        (await res?.data?.data) && setData(res?.data?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  useEffect(()=>{
    getBiding();
  },[]);
  console.log(data,"data");


    return (
      <div className=' bg-[#E6F2F2] h-full'>
            {/* <div className='mx-6 pt-3'>
                <BreadCrumb items={[
                    { title: <p className='text-[13px]'>Procurement</p> },
                    { title: <p className='text-[13px]'>Purchase Request</p> },
                    { title: <p className='text-[13px]'>View</p> },
                ]}
                />
            </div>
            <div className='flex justify-end'>
                <div className='w-1/2 flex justify-end'>
                    <div className='pr-6 flex gap-3'>
                        <div className='flex justify-end gap-2'>
                        <Link to={`/main/procurment/purchase-request/${officeId}`}><BackButton link={true} /></Link>
          <Link to={`/main/procurment/purchase-request/${officeId}/edit/${purchaseRequestViewId}`} state={{ from: `/main/procurment/purchase-request/${officeId}/view/${data.employee_id}`, data: data }}><EditButton height='8' /></Link>
       
                            </div>
                    </div>
                </div>
            </div> */}
             <div className='flex justify-between items-center py-2 mx-6'>
                <BreadCrumb items={[
                    {
                        title: (
                          <Link to="/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                          </Link>
                        ),
                      },
                    
                    { title: <p className='text-[13px]'>Procurement</p> },
                    { title: <p className='text-[13px]'>Purchase</p> },
                    { title: <p className='text-[13px]'>View</p> },
                ]}
                />
                <div className='w-1/2 flex justify-end'>
                    <div className='flex gap-3'>
                        <Link to={`/main/procurment/purchase-request/${officeId}`}><BackButton link={true}/></Link>
                        <div>
                            <Link 
                            to={`/main/procurment/purchase-request/${officeId}/edit/${data?.prId}`} 
                            state={{ data : data , from : `/main/procurment/purchase-request/${officeId}/view/${data?.prId}`}} >
                                <EditButton height='8' />
                            </Link>
                        </div> 
                    </div>
                </div>
            </div>
            {/*  Section-1 */}
            <div className='px-6 py-5 flex  gap-5'>
                <div className='gap-2 px-5 py-3 w-full h-[calc(100vh-175px)] overflow-scroll bg-[#FFF] rounded-[5px] p-5'>
                    {/* <h3 className='text-[#212121] text-sm font-semibold leading-normal'>Building Layout</h3> */}
                    
                    <div className=''>
                       
                        <div className='' />
                        <div className='w-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto'>
                            <div className='w-full grid grid-cols-5 gap-x-8 m-10'>
                            <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Id</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.prId || '-'}</p>
                                </div>
                            <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Project</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.project || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Project Code</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.projectCode || '-'}</p>
                                </div>
                                {/* <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Ref Number</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.refNumber|| '-'}</p>
                                </div> */}
                                <div className='w-ful grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Ref</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.ref || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Reviewed By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.reviewedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Revision Count</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.revisionCount || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Status</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.status || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Verified By</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.verifiedBy || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Verified By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.verifiedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Approved By</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.approvedBy || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Approved By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.approvedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Cost Code</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.costCode || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Date</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.date || '-'}</p>
                                </div>
                                {/* <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Verified By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.verifiedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Ref</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.ref || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Requested By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.requestedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Reviewed By</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.reviewedBy || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Reviewed By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.reviewedByName || '-'}</p>
                                </div> */}
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Deadline</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.deadLine || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Is Active</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.isActive || '-'}</p>
                                </div>
                                {/* <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Delivery Address</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.deliveryAddress || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Delivery Terms</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.deliveryTerms || '-'}</p>
                                </div> */}
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Prepared By</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.preparedBy || '-'}</p>
                                </div>
                                <div className='w-full grid px-2'>
                                    <h4 className='text-[#212121] py-3'>Prepared By Name</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.preparedByName || '-'}</p>
                                </div>
                                <div className='w-full grid px-2 '>
                                    <h4 className='text-[#212121] py-3'>Comments</h4>
                                    <p className='text-[#4D4D4D] py-3'>{data?.comments || '-'}</p>
                                </div>
                              
                            </div>
                            <div className='w-full flex justify-start gap-2 flex-wrap my-2'>
    {data?.attachments?.map((imgs, idx) => {
                                        console.log('img', imgs);
                                        return (
                                            <div
                                                className=" w-28 h-11 relative flex justify-center items-center rounded-lg"
                                                key={idx}
                                                style={{ border: "1px solid gray", borderColor: "rgb(148 163 184 / var(--tw-bg-opacity))" }}
                                            >
                                                <div className=" w-28 h-11 flex justify-center items-center rounded-lg overflow-hidden cursor-pointer" 
                                                // onClick={() => handleImageClick(imgs)}
                                                onClick={() => {setShowFilterDialog(true);setUrl(imgs?.file)}}
                                                >
                                                    {/* {imgs && <ViewFiles data={imgs} key={idx} />} */}
                                                    <img src={imgs?.file} alt={imgs?.file} />
                                                </div>
                                            </div>
                                         );
                                    })} 
                                    </div>
                        </div>
                        <h3>PR Attachement Files</h3>

                   
                        <div className='w-full '>
                                        <div className='w-full py-[1%] bg-[#048178] flex justify-between rounded-lg p-4'>
                                        {/* <p className=" w-[20%] text-center text-white text-sm font-medium">
                                        Quote Product Id
                                        </p> */}
                                        <p className=" w-[20%] text-center text-white text-sm font-medium">
                                        Item Code
                                        </p>
                                       
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Product Id
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Product Category
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Product Name
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Product Unit
                                        </p>
                                        <p className=" w-[25%] text-center text-white text-sm font-medium">
                                        Quantity
                                        </p>
                                        </div>
                                        <div className='overflow-scroll h-[340px]'>
                                        {data?.productList?.map((data,index)=>{
                                  return(
                                    <div key={index} className="w-full py-2 flex justify-between">
                                    {/* <p className=" w-[20%] text-center text-black text-sm font-light py-1">
                                      {data?.quoteProductId}
                                    </p> */}
                                    <p className=" w-[20%] text-center text-black text-sm font-light py-1">
                                      {data?.item_code}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light py-1">
                                      {data?.productId}
                                      </p>
                                      <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.product_category}
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.product_name} 
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.product_unit} 
                                    </p>
                                    <p className=" w-[25%] text-center text-black text-sm font-light">
                                      {data?.quantity} 
                                    </p>
                                    </div>
                                  );
                                })}
                                </div>
                </div>
                    </div>
                </div>
                
                <Modal
        open={showFilterDialog}
        title={"Purchase Request"}
        width={"80%"}
        
        className="my-modal"
        onCancel={closeFilterDialog}
        footer={[]}
      >
        <div className='w-full h-[80vh] overflow-hidden'>
        <img src={url} alt="" className='' width={"100%"} />
        </div>
           </Modal>
               
            </div>

          
          
        </div>
        

    )
}

export default ViewPurchaseRequest