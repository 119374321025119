import React from 'react'
import { useFormik } from 'formik';
import { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { Link, Navigate, useLocation, useParams } from 'react-router-dom';
import { NuDatePicker,  NuSelect, NuTextArea, NuInputText,NuInputNumber } from '../../../../Components/Utils/NuInput';
import Dashboard from '../../../../Components/Icons/Dasboard';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import useEditFormData from '../../../../Components/Hooks/useEditFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';

function EditVehicleMaintenance() {
  const {id} = useParams();
  const { value: maintenanceTypeValue, getData: maintenanceType } = useFetchMataData('maintenanceType')
  const { value: inspectionResultValue, getData: inspectionResult } = useFetchMataData('inspectionResult')
  const {data:vehicle} = UseDropDown('/vehicle/drop-down')

  const { value: CalliberReq} = useFetchMataData('CalliberReq') 
  const { value: EquipmentType} = useFetchMataData('EquipmentType')
  const { value: Condition} = useFetchMataData('Condition')

  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  
    const { equipmentsEditId } = useParams();
    const location=useLocation()
    const data =location.state.data
    const from=location.state.from
    // console.log(location.state,'from');
    // const [division, setDivision] = useState([]);
    const { Oncancel, editFormData, contextHolder, isLoading } = useEditFormData();
    const [createNew, setCreateNew] = useState({
      branchId: data?. branchId,
      vehicleId: data?.vehicleId,
      maintenancePeriod: data?.maintenancePeriod,
      mileageSinceLastMaintenance: data?.mileageSinceLastMaintenance,
      maintenanceStartDate: data?.maintenanceStartDate,
      maintenanceType: data?.maintenanceType,
      detailsOfParts: data?.detailsOfParts,
      maintenanceEndDate: data?.maintenanceEndDate,
      reason: data?.reason,
      workDoneBy: data?.workDoneBy,
      history: data?.history,
      agreementInfo: data?.agreementInfo,
      comments: data?.comments,
      inspectionResult: data?.inspectionResult,

      nextMaintenance: data?.nextMaintenance,

    })
    console.log(data);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
      
            editFormData(`/vehicle-maintenance/edit-vehicle/${data?.vehicleMaintainId}`, value, resetForm, from);

        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Maintenance</p>) },
        { title: (<p className=' text-[12px] '>Vehicle</p>) },
        { title: (<p className=' text-[12px] '>Edit</p>) },
    ]} />
    <BackButton/>
    </div>
 
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          <NuSelect
            label="Vehicle Id"
            placeholder="vehicleId"
            options={vehicle}
            formik={formik}
            name="Vehicle Id"
            width="w-full md:w-1/3"
            />
          <NuInputNumber
              // isRequired={true}
              label="Meter Reading"
              placeholder="Meter Reading"
              formik={formik}
              name="odoMeterReading"
              width="w-full md:w-1/3"
            />
<NuInputNumber
              // isRequired={true}
              label="Mileage Since Last Maintenance"
              placeholder="Mileage Since Last Maintenance"
              formik={formik}
              name="mileageSinceLastMaintenance"
              width="w-full md:w-1/3"
            />

<NuInputText
              // isRequired={true}
              label="Maintenance Period"
              placeholder="Maintenance Period"
              formik={formik}
              name="maintenancePeriod"
              width="w-full md:w-1/3"
            />
            
            <DropDownAddMetaData
              label="Maintenance Type"
              Options={maintenanceTypeValue}
              formik={formik}
              placeholder="Choose"
              name="maintenanceType"
              getFunc={() => maintenanceType()}
              displayProperty={"Maintenance Type"}
              propertyName={"maintenanceType"}
              width="w-full md:w-1/2 lg:w-1/3"
          />
           <NuDatePicker
              label="Maintenance Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="maintenanceStartDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Maintenance End Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="maintenanceEndDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Next Maintenance"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="nextMaintenance"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              // isRequired={true}
              label="Inspection Result"
              Options={inspectionResultValue}
              placeholder="inspectionResult"
              formik={formik}
              name="inspectionResult"
              getFunc={() => inspectionResult()}
              displayProperty={"Inspection Result"}
              propertyName={"inspectionResult"}
              width="w-full md:w-1/3"
            />
            {/* <DropDownAddMetaData
              label="Caliber Request"
              Options={CalliberReqValue}
              formik={formik}
              placeholder="caliber Request"
              name="calibReq"
              getFunc={() => CalliberReq()}
              displayProperty={"Calliber Req"}
              propertyName={"CalliberReq"}
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
           {/* <NuSelect
            label="Caliber Request"
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            formik={formik}
            placeholder="Choose"
            name="calibReq"
            width="w-full md:w-1/3"
          />  */}
          <NuInputText
              // isRequired={true}
              label="Work Done By"
              placeholder="Work Done By"
              formik={formik}
              name="workDoneBy"
              width="w-full md:w-1/3"
            />
           <NuInputText
              // isRequired={true}
              label="Details Of Parts"
              placeholder="Details Of Parts"
              formik={formik}
              name="detailsOfParts"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Reason"
              placeholder="Reason"
              formik={formik}
              name="reason"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Agreement Info"
              placeholder="Agreement Info"
              formik={formik}
              name="agreementInfo"
              width="w-full md:w-1/3"
            />
           
            <NuInputText
              // isRequired={true}
              label="History"
              placeholder="History"
              formik={formik}
              name="history"
              width="w-full md:w-1/3"
            />
          <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Navigate(-1)}} />
                <SubmitButton  isLoading={isLoading} />
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
  )
}

export default EditVehicleMaintenance