import React, { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker,  NuInputText, NuSelect, NuTextArea,NuInputNumber } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Navigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dropdown from 'antd/es/dropdown/dropdown';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';

function CreateEquipmentMaintenance() {
  const {officeId} = useParams();

  const { value: maintenanceTypeValue, getData: maintenanceType } = useFetchMataData('maintenanceType')
  const { value: inspectionResultValue, getData: inspectionResult } = useFetchMataData('inspectionResult')
  const { value: CalliberReqValue, getData: CalliberReq } = useFetchMataData('CalliberReq')




  const { value: CalliberReqq} = useFetchMataData('CalliberReq') 
  const { value: EquipmentTypee} = useFetchMataData('EquipmentType')
  const { value: Conditionn} = useFetchMataData('Condition')
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:equipment} = UseDropDown('/equipments/drop-down')
  const {data:employees} = UseDropDown('/employees/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData ();
  const [createNew, setCreateNew] = useState({
      // reqType: "RoomService",
      branchId:officeId,
      equipmentId:"",
      maintenanceStartDate:"",
      maintenanceEndDate: "",
      maintenancePeriod:"",
      maintenanceType:"",
      detailsOfParts:"",
      workDoneBy:"",
      nextMaintenance:"",
      reason:"",
      agreementInfo:"",
      inspectionResult:"",
      history:"",
      comments:"" 
  })
 

  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
         

      addFormData('equipment-maintenance/new-equipment', value, resetForm);
         
      }
      
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Maintenance</p>) },
        { title: (<p className=' text-[12px] '>Equipment</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
            {/* <NuSelect
            label="Equipment Id"
            placeholder="Equipment Id"
            options={equipment}
            formik={formik}
            name="equipmentId"
            width="w-full md:w-1/3"
            /> */}
             <NuInputText
            label="Equipment Id"
            placeholder="Equipment Id"
            // options={equipment}
            formik={formik}
            name="equipmentId"
            width="w-full md:w-1/3"
            />
            <NuDatePicker
              label="Maintenance Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="maintenanceStartDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Maintenance End Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="maintenanceEndDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Maintenance Period"
              placeholder="Maintenance Period"
              formik={formik}
              name="maintenancePeriod"
              width="w-full md:w-1/3"
            />
             <DropDownAddMetaData
              label="Maintenance Type"
              Options={maintenanceTypeValue}
              formik={formik}
              placeholder="Choose"
              name="maintenanceType"
              getFunc={() => maintenanceType()}
              displayProperty={"Maintenance Type"}
              propertyName={"maintenanceType"}
              width="w-full md:w-1/2 lg:w-1/3"
          />
          <NuInputText
              // isRequired={true}
              label="Details Of Parts"
              placeholder="Details Of Parts"
              formik={formik}
              name="detailsOfParts"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Work Done By"
              placeholder="Work Done By"
              formik={formik}
              name="workDoneBy"
              width="w-full md:w-1/3"
            />
            <NuDatePicker
              label="Next Maintenance"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="nextMaintenance"
              width="w-full md:w-1/2 lg:w-1/3"
            />
          
            {/* <DropDownAddMetaData
              label="Caliber Request"
              Options={CalliberReqValue}
              formik={formik}
              placeholder="caliber Request"
              name="calibReq"
              getFunc={() => CalliberReq()}
              displayProperty={"Calliber Req"}
              propertyName={"CalliberReq"}
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
           {/* <NuSelect
            label="Caliber Request"
            options={[
              { value: true, label: "Yes" },
              { value: false, label: "No" },
            ]}
            formik={formik}
            placeholder="Choose"
            name="calibReq"
            width="w-full md:w-1/3"
          />  */}
         
          
            <NuInputText
              // isRequired={true}
              label="Reason"
              placeholder="Reason"
              formik={formik}
              name="reason"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Agreement Info"
              placeholder="Agreement Info"
              formik={formik}
              name="agreementInfo"
              width="w-full md:w-1/3"
            />
            <DropDownAddMetaData
              // isRequired={true}
              label="Inspection Result"
              Options={inspectionResultValue}
              placeholder="inspectionResult"
              formik={formik}
              name="inspectionResult"
              getFunc={() => inspectionResult()}
              displayProperty={"Inspection Result"}
              propertyName={"inspectionResult"}
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="History"
              placeholder="History"
              formik={formik}
              name="history"
              width="w-full md:w-1/3"
            />
          <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/3'
            />
              {/* <NuInputNumber
              label="Id"
              placeholder="Id"
              formik={formik}
              name="roomserviceId"
              width="w-full md:w-1/3"
            /> */}
            {/* <NuSelect
              label="Menu Id"
              options={[
                { value: 1, label: "Veg" },
                { value: 2, label: "Non-veg" },
              ]}
              formik={formik}
              placeholder="Choose"
              name="menuId"
              width="w-full md:w-1/2 lg:w-1/3"
            /> */}
          
            {/* <NuInputNumber
              label="Prpared By"
              placeholder="prparedBy"
              formik={formik}
              name="prparedBy"
              width="w-full md:w-1/3"
            /> */}
             {/* <NuInputNumber
              label="Bed No"
              placeholder="Bed No"
              formik={formik}
              name="Bed No"
              width="w-full md:w-1/3"
            /> */}
            
            
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateEquipmentMaintenance