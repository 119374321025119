import React, { useState } from 'react'
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { Link, Navigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import { NuDatePicker, NuInputNumber, NuInputText, NuInputfile, NuSelect, NuTextArea } from '../../../../Components/Utils/NuInput';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateReferrence() {
  const { value: value } = useFetchMataData('category_reference')
  const { value: category_referenceValue, getData: category_reference } = useFetchMataData('category_reference')
 const {officeId} = useParams()

  const [images, setImages] = useState([]);
    const [selectedFile,setSelectedFile] =useState(null)
    // const [division, setDivision] = useState([]);
  const [ api ] = notification.useNotification();
  
    const [image, setImage] = useState({ preview: "", raw: "" });
    // const [division, setDivision] = useState([]);
    const { Oncancel, addFormData, contextHolder, isLoading } = useAddFormData();
    const [createNew, setCreateNew] = useState({
      subject:"", 
      branchId:officeId,
      details:"", 
      category:"", 
      comments:"", 
      

    })
    const handleChangeImg = (e) => {
        if (e.target.files.length) {
            setImage({
                preview: URL.createObjectURL(e.target.files[0]),
                raw: e.target.files[0],
            });
        }
    };
    const handleImageRemove = () => {
        setImage({
            preview: "",
            raw: "",
        });
    }

    function handleImageChange(e) {
      const maxFileSize = 200000000; // 200 MB
      const files = Array.from(e.target.files);
      let fileArr = []
      files.map(item=>{
        if (item.size < maxFileSize ){
          fileArr.push(item)
        }else{
          api.warning({
            message: `File Size to big`,
            description: `${item.name} is Removed and not Uploaded`,
            placement: "bottomRight",
          });
        }
      })    
      let tempArr = []
      setSelectedFile(fileArr);
      files.map(item=>{
        if (item.size < maxFileSize ){
        let fileName = item?.name
        console.log('fname',fileName);
        let fileExtention =  fileName.split(".").reverse()[0]
        if(filetype?.includes(fileExtention)){
          tempArr.push(fileName)
        }else{
          let x = URL.createObjectURL(item)
          tempArr.push(x)
        }
      }
      })
      console.log(tempArr,'temp');
      setImages(tempArr)
  }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: createNew,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
            // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
            let formData = new FormData();
            formData.append('referenceData', JSON.stringify(value));
            formData.append('attachments', image.raw);

            addFormData('/reference/new', formData, resetForm);
        }
    })
  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Clinic</p>) },
        { title: (<p className=' text-[12px] '>Reference</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/
    >
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            
          
             <NuInputText
              label="Subject"
              placeholder="Subject"
              formik={formik}
              name="subject"
              width="w-full md:w-1/3"
            />
             <DropDownAddMetaData
              label="Category"
              Options={category_referenceValue}
              formik={formik}
              placeholder="Choose"
              name="category"
              getFunc={() => category_reference()}
              displayProperty={"category"}
              propertyName={"category_reference"}
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuTextArea
              label="Details"
              placeholder="Details"
              formik={formik}
              name="details"
              width='w-full md:w-1/3'
            />
             <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/2'
            />
              <Attachement
                  handleChange={handleImageChange}
                  preview={images||''}
                  width="w-full"
                  label="Attach"
                  multiple={true}
                />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{Oncancel(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
      </div>
               
               )
             }
export default CreateReferrence