import React, { useState,useEffect } from 'react'
import { Link, useParams } from 'react-router-dom';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CreateButton, FilterButton, SubmitButton, CancelButton,DeleteButton} from '../../../../Components/Utils/Buttons';
import DataTable from '../../../../Components/Layout/DataTable';
import { currencies } from '../../../../assets/CountryMini';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { NuInputNumber, NuSelect, NuInputText,NuDatePicker } from '../../../../Components/Utils/NuInput';
import { useFormik } from 'formik';
import * as yup from 'yup'
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { Modal } from 'antd';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import ViewMorePurchaseOrder from './ViewMorePurchaseOrder';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function PurchaseOrderIndex() {
  const {data:user} =UseDropDown('/user/drop-down')

  const {data:purchase} =UseDropDown('/PR/drop-down')
  const {data:vendors} =UseDropDown('/vendors/drop-down')
  const { value: PaymentStatus} = useFetchMataData('PaymentStatus')
  const { value: ApprovalStatus} = useFetchMataData('ApprovalStatus')
  const { officeId } = useParams();
    
  const [page,setPage]=useState(1)
  const [pageCount,setPageCount]=useState(20)
  const [params, setParams] = useState({
    page: page,
    per_page: pageCount,
    search: "",
  });
  const { data, totalCount, getData } = UseGetDataSearch( '/PO/',params);
  console.log(data,'dddd');
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
  const [showFilterDialog, setShowFilterDialog] = useState(false);
const [filter, setFilter] = useState(false);
  const closeFilterDialog = () => {
      setShowFilterDialog(false);
    };
    const[filterPO,setfilterPO]=useState({
      prId:"",
      vendorId:"",
      paymentStatus:"",
      approvalStatus:"",
      requestedBy:"",
    });
    const formik = useFormik({
      enableReinitialize: true,
      initialValues: filterPO,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
        // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
        console.log("ffff",value);
        setParams({...params, prId: value?.prId,vendorId: value?.vendorId,paymentStatus: value?.paymentStatus, approvalStatus: value?.approvalStatus, requestedBy: value?.requestedBy ,paymentTerms: value?.paymentTerms,})
        closeFilterDialog();
        setFilter(true)
        setfilterPO({
          prId:"",
          vendorId:"",
          paymentStatus:"",
          approvalStatus:"",
          requestedBy:"",
        });
      }
    })
    const clearFilter = ()=> {
      setParams({...params,
        prId:"",
        vendorId:"",
        paymentStatus:"",
        approvalStatus:"",
        requestedBy:"",
        
     
      })
      formik.values.prId=""
      formik.values.vendorId=""
      formik.values.paymentStatus=""
      formik.values.approvalStatus=""
      formik.values.requestedBy=""
        setFilter(false)
    }

    let headers = [
        {
          label: <span>ID</span>,
          width: "w-[5%]",
        },
        // {
        //   label: <span>Meal Id</span>,
        //   width: "w-[5%]",
        // },
        {
          label: <span>Ordered Qty</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Total Price</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Payment Status</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Requested Delivery Date</span>,
          width: "w-[10%]",
        },
        {
          label: <span>Actions</span>,
          width: "w-[5%]",
        },
      ];

      useEffect(()=>{
        setParams({...params,page:page,per_page:pageCount})
       },[page,pageCount])
     
return (
  <div className='w-full h-full px-4 py-2'>
    <div className='w-full flex justify-between items-center'>
    <BreadCrumb items={[
      {title:(<Link to="/main/dashboard">
      <Dashboard color="gray" width={10} height={10} />{" "}
    </Link>)},
      {title:(<p className=' text-[12px] '>Procurement</p>)},
      {title:(<p className=' text-[12px] '>Purchase Order</p>)}
      ]} />
    <div className=' flex gap-2'>
     <SearchBar
     handleChange={(e) =>
      setParams({ ...params, search: e.target.value })
    }
     />
     <FilterButton height='8' 
      handleClick={() => setShowFilterDialog(true)}/>
      {filter && ( 
           <FilterButton 
          handleClick={() => clearFilter()} 
          label="Clear" /> 
        )}
     <CreateButton label='Import' />
     <Link to={`/main/procurment/purchase-order/${officeId}/create`}><CreateButton label='Purchase'/></Link> 
    </div>
    </div>
    <DataTable
     bodyHeight='max-h-[calc(100vh-260px)]'
     height='h-[calc(100vh-130px)]'
     headers={headers} 
     total={totalCount}
     pageIndex={page}
     setPageIndex={setPage}
     dataCount={pageCount}
     setDataCount={setPageCount}
    >
    {data.length>0?data.map((item, i) => {
            let getCurrency;
            currencies.find((i) => {
              if(i.value == item?.currency){
                getCurrency=i.label;
              }
            })
            console.log("aaa",data?.brand);
            return (
              <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                <td className="w-[5%] p-2 px-[1px] text-center ">{item?.poId||"#"}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.products?.map((n)=>{
                    return(
                      <p>{n?.orderedQty}</p>
                    )
                  })||""}</td>
                {/* <td className="w-[5%] p-2 px-[1px] text-center ">{item?.menuId||"#"}</td> */}
                {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.totalPrice||""}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.paymentStatus||"kkk"}</td>
                <td className="w-[10%] p-2 px-[1px] text-center ">{item?.requestedDeliveryDate||""}</td>
                
                <td className="w-[5%] p-2 px-[1px] text-center ">
                    <ViewMorePurchaseOrder data={item} fetcher={getData} id={item?.poId} />
                </td>
              </tr>
            );
          })
          :
          <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
        }
    </DataTable>

    <Modal
      open={showFilterDialog}
      title={"Purchase Order"}
      width={"50%"}
      className="my-modal"
      centered
      onCancel={closeFilterDialog}
      footer={[]}
    >
               <form onSubmit={formik.handleSubmit}> 
                  <div className="grid grid-cols-2 mb-4">
                  
                  <NuSelect 
                                        label='Purchase Id'
                                         placeholder='Purchase Id'
                                         options={purchase}
                                          formik={formik} 
                                          name='prId'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width=' w-full' />
                                           <NuSelect 
                                        label='Vendor Id'
                                         placeholder='Vendor Id'
                                         options={vendors}
                                          formik={formik} 
                                          name='vendorId'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full' />
                                          
                                           <NuSelect
                                        label='Requested By'
                                        // options={user}
                                         placeholder='Requested By'
                                         options={user}
                                          formik={formik} 
                                          name='requestedBy'
                                        //   onChange={handleChange}
                                        //   disable={true}
                                           width='w-full md:w-1/3' />
          <NuSelect
            label="Payment Status"
            options={PaymentStatus}
            formik={formik}
            placeholder="Choose"
            name="paymentStatus"
            width="w-full"
          />
          
                  </div>
                  
                 <div className="flex justify-end gap-2">
                   <CancelButton handleClick={closeFilterDialog} />
                   <SubmitButton name='Apply' />
                 </div>
                </form> 
             {/* </div>
           </div>
         )} */}
         </Modal>         
  </div>
)
}

export default PurchaseOrderIndex