import React from 'react'
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs'
import { BackButton } from '../../../../Components/Utils/Buttons'
import Patients from './Patients/Index'
import ContactList from './ContactList/Index'
import Stock from './Stocks/Index'
import Issuance from './Issuance/Index'
import InsuranceType from './Product/Index'
import Product from './Product/Index'
import { Link } from 'react-router-dom'
import Dashboard from '../../../../Components/Icons/Dasboard'
import Reference from './Reference'

const MainClininc = () => {
  return (
    <div className='w-full h-full px-4 py-2'>
    <div className='w-full flex justify-between items-center'>
    <BreadCrumb items={[
                    {
                        title: (<Link to="/main/dashboard">
                            <Dashboard color="gray" width={10} height={10} />{" "}
                        </Link>)
                    },
                    { title: (<p className=' text-[12px] '>Settings</p>) },
                    { title: (<p className=' text-[12px] '>Clinic</p>) },
                ]} />
    <BackButton/>
    </div>
    <div className="w-full h-[calc(100vh-145px)] bg-white mt-3 mx-auto rounded-md ">
        <div className="p-3"><Patients/></div>
        <div className="p-3"><Product/></div>
        <div className="p-3"><ContactList/></div>
        <div className="p-3"><Stock/></div>
        <div className="p-3"><Issuance/></div>
        <div className="p-3"><Reference/></div>


    </div>
    </div>
  )
}

export default MainClininc