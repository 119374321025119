import React, { useEffect, useState } from 'react'

import { Link, useParams } from 'react-router-dom';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import SearchBar from '../../../../Components/Utils/SearchBar';
import { CancelButton, CreateButton, FilterButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import DataTable from '../../../../Components/Layout/DataTable';
import { currencies } from '../../../../assets/CountryMini';
import ViewMoreIssuance from './ViewMoreIssuance';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { NuDatePicker, NuInputText, NuSelect } from '../../../../Components/Utils/NuInput';
import { Modal } from 'antd';
import UseDropDown from '../../../../Components/Hooks/useDropDown';


function IssuanceIndex() {
  const {officeId} = useParams();
  const {data:medicalStocks} =UseDropDown('/medical-stocks/drop-down')
  const {data:patients} =UseDropDown('/patients/drop-down')
  console.log("nnnnnn0",officeId)
    const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, totalCount, getData} = UseGetDataSearch( '/issuances/',params);
    console.log(data,'dddd');
  
      let headers = [
          {
            label: <span>ID</span>,
            width: "w-[5%]",
          },
          {
            label: <span>Date</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Stock Id</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Patient Id</span>,
            width: "w-[10%]",
          },
          {
            label: <span>Issued Quality</span>,
            width: "w-[10%]",
          },
        //   {
        //     label: <span>Occupant Id</span>,
        //     width: "w-[10%]",
        //   },
        //   {
        //     label: <span>Comments</span>,
        //     width: "w-[10%]",
        //   },
         
          {
            label: <span>Actions</span>,
            width: "w-[5%]",
          },
        ];

        const [showFilterDialog, setShowFilterDialog] = useState(false);
        const [filter, setFilter] = useState(false);
          const closeFilterDialog = () => {
              setShowFilterDialog(false);
            };
            const[filterIssuance,setfilterIssuance]=useState({
              patientId:"",
              stockId:""
            });
            const formik = useFormik({
              enableReinitialize: true,
              initialValues: filterIssuance,
              validateOnChange: false,
              validateOnBlur: false,
              validationSchema: yup.object({
                // email: yup.string().required('Email is required!'),
              }),
              onSubmit: async (value, { resetForm }) => {
                console.log("ffff",value);
                setParams({...params, patientId: value?.patientId,stockId: value?.stockId})
                closeFilterDialog();
                setFilter(true)
                setfilterIssuance({
                  patientId: "",
                  stockId:""
                });
              }
            })
            const clearFilter = ()=> {
              setParams({...params,
                patientId: "",
                stockId:""
             
              })
              formik.values.patientId=""
                setFilter(false)
            }
            useEffect(()=>{
              setParams({...params,page:page,per_page:pageCount})
             },[page,pageCount])
    return (
      <div className='w-full h-full px-4 py-2'>
      <div className='w-full flex justify-between items-center'>
      <BreadCrumb items={[
        {title:(<Link to="/main/dashboard">
        <Dashboard color="gray" width={10} height={10} />{" "}
      </Link>)},
        {title:(<p className=' text-[12px] '>Clinic</p>)},
        {title:(<p className=' text-[12px] '>Issuance</p>)}
        ]} />
      <div className=' flex gap-2'>
      <SearchBar
       handleChange={(e) =>
        setParams({ ...params, search: e.target.value })
      }
       />
       <FilterButton height='8'
       handleClick={() => setShowFilterDialog(true)}/>
       {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
       <CreateButton label='Import' />
       <Link to={`/main/clinic/issuance/${officeId}/create`}><CreateButton label='Issuance'/></Link> 
      </div>
      </div>
  
      <DataTable
       bodyHeight='max-h-[calc(100vh-260px)]'
       height='h-[calc(100vh-130px)]'
       headers={headers} 
       total={totalCount}
       pageIndex={page}
       setPageIndex={setPage}
       dataCount={pageCount}
       setDataCount={setPageCount}
      >
      {data.length>0?data.map((item, i) => {
              let getCurrency;
              currencies.find((i) => {

                if(i.value == item?.currency){
                  getCurrency=i.label;
                }
              })
              return (
                <tr className="odd:bg-white even:bg-slate-50 hover:text-baseColor-primary hover:shadow-md hover:scale-y-105" key={i}>
                  <td className="w-[5%] px-3 py-2 text-center ">{item?.issuanceId||"#"}</td>
                  {/* <td className="w-[15%] p-2 px-[1px] text-center"><Link className='hover:text-baseColor-primary text-black ' to={`/main/accomodation/building/view/${item?.branchId}`} state={{data: item}}>{item?.branch_name||""}</Link></td> */}
                  <td className="w-[10%] px-3 py-2 text-center ">{item?.date||""}</td>
                  <td className="w-[10%] px-3 py-2  text-center ">{item?.stockId||""}</td>
                  <td className="w-[10%] px-3 py-2  text-center ">{item?.patientId||""}</td>
                  <td className="w-[10%] px-3 py-2 text-center">{item?.issuedQty||""}</td>
                  
                  <td className="w-[5%] px-3 py-2 text-center ">
                      <ViewMoreIssuance  data={item} fetcher={getData} id={item?.issuanceId}/>
                  </td>
                </tr>
              );
            })
            :
            <tr className=' text-center my-2 font-semibold text-sm'>No Data Found</tr>
          }
      </DataTable>

      <Modal
        open={showFilterDialog}
        title={"Issuance"}
        width={"50%"}
        className="my-modal"

        
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="">
                    <NuSelect
              label="Stock Id"
              options={medicalStocks}
              formik={formik}
              placeholder="Choose"
              name="stockId"
              width="w-full"
            />
            <NuSelect
              label="Patient Id"
              options={patients}
              formik={formik}
              placeholder="Choose"
              name="patientId"
              width="w-full "
            />
                    
                    </div>
                    
                   <div className="flex justify-end gap-2 mt-5">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
    </div>
    )
  }

export default IssuanceIndex