import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik'
import * as yup from 'yup';
import { notification } from 'antd';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker, NuInputEmail, NuInputNumber, NuInputText, NuMobileNumber, NuSelect, NuSelectCurrency, NuTextArea, NuTimePicker } from '../../../../Components/Utils/NuInput';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import UseAddData from '../../../../Components/Hooks/useAddData';


function CreateVisitorTracking() {
  const { data: occupants } = UseDropDown('/occupants/drop-down');
  const { Oncancel, addData, contextHolder, isLoading } = UseAddData();
  const {officeId} = useParams()
  const [createNew, setCreateNew] = useState({
    date: "",
    visitorName: "",
    countryCode: "",
    visitorMobile: "",
    whomToVisit: "",
    purposeOfVisit: "",
    timeIn: "",
    branchId:officeId,
    visitNumber:""
  })
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: createNew,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: yup.object({
      date: yup.string().required('Date is required.'),
      visitorName: yup.string().required('Visitor Name is required.'),
      timeIn: yup.string().required('Time -In is required.'),
      whomToVisit: yup.string().required('Please choose Occupant.'),
    }),

    onSubmit: async (value, { resetForm }) => {
      addData('/visitors-tracking/new-visitor', value, resetForm)
    }
  });
  return (
    <div className="w-full h-full px-4 py-2">
      {contextHolder}
      <div className="w-full h-[46px]  flex justify-between ">
        <BreadCrumb
          items={[
            {
              title: (
                <Link to="/dashboard">
                  <Dashboard color="gray" width={10} height={10} />{" "}
                </Link>
              ),
            },
            { title: <p className=" text-[12px] ">Accomodation</p> },
            { title: <p className=" text-[12px] ">Visitors-Tracking</p> },
            { title: <p className=" text-[12px] text-[#4E4B4B]">Create</p> },
          ]}
        />
        <div className=" flex gap-2">
          <BackButton />
        </div>
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
            <NuInputText
              isRequired={true}
              label="Visitor Name"
              placeholder="Visitor Name"
              formik={formik}
              name="visitorName"
              width="w-full md:w-1/3"
            />
            <NuMobileNumber
              label="Mobile"
              nunCode='countryCode'
              placeholder="Mobile"
              formik={formik}
              name="visitorMobile"
              width="w-full md:w-1/3"
            />
            <NuDatePicker
              isRequired={true}
              label="Date"
              formik={formik}
              name="date"
              width="w-full md:w-1/3"
            />
            <NuTimePicker
              isRequired={true}
              label="Time-In"
              formik={formik}
              name="timeIn"
              width="w-full md:w-1/3"
            />
            <NuSelect
              isRequired={true}
              label="Whom to Visit"
              options={occupants}
              formik={formik}
              placeholder="Choose"
              name="whomToVisit"
              width="w-full md:w-1/3"
            />
              <NuInputNumber
              label='VisitNumber' 
              placeholder='VisitNumber'
              formik={formik}
              name='visitNumber'
              width='w-full md:w-1/3'
               />
            <NuTextArea
              label="Purpose of visit"
              placeholder="Purpose of visit"
              formik={formik}
              name="purposeOfVisit"
              width='w-full md:w-1/3'
            />
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={() => { Oncancel() }} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateVisitorTracking;