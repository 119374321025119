
import React from 'react'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import ConditionMain from './condition'
import Category from '../InspectionSchedule/Category'


const DailyInspectionByStaff  = () => {
  return (
    <>
    <TextMediumSM content="Daily Inspection" />
    {/* <Category/> */}
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
       <ConditionMain/>
     </div>
    
     </div>
     </>
  )
}

export default DailyInspectionByStaff