import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const CategoryMeeting = () => {
  return (
    <SettingsMetaData propertyName={"Category_Meeting"} viewPropertyName={"Category"} />

  )
}

export default CategoryMeeting