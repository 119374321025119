import React, { useState } from 'react';
import { BackButton, CancelButton, SubmitButton } from '../../../../Components/Utils/Buttons';
import { NuDatePicker,  NuInputText, NuSelect, NuTextArea,NuInputNumber, NuSelectwithSearch } from '../../../../Components/Utils/NuInput';
import CustomImgUpload from '../../../../Components/Utils/CustomImgUpload';
import Dashboard from '../../../../Components/Icons/Dasboard';
import { json, Link, Navigate,useNavigate, useParams } from 'react-router-dom';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import { useFormik } from 'formik';
import useAddFormData from '../../../../Components/Hooks/useAddFormData';
import * as yup from 'yup';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import Dropdown from 'antd/es/dropdown/dropdown';
import Attachement from '../../../../Components/Utils/CustomAttrachmentupload';
import { notification } from 'antd';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';
import DropDownAddMetaData from '../../../../Components/Utils/selectAddMetaData';
const filetype = ["docx", "doc", "xlsx", "xls", "pdf", "txt", "pptx","mp4", "mov", "wmv", "avi", "flv", "mkv"];

function CreateAsserts() {
  const {officeId} = useParams();

  const { value: WarrentyApplicableValue, getData: WarrentyApplicable } = useFetchMataData('WarrentyApplicable')
  const { value: CalliberReqValue, getData: CalliberReq } = useFetchMataData('CalliberReq')

  const { value: Category} = useFetchMataData('Category') 
  const { value: SubCategory} = useFetchMataData('SubCategory')
  const { value: CalliberReqq} = useFetchMataData('CalliberReq') 
  const { value: WarrentyApplicablee} = useFetchMataData('WarrentyApplicable')
  const navigate=useNavigate()
  const [images, setImages] = useState([]);
    const [selectedFile,setSelectedFile] =useState(null)
    const [ api, contextHolder ] = notification.useNotification();
  const {data:user} = UseDropDown('/user/drop-down')
  const {data:occupant} = UseDropDown('/occupants/drop-down')
  const {data:suppliers} = UseDropDown('/vendors/drop-down')
  const {data:menus} = UseDropDown('/food-menu/drop-down')
  // const [division, setDivision] = useState([]);
  const { Oncancel, addFormData, isLoading } = useAddFormData ();
  const [createNew, setCreateNew] = useState({
      // reqType: "RoomService",
      branchId: officeId,
      code:"",
      isAsset:true,
      name:"",
      category: "",
      subCategory:"",
      serialNo:"",
      brand:"",
      manufacturer:"",
      supplier:"",
      batchNo:"",
      unit:"",
      warrentyDuration:"",
      warrentyEndDate:"",
      condition:"",
      quantity:"",
      mfdDate:"",
      expDate:"",
      mrp:"",
      unitPrice:"",
      purchasedDate:"",
      stockLocation:"",
      minStockLevel:"",
      barcode:"",
      stockQty:"",
      adjustedQty:"",
      reasonForAdjust:"",
      warrentyApplicable:"",
      warrentyStartDate:"",
      calibReq:"",
      calibExp:"",
      receivedQty:"",
      issuedQty:"",
      comments:"",
  })
 
  function handleImageChange(e) {
    const maxFileSize = 200000000; // 200 MB
    const files = Array.from(e.target.files);
    let fileArr = []
    files.map(item=>{
      if (item.size < maxFileSize ){
        fileArr.push(item)
      }else{
        api.warning({
          message: `File Size to big`,
          description: `${item.name} is Removed and not Uploaded`,
          placement: "bottomRight",
        });
      }
    })    
    let tempArr = []
    setSelectedFile(fileArr);
    files.map(item=>{
      if (item.size < maxFileSize ){
      let fileName = item?.name
      console.log('fname',fileName);
      let fileExtention =  fileName.split(".").reverse()[0]
      if(filetype?.includes(fileExtention)){
        tempArr.push(fileName)
      }else{
        let x = URL.createObjectURL(item)
        tempArr.push(x)
      }
    }
    })
    console.log(tempArr,'temp');
    setImages(tempArr)
}
  const formik = useFormik({
      enableReinitialize: true,
      initialValues: createNew,
      validateOnChange: false,
      validateOnBlur: false,
      validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
      }),
      onSubmit: async (value, { resetForm }) => {
         
        const formData = new FormData();
        formData.append("json_data",JSON.stringify(value))
        if (selectedFile!=null){
          selectedFile.forEach(element => {
             formData.append("stockImage",element)
          });
        }
        else{
          formData.append("stockImage",[])
        }

          addFormData('/stocks/new', formData, resetForm);
      }
  })

  return (
    <div className="w-full h-full p-2 bg-[#F1F2FB]">
    <div className="flex justify-between px-4">
        <BreadCrumb items={[
        {
            title: (<Link to="/main/dashboard">
                <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
        },
        { title: (<p className=' text-[12px] '>Invontary</p>) },
        { title: (<p className=' text-[12px] '>Asserts</p>) },
        { title: (<p className=' text-[12px] '>Create</p>) },
    ]} />
    <BackButton/>
    </div>
    <form onSubmit={formik.handleSubmit}>
        <div className="w-full mx-auto h-[calc(100vh-130px)] bg-white rounded-xl overflow-hidden mt-4">
          <div className=" w-full h-full py-4 px-3 flex justify-start content-start flex-wrap  overflow-y-auto">
          <NuInputNumber
              // isRequired={true}
              label="Code"
              placeholder="Code"
              formik={formik}
              name="code"
              width="w-full md:w-1/3"
            />
             {/* <NuInputText
              isRequired={true}
              label="Is Asset"
              placeholder="isAsset"
              formik={formik}
              name="manufacturer"
              width="w-full md:w-1/3"
            /> */}
            <NuInputText
              // isRequired={true}
              label="Name"
              placeholder="Name"
              formik={formik}
              name="name"
              width="w-full md:w-1/3"
            />
                 
            <NuSelect
              label="Category"
              options={Category}
              formik={formik}
              placeholder="Category"
              name="category"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelect
              label="Sub Category"
              options={SubCategory}
              formik={formik}
              placeholder="Sub Category"
              name="subCategory"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              label="Serial No"
              placeholder="Serial No"
              formik={formik}
              name="serialNo"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Brand"
              placeholder="Brand"
              formik={formik}
              name="brand"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              label="Manufacturer"
              placeholder="Manufacturer"
              formik={formik}
              name="manufacturer"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Manufacture Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="mfdDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="expDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuSelectwithSearch
              label="Supplier"
              placeholder="Supplier"
              formik={formik}
              options={suppliers}
              name="supplier"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label=" Batch No"
              placeholder="Batch No"
              formik={formik}
              name="batchNo"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Unit"
              placeholder="Unit"
              formik={formik}
              name="unit"
              width="w-full md:w-1/3"
            />
            
            
            <NuDatePicker
              label="Purchased Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="purchasedDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Quantity"
              placeholder="Quantity"
              formik={formik}
              name="quantity"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Condition"
              placeholder="Condition"
              formik={formik}
              name="condition"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="MRP Price"
              placeholder="MRP Price"
              formik={formik}
              name="mrp"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Unit Price"
              placeholder="Unit Price"
              formik={formik}
              name="unitPrice"
              width="w-full md:w-1/3"
            />
            <NuInputText
              label="Warrenty Duration"
              placeholder="Warrenty Duration"
              formik={formik}
              name="warrentyDuration"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Warrenty Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyEndDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Stock Location"
              placeholder="Stock Location"
              formik={formik}
              name="stockLocation"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Minimum Stock Level"
              placeholder="Minimum Stock Level"
              formik={formik}
              name="minStockLevel"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Barcode"
              placeholder="Barcode"
              formik={formik}
              name="barcode"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Stock Quantity"
              placeholder="Stock Quantity"
              formik={formik}
              name="stockQty"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Adjusted Quantity"
              placeholder="Adjusted Quantity"
              formik={formik}
              name="adjustedQty"
              width="w-full md:w-1/3"
            />
            <NuInputText
              // isRequired={true}
              label="Reason For Adjust"
              placeholder="Reason For Adjust"
              formik={formik}
              name="reasonForAdjust"
              width="w-full md:w-1/3"
            />
            <DropDownAddMetaData
              label="Warrenty Applicable"
              Options={WarrentyApplicableValue}
              formik={formik}
              placeholder="Warrenty Applicable"
              name="warrentyApplicable"
              getFunc={() => WarrentyApplicable()}
              displayProperty={"Warrenty Applicable"}
              propertyName={"WarrentyApplicable"}
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Warrenty Start Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="warrentyStartDate"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <DropDownAddMetaData
              label="Caliber Request"
              Options={CalliberReqValue}
              formik={formik}
              placeholder="Calib Request"
              name="calibReq"
              getFunc={() => CalliberReq()}
              displayProperty={"Calliber Req"}
              propertyName={"CalliberReq"}
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuDatePicker
              label="Caliber Expire Date"
              placeholder="dd/mm/yyyy"
              formik={formik}
              name="calibExp"
              width="w-full md:w-1/2 lg:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Received Quantity"
              placeholder="Received Quantity"
              formik={formik}
              name="receivedQty"
              width="w-full md:w-1/3"
            />
            <NuInputNumber
              // isRequired={true}
              label="Issued Quantity"
              placeholder="Issued Quantity"
              formik={formik}
              name="issuedQty"
              width="w-full md:w-1/3"
            />
            {/* <NuTextArea
              label="Description"
              placeholder="Description"
              formik={formik}
              name="description"
              width='w-full md:w-1/3'
            /> */}
            <NuTextArea
              label="Comments"
              placeholder="Comments"
              formik={formik}
              name="comments"
              width='w-full md:w-1/2'
            />

<Attachement
                  handleChange={handleImageChange}
                  preview={images||''}
                  width="w-full"
                  label="Attach"
                  multiple={false}
                />
             
            <div className="w-full hidden md:block mx-auto my-4 mt-8 ">
              <div className="md:flex justify-end gap-4">
                <CancelButton handleClick={()=>{navigate(-1)}} />
                <SubmitButton />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default CreateAsserts