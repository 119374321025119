import React from 'react'
import InsuranceType from './InsuranceType'
import Status from './Status'
import { TextMediumSM } from '../../../../../Components/Text/MediumText'
import Gender from './Gender'
import Revisit from './Revisit'

const Patients = () => {
  return (
    <>
    <TextMediumSM content="Patients" />
    <div className='w-full flex flex-wrap justify-center md:justify-start  items-center overflow-y-scroll'>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <InsuranceType/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Gender/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Revisit/>
     </div>
     <div className=' w-full md:w-1/2 lg:w-1/3 mt-4'>
        <Status/>
     </div>
    
    
     </div>
     </>
  )
}

export default Patients