import React, { useEffect, useState} from 'react'
import { useFormik } from 'formik';
import { Link, useParams } from 'react-router-dom';
import { CreateButton, FilterButton,CancelButton,SubmitButton } from '../../../../Components/Utils/Buttons';
import BreadCrumb from '../../../../Components/Utils/Breadcrumbs';
import Dashboard from '../../../../Components/Icons/Dasboard';
import SearchBar from '../../../../Components/Utils/SearchBar';
import UseGetData from '../../../../Components/Hooks/useFetchData';
import EmployeeCard from './employeeCard';
import UseGetDataSearch from '../../../../Components/Hooks/useFetchDataBySearch';
import * as yup from 'yup'
import { Modal } from 'antd';
import UseDropDown from '../../../../Components/Hooks/useDropDown';
import { useDeleteData } from '../../../../Components/Hooks/useDeleteData';
import { formattedBloodGroups } from "../../../../assets/CountryMini";
import { NuInputText, NuSelect, NuSelectwithSearch } from "../../../../Components/Utils/NuInput";
import Pagination from '../../../../Components/Utils/Pagenation';
import useFetchMataData from '../../../../Components/Hooks/useFetchMataData';

function EmployeeIndex() {
  const { value: IdType } = useFetchMataData('Id_Type')
    const { value: Division } = useFetchMataData('Division')
  const { value: Designation } = useFetchMataData('Designation') 
  const { value: Status } = useFetchMataData('Status')
  const {officeId} = useParams();
  console.log("nnnnnn0",officeId)
  const [page,setPage]=useState(1)
    const [pageCount,setPageCount]=useState(20)
    const [params, setParams] = useState({
      page: page,
      per_page: pageCount,
      search: "",
    });
    const { data, totalCount, getData } = UseGetDataSearch( '/employees/',params);
    console.log(data,'dddd');
    useEffect(()=>{
      setParams({...params,page:page,per_page:pageCount})
     },[page,pageCount])
  // const { contextHolder, handle } = useDeleteData(getData);
  // const handleDelete = async (removevalue,id) => handle(`/employees/${id}`, removevalue, 'employees');
  // const { data: branches } = UseDropDown('/company_branch/drop-down');
    const [showFilterDialog, setShowFilterDialog] = useState(false);
  const [filter, setFilter] = useState(false);
    const closeFilterDialog = () => {
        setShowFilterDialog(false);
      };
      const[filterEmployees,setfilterEmployees]=useState({
        department:"",
        idType:"",
        designation:"",
        status:"",
      });
      const formik = useFormik({
        enableReinitialize: true,
        initialValues: filterEmployees,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: yup.object({
          // email: yup.string().required('Email is required!'),
        }),
        onSubmit: async (value, { resetForm }) => {
          console.log("ffff",value);
          setParams({...params, department: value?.department, idType: value?.idType,designation: value?.designation,status: value?.status,})
          closeFilterDialog();
          setFilter(true)
          setfilterEmployees({
            department: "",
            idType: "",
            designation: "",
            status: "",

          });
        }
      })
      const clearFilter = ()=> {
        setParams({...params,
          department: "",
          idType: "",
          designation: "",
          status: "",

       
        })
        formik.values.department=""
        formik.values.idType=""
        formik.values.designation=""
        formik.values.status=""

          setFilter(false)
      }
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState('');
  // const  { data, totalCount, getData } = UseGetData(1,20,'/employees/');
  // console.log('ddd',data);
  
  // const userAccess = true;JSON.parse(localStorage.getItem("userAccess"));
  let AllUserCreateAccess = true;//userAccess
  // ? userAccess.some((permission) => permission.permissionName == "Settings_Alluser_create")
  // : false;
  useEffect(()=>{
    setParams({...params,page:page,per_page:pageCount})
   },[page,pageCount])
  return (
    <div className='w-full h-full px-4 py-2'>
      <div className='w-full h-[46px] flex flex-wrap lg:flex-nowrap justify-between items-center'>
        <BreadCrumb items={[
          {
            title: (<Link to="/main/dashboard">
              <Dashboard color="gray" width={10} height={10} />{" "}
            </Link>)
          },
          { title: (<p className=' text-[12px] '>Listing</p>) },
          { title: (<p className=' text-[12px] '>Employees</p>) }
        ]} />
        <div className='lg:flex gap-2 hidden'>
          <SearchBar 
          handleChange={(e) =>
            setParams({ ...params, search: e.target.value })
          }
          />
          <FilterButton height='8' 
          handleClick={() => setShowFilterDialog(true)}/>
           {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
          <CreateButton label='Import' />
          {AllUserCreateAccess && <Link to={`/main/listing/employees/${officeId}/create`}><CreateButton label='Employee' /></Link>}
        </div>
      </div>
      <div className=' lg:hidden flex justify-between  gap-2'>
          <div className='w-1/2'>
            <SearchBar 
            handleChange={(e) =>
              setParams({ ...params, search: e.target.value })
            }
            />
          </div>
          <div className='flex w-1/2 justify-around'>
            <FilterButton height='8' 
            handleClick={() => setShowFilterDialog(true)}/>
            {filter && ( 
             <FilterButton 
            handleClick={() => clearFilter()} 
            label="Clear" /> 
          )}
            <CreateButton label='Import' />
            {AllUserCreateAccess && <Link to={`/main/listing/employees/${officeId}/create`}><CreateButton label='Employee' /></Link>}
          </div>
      </div>
      {/* <NormalHeading title={'Users'}/> */}
      <div className="w-full h-[calc(100vh-190px)] rounded-md bg-white p-3 mt-4 overflow-y-scroll">
            <div className="w-full flex justify-start flex-wrap md:gap-x-5 lg:gap-x-6 gap-y-6 pt-4 ">
        {data?.length>0 ? data &&
          data.map((item, idx) => {
            return (<EmployeeCard fetcher={getData} item={item} key={idx}/>);
          })
          :        
          <p className=" w-full flex justify-center font-semibold text-sm">No data Found</p>      
        }
      </div>
      </div>
      {/* h-[calc(100vh-130px)] */}
      <div className='w-full h-10'>
            <Pagination
             pageIndex={page}
             setPageIndex={setPage}
             dataCount={pageCount}
             setDataCount={setPageCount}
             total={totalCount}
            />
            </div>

      <Modal
        open={showFilterDialog}
        title={"Filter Employees"}
        width={"50%"}
        className="my-modal"
        centered
        onCancel={closeFilterDialog}
        footer={[]}
      >
                 <form onSubmit={formik.handleSubmit}> 
                    <div className="">
                    
                    
                    <NuSelect
                                    label='Division / Department'
                                    options={Division}
                                    formik={formik}
                                    placeholder='Choose'
                                    name='department'
                                    width='w-full'
                                />
                    <NuSelect
                                    label={'Id Type'}
                                    options={IdType}
                                    placeholder={'Choose'}
                                    name={'idType'}
                                    formik={formik}
                                    width={'w-full'}
                                />
                    <NuSelect
                                    label='Desigination'
                                    placeholder='choose'
                                    formik={formik}
                                    options={Designation}
                                    name='designation'
                                    width='w-full' />
                                    <NuSelect
                                    label={'Status'}
                                    options={Status}
                                    placeholder={'Choose'}
                                    name={'status'}
                                    formik={formik}
                                    width={'w-full'}
                                />
                    </div>
                    
                   <div className="flex justify-end gap-2 mt-5">
                     <CancelButton handleClick={closeFilterDialog} />
                     <SubmitButton name='Apply' />
                   </div>
                  </form> 
               {/* </div>
             </div>
           )} */}
           </Modal>
           

           
    </div>
  )
}

export default EmployeeIndex