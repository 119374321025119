import React from 'react'
import SettingsMetaData from '../../../../../Components/Hooks/useSettingsMetaData'

const InspectionResult = () => {
  return (
    <SettingsMetaData propertyName={"inspectionResult"} viewPropertyName={"Inspection Result"} />

  )
}

export default InspectionResult